// import * as React from "react";
import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import _ from 'lodash';

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useNavigate } from 'react-router-dom'
import {
  registerUser, registerUserIsPersonalInfoFinish, registerUserIsLoginInfoFinish, registerUserIsStoreInfoFinish
  , registerUserIsIRSWqStateInfoFinish, registerUserProceeding, registerUserSuccessful, registerUserFailed
} from 'store/auth/register/reducer';

import { Divider } from "@mui/material";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";

import "assets/styles/pages/SignUpPage.scss";

import { MessageContext } from "common/lib/MessageProvider";
import { ComNoticfication } from "common/components/ComNoticfication";
import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from "common/lib/ComUtils";

import Breadcrumb from "common/components/ComBreadcrumb";

import HeaderSignUp from "./HeaderSignUp";
import FooterLogin from "Layout/FooterLogin";

import PersonalInformationPage from "./PersonalInforSpiffPage";
import LoginInforSpiffPage from "./LoginInforSpiffPage";
import StoreInformation from "./StoreInforSpiffPage";
import IRSWqStatement from "./IRSWqStatementSpiffPage";
import CompleteSignUp from "./CompleteSignUpSpiffPage";


const steps = [
  "Personal Information",
  "Login Information",
  "Store Information",
  "IRS W-9 Statement",
];

function SignUpSpiff() {

  const insertAllSpiffUserUrl = "/init/b2buser/insertAllSpiffUser";
  const retrieveSpiffDuplicateSSNInfoUrl = "/init/b2buser/retrieveSpiffDuplicateSSNInfo";

  const navigate = new useNavigate();

  const personalInfoRef = useRef(null);
  const loginInfoRef = useRef(null);
  const storeInfoRef = useRef(null);
  const irsWqStatement = useRef(null);

  const dispatch = useDispatch();
  const AccountProperties = createSelector(
    (state) => state.account,
    (account) => ({
      user: account.user,
      isPersonalInfoFinish: account.isPersonalInfoFinish,
      isLoginInfoFinish: account.isLoginInfoFinish,
      isStoreInfoFinish: account.isStoreInfoFinish,
      isIRSWqStateInfoFinish: account.isIRSWqStateInfoFinish,
      success: account.success
    })
  );
  const { user, isPersonalInfoFinish, isLoginInfoFinish, isStoreInfoFinish, isIRSWqStateInfoFinish, success } = useSelector(AccountProperties);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = async () => {
    switch (activeStep) {
      case 0:

        if (
          user.firstName && user.lastName
          // && user.year && user.month && user.day
          && user.ssn1 && user.ssn2 && user.ssn3
          && user.streetAddress
          // && user.city && user.state 
          && user.zipCode
          && user.email && user.confirmEmail && user.officePhone
        ) {

          //SSN 중복체크
          let goCheck = true;
          let ssn = `${user.ssn1}${user.ssn2}${user.ssn3}`;
          let resultData = await ApiManager.post(retrieveSpiffDuplicateSSNInfoUrl, { ssn });
          if (resultData?.statusCode) {
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, resultData?.statusCode);
            goCheck = false;
            dispatch(registerUserIsPersonalInfoFinish(false));
            // personalInfoRef.current.isEmptyRequired();
            break;
          } else {
            if (resultData && resultData.length > 0) {
              if (resultData[0].cnt > 0) {
                ComNoticfication.ShowErrorUI(ComUtils._altTitleError, `Registration blocked -- Spiff account already exists under your personal SSN.
                Please go to our homepage and use the Forgot User ID or Forgot Password option if you don’t know your login information.
               For additional assistance you can contact spiff.claim@lge.com`);
                goCheck = false;
                dispatch(registerUserIsPersonalInfoFinish(false));
                // personalInfoRef.current.isEmptyRequired();
              }
            } else {
              goCheck = false;
              dispatch(registerUserIsPersonalInfoFinish(false));
              // personalInfoRef.current.isEmptyRequired();
            }
          }

          if (goCheck) {
            user.ssn = `${user.ssn1}${user.ssn2}${user.ssn3}`;
            user.dob = `${user.year}${user.month}${user.day}`;
            if (user?.middleName) {
              user.userName = `${user.firstName} ${user.middleName} ${user.lastName}`;
            } else {
              user.userName = `${user.firstName} ${user.lastName}`;
            }
            user.fName = user.firstName;
            user.mName = user.middleName;
            user.lName = user.lastName;
            dispatch(registerUserProceeding(user));
            dispatch(registerUserIsPersonalInfoFinish(true));
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        } else {
          dispatch(registerUserIsPersonalInfoFinish(false));
          personalInfoRef.current.isEmptyRequired();
        }
        break;
      case 1:
        if (
          user.userId && user.userIdCheck && user.password && user.repassword
        ) {
          user.userGroup = "DA";
          user.paymentMethod = "Debit Card";
          dispatch(registerUserProceeding(user));
          dispatch(registerUserIsLoginInfoFinish(true));
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          dispatch(registerUserIsLoginInfoFinish(false));
          loginInfoRef.current.isEmptyRequired();
        }
        break;
      case 2:
        if (
          user.companyName && user.reviewRemark && user.companyAddress && user.storeZipcode
        ) {
          dispatch(registerUserIsStoreInfoFinish(true));
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          dispatch(registerUserIsStoreInfoFinish(false));
          storeInfoRef.current.isEmptyRequired();
        }
        break;
      case 3:
        if (
          user.esignature
        ) {
          if (isPersonalInfoFinish && isLoginInfoFinish && isStoreInfoFinish && isIRSWqStateInfoFinish) {

            // 서버 전송
            let userInfo = _.cloneDeep(user);
            let resultData = await ApiManager.post(insertAllSpiffUserUrl, userInfo);
            if (resultData?.resultCode) {
              ComNoticfication.ShowErrorUI(ComUtils._altTitleError, resultData?.resultMsg);
              dispatch(registerUserFailed(resultData?.resultMsg));
            }
            else {
              dispatch(registerUserSuccessful(user));
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
          }
        } else {
          dispatch(registerUserIsIRSWqStateInfoFinish(false));
          irsWqStatement.current.isEmptyRequired();
        }
        break;
      default:
        break;
    }

  };

  const handleBack = () => {
    if (activeStep == 0) {
      navigate("/sign-up", { state: { type: false } });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const breadCrumbs = [
    {
      text: "Login",
      link: "#",
    },
    {
      text: "Sign up",
      link: "#",
    },
  ];

  const mapSteps = () => {
    switch (activeStep) {
      case 0:
        return <PersonalInformationPage ref={personalInfoRef} />;
      case 1:
        return <LoginInforSpiffPage ref={loginInfoRef} />;
      case 2:
        return <StoreInformation ref={storeInfoRef} />;
      case 3:
        return <IRSWqStatement ref={irsWqStatement} />;
    }
  };

  const _isMounted = useRef(true);
  useEffect(() => {
    let abortController = new AbortController();

    dispatch(registerUser("SPIFF"));

    // ComponentWillUnmount in Class Component
    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, []);

  return (
    <div className="sign-up-page">
      <HeaderSignUp />
      <Divider />

      <div className="container-signup">
        {/* <Breadcrumb items={breadCrumbs} /> */}
        <div className="stepper-signup">
          <Box className="box-signup">
            {activeStep !== 4 ? (
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  return (
                    <Step
                      key={index}
                      className={
                        index < activeStep
                          ? "is-completed"
                          : index === activeStep
                            ? "is-active"
                            : "is-default"
                      }
                    >
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            ) : (
              <></>
            )}
            {activeStep === 4 ? (
              <React.Fragment>
                <CompleteSignUp />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="body-text">
                  <div className="boder-text">
                    <Typography component={"span"} variant={"body2"}>
                      {mapSteps()}
                    </Typography>
                    <Box className="btn-footer">
                      <Button
                        variant="outlined"
                        className="btn-pre"
                        onClick={handleBack}
                        disabled={activeStep === 4}
                      >
                        Previous
                      </Button>
                      <Button variant="contained" onClick={handleNext}>
                        {activeStep === 3 ? "Submit" : "Next"}
                      </Button>
                    </Box>
                  </div>
                </div>
              </React.Fragment>
            )}
          </Box>
        </div>
      </div>

      <FooterLogin />
    </div>
  );
}
export default SignUpSpiff;
