import { useState, useEffect, useRef, useContext } from "react";

import * as wjInput from "@grapecity/wijmo.react.input";
import { JSEncrypt } from "jsencrypt";

import "assets/styles/pages/ModalCommon.scss";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";

import { MessageContext } from "common/lib/MessageProvider";

import { ComNoticfication } from "common/components/ComNoticfication";
import { ComUtils } from "common/lib/ComUtils";
import { ApiManager } from 'common/lib/ApiUtil';

import ComPassword from "common/components/ComPassword";

function ShowModalPasswordChange({ callbackFuntion, isOpen, userSaveInfo, randomKey }) {
  const loginPwdRef = useRef(null);
  const newPwdRef = useRef(null);
  const reEnterPwdRef = useRef(null);

  const positionTopValue = useRef();
  const popComponent = useRef();

  const updatePasswordInfoUrl = "/adm/b2buser/updatePasswordInfo";
  const pwRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,20}$/; //최소 8자, 최소 하나의 문자, 하나의 숫자 및 하나의 특수 문자

  let top = 0;

  const initForm = (s) => {
    popComponent.current = s;
    positionTopValue.current = popComponent?.current?.hostElement?.style?.top;
  };

  const shown = (p) => {
    top = p.hostElement.style.top;
  };
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  };

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(
      Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)),
      window.scrollX
    );
    bnd.top = Math.max(
      Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height),
      window.scrollY
    );
  };

  const positionChanged = (s, e) => {
    top = s.hostElement.style.top;
  };

  const onBlurCurrentPassword = (id, value) => {
  };

  const onBlurNewPassword = (id, value) => {
    if (pwRegex.test(value)) {
    } else {
      if (value)
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn,
          "New password must be between 8 and 20 characters long, must contain\rspecial characters, and must include at least two of the three options below.\r[Uppercase letters, lowercase letters, numbers]"
          , () => {
            newPwdRef.current?.setValue(null);
            newPwdRef.current?.setFocus();
          });
    }
  };
  const onBlurReNewPassword = (id, value) => {
    if (value) {
      if (newPwdRef.current.getValue() !== value) {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleWarn,
          "New Password and the password you entered are different."
          , () => {
            reEnterPwdRef.current.setValue(null);
            reEnterPwdRef.current.setFocus();
          });
      }
    }
  };

  const onPasswordInfoSaveExecute = async (r) => {
    if (r) {
      let sendParam = {
        userId: userSaveInfo?.userId ?? "",
        password: loginPwdRef.current.getValue(),
        newPassword: newPwdRef.current.getValue(),
      };

      const resultData = await ApiManager.post(
        updatePasswordInfoUrl,
        sendParam
      );
      if (resultData?.resultCode) {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Process failed");
      } else if (resultData === 1) {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleInfo, 
          "Your password has been changed. Please re-login."
          , () => {
            loginPwdRef.current.setValue("");
            newPwdRef.current.setValue("");
            reEnterPwdRef.current.setValue("");
            
            popComponent.current.hide();
            callbackFuntion(true);
          });
      } else if (resultData === 2) {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleError,
          // "Old password doesn't match."
          "Please check that your password match and try again."
          , () => {
            loginPwdRef.current.setFocus();
          });
      } else if (resultData === 3) {
        ComNoticfication.ShowAlertUICallback(
          ComUtils._altTitleError,
          "You can't reuse your old passwords."
          , () => {
            newPwdRef.current.setFocus();
          });
      } else {
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Process failed");
      }
    }
  };

  const onCancelClick = () => {
    popComponent.current.hide();
    callbackFuntion(false);
  };

  const onPasswordInfoSave = async () => {
    const currentPasswordValue = loginPwdRef.current.getValue();
    const newPasswordValue = newPwdRef.current.getValue();
    const reNewPasswordValue = reEnterPwdRef.current.getValue();

    if (!currentPasswordValue) {
      ComNoticfication.ShowErrorUI(
        ComUtils._altTitleWarn,
        "Current Password is required"
      );
      return;
    }
    if (!newPasswordValue) {
      ComNoticfication.ShowErrorUI(
        ComUtils._altTitleWarn,
        "New Password is required"
      );
      return;
    }
    if (!reNewPasswordValue) {
      ComNoticfication.ShowErrorUI(
        ComUtils._altTitleWarn,
        "Re-enter New Password is required"
      );
      return;
    }

    ComNoticfication.ShowConfirmUI(
      ComUtils._altTitleInfo,
      "Do you want to submit ?",
      onPasswordInfoSaveExecute
    );
  }

  //Memory
  const _isMounted = useRef(true);
  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();
    if (isOpen) {
      popComponent.current.show(true);
    }
    
    const getPageInfo = async () => {};

    getPageInfo();

    loginPwdRef?.current?.setValue("");
    newPwdRef?.current?.setValue("");
    reEnterPwdRef?.current?.setValue("");
    loginPwdRef?.current?.setFocus();

    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [isOpen, randomKey]);


  return (
    <wjInput.Popup
      className="pop-resizable-h-auto"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
      style={{ width: "510px" }}
    >
      <div className="wj-dialog-header">
        <div></div>
        <Button color="secondary" className="btn-close" onClick={onCancelClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="content-modal">
        <Divider component="li" />
        <h2 style={{ fontSize: "14px" }} className="fwb">
          Your account has been locked due to 3 months of inactivity. 
        </h2>
        <div className="content-password-aut">
          <div className="field-wrap fixed-column1">
            <div className="bx-item">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol">*</span>Current Password
                </label>
              </div>
              <div className="bx-inp combo">
                <ComPassword
                  placeholder="Password"
                  callRelationBlur={onBlurCurrentPassword}
                  ref={loginPwdRef}
                  defaultMaxLength={100}
                />
              </div>
            </div>
            <div className="bx-item">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol">*</span>New Password
                </label>
              </div>
              <div className="bx-inp combo">
                <ComPassword
                  placeholder="Password"
                  callRelationBlur={onBlurNewPassword}
                  ref={newPwdRef}
                  defaultMaxLength={100}
                />
              </div>
            </div>
            <div className="bx-item">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol">*</span>Re-enter New Password
                </label>
              </div>
              <div className="bx-inp combo">
                <ComPassword
                  placeholder="Password"
                  callRelationBlur={onBlurReNewPassword}
                  ref={reEnterPwdRef}
                  defaultMaxLength={100}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex-center btn-action-h-40 mt-10">
          <Button variant="outlined" onClick={onCancelClick}>
            {"Cancel"}
          </Button>
          <Button variant="contained" onClick={onPasswordInfoSave}>
            {"Submit"}
          </Button>
        </div>
      </div>
    </wjInput.Popup>
  );
}

export default ShowModalPasswordChange;
