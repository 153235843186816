import { CollectionView } from "@grapecity/wijmo";
import { FlexGrid } from "@grapecity/wijmo.react.grid";
import * as wjInput from "@grapecity/wijmo.react.input";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import "assets/styles/pages/ModalCommon.scss";
import { ComNoticfication } from "common/components/ComNoticfication";
import ComTextBox from "common/components/ComTextBox";
import { ApiManager } from "common/lib/ApiUtil";
import { ComUtils } from "common/lib/ComUtils";
import { FileUtils } from "common/lib/FileUtils";
import { MessageContext } from "common/lib/MessageProvider";
import FileSaver from "file-saver";
import { file } from "jszip";
import IrSpiffConfDtl from "Pages/Clm/Isf/IrSpiffConfDtl";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
function SerialInvoiceUploadPop({
  open,
  closeFn,
  reqRandomKey,
  pageInfo,
  reqClaimType,
  reqBillToCd,
  reqBillToCdNm,
  reqProgramId,
  reqClaimId,

}) {
  const comCode = sessionStorage.getItem("comCode").toString();
  const userGroup = sessionStorage.getItem("userGroup").toString();
  const userId = sessionStorage.getItem("userId").toString();
  const userLevel = pageInfo.userLevel;

  //url
  const retrieveClaimsExcelSerialInvoice = "/clm/isf/retrieveClaimsExcelSerialInvoice";
  const irConfInvSeriallUpload = "/clm/isf/ir/updXls/irConfInvSeriallUpload";
  const spiffConfInvSeriallUpload = "/clm/isf/spiff/updXls/spiffConfInvSeriallUpload";

  const positionTopValue = useRef();

  const popComponent = useRef();
  const [popuView, setPopuView] = useState(true);
  const [viewType, setViewType] = useState(true);
  const [dtlViewType, setDtlViewType] = useState(true);
  const [errViewType, setErrViewType] = useState(false);
  const [resultList, setResultList] = useState([]);
  const entityReqClaimId = useRef(null);
  const entityReqClaimType = useRef(null);
  const [openDtlPage, setOpenDtlPage] = useState(false);

  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGridData] = useState(null);

  const gridObject = useRef();


  const [fileList, setFileList] = useState([]);
  const excelUploadFileElement = useRef(null);
  const entityUploadExcelFileNm = useRef(null);

  const randomKey = useRef(null);
  const entityErrorInfo = useRef(null);

  const { messageObject } = useContext(MessageContext);

  //Memory
  const _isMounted = useRef(true);
  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();

    const getPageInfo = async () => { };
    getPageInfo();
    setPopuView(open);
    initGrid();
    initGridFormat();
    initGridBindData();

    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [reqRandomKey]);

  const initGrid = (sender) => {
    gridObject.current = sender;
  };

  const initGridFormat = () => {
    let gridFormat = [
      {
        binding: "model",
        header: "Model",
        width: 150,
        allowDragging: true,
        isReadOnly: true,
        align: "left",
      },
      {
        binding: "serialNo",
        header: "Serial_No",
        width: 120,
        allowDragging: true,
        isReadOnly: true,
        align: "left",
      },
      {
        binding: "invoiceNo",
        header: "Invoice_No",
        width: 200,
        allowDragging: true,
        isReadOnly: true,
        align: "left",
      },
      {
        binding: "qty",
        header: "Qty",
        width: 80,
        allowDragging: true,
        isReadOnly: true,
        align: "right",
      },
      {
        binding: "dateSold",
        header: "Date_Sold",
        width: 100,
        allowDragging: true,
        isReadOnly: true,
        align: "center",
      },
      {
        binding: "dateSoldFrom",
        header: "Date_Sold_From",
        width: 250,
        allowDragging: true,
        isReadOnly: true,
        align: "right",
      },
      {
        binding: "dateSoldTo",
        header: "Date_Sold_To",
        width: 100,
        allowDragging: true,
        isReadOnly: true,
        align: "center",
      },
      {
        binding: "dealerName",
        header: "Dealer_Name",
        width: 200,
        allowDragging: true,
        isReadOnly: true,
        align: "center",
      },
    ];
    setGridColGroup([...gridFormat]);
  };

  const initGridBindData = useCallback(() => {
    if (reqClaimType == "IR") {
      let resultData = [
        {
          model: "32SE3KB-B",
          serialNo: "NA",
          invoiceNo: "NA",
          qty: "1",
          dateSold: "20160605",
          dateSoldFrom: "",
          dateSoldTo: "",
          dealerName: "Dealer Name",
        },
        {
          model: "32SM5KB-B",
          serialNo: "NA",
          invoiceNo: "NA",
          qty: "1",
          dateSold: "",
          dateSoldFrom: "20160605",
          dateSoldTo: "20160615",
          dealerName: "",
        },
      ];
      setGridData(
        new CollectionView(resultData, {
          trackChanges: true,
          refreshOnEdit: false,
        })
      );
    } else {
      let resultData = [
        {
          model: "32SE3KB-B",
          serialNo: "AAAA",
          invoiceNo: "tl351",
          qty: "1",
          dateSold: "20160605",
          dateSoldFrom: "",
          dateSoldTo: "",
          dealerName: "Dealer Name",
        },
        {
          model: "32SE3KB-B",
          serialNo: "AAAA",
          invoiceNo: "tl351",
          qty: "1",
          dateSold: "",
          dateSoldFrom: "20160605",
          dateSoldTo: "20160605",
          dealerName: "HOME FURNISHINGS",
        },
        {
          model: "32SM5KB-B",
          serialNo: "AB",
          invoiceNo: "tl351",
          qty: "1",
          dateSold: "",
          dateSoldFrom: "20160605",
          dateSoldTo: "20160605",
          dealerName: "",
        },
        {
          model: "32SM5KB-B",
          serialNo: "AB",
          invoiceNo: "tl351",
          qty: "1",
          dateSold: "20160605",
          dateSoldFrom: "",
          dateSoldTo: "",
          dealerName: "",
        },
      ];
      setGridData(
        new CollectionView(resultData, {
          trackChanges: true,
          refreshOnEdit: false,
        })
      );
    }
  });

  const onCancleClick = () => {
    popComponent.current.hide();
    setPopuView(false);
    closeFn();
  };

  const onSampleDown = async () => {
    initGridFormat();
    initGridBindData();
    if (reqClaimType == "IR") {
      ApiManager.ExcelDownloadSplitFile(
        gridObject.current,
        "ir_claim_upload",
        null,
        null,
        null
      );
    } else {
      ApiManager.ExcelDownloadSplitFile(
        gridObject.current,
        "spiff_claim_upload",
        null,
        null,
        null
      );
    }
  };

  const onClickView = async () => {
    let sendParam = {
      claimType: reqClaimType,
      claimId: reqClaimId,
    };

    let resultData = await ApiManager.callExcelDowonload(
      retrieveClaimsExcelSerialInvoice,
      sendParam
    );
    let disposition = resultData.headers["content-disposition"];

    let filename = "excel.xlsx";
    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }
    if (filename) {
      try {
        FileSaver.saveAs(
          new Blob([resultData.data], {
            type: resultData.headers["content-type"],
          }),
          decodeURI(filename)
        );
      } catch (ex) {
        console.log(ex);
      }
    }
  };

  const handlePromoValid = () => {
    let chkFlag = true;

    // if (entityBillTo?.current?.getCode() === '' || entityBillTo?.current?.getCode() === null
    //   || entityBillTo?.current?.getText() === '' || entityBillTo?.current?.getText() === null) {
    //   chkFlag = false;
    //   ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Select a bill to code first");
    // }

    // if (entityBillTo?.current?.getCode() === 'Not IR Eligible') {
    //   chkFlag = false;
    //   ComNoticfication.ShowErrorUI(ComUtils._altTitleError, "Not IR Eligible");
    // }

    // if (chkFlag && userLevel === "1") {
    //   if (entityBillTo?.current?.getCode() === 'US006712005B' ||
    //     entityBillTo?.current?.getCode() === 'US006246001B' ||
    //     entityBillTo?.current?.getText().indexOf("SEARS") > -1) {
    //     entityDisplaycustModelYn.current = 'Y';
    //   }

    // }

    if (chkFlag) {
      return true;
    } else {
      return false;
    }
  };

  const onExcelUpload = async () => {
    try {
      if (
        entityUploadExcelFileNm?.current?.getValue() === "" ||
        entityUploadExcelFileNm?.current?.getValue() == null
      ) {
        ComNoticfication.ShowErrorUI(
          ComUtils._altTitleError,
          ComUtils.getMessage(messageObject, "CLM_ERR_00128") // Please select the Excel File by using Browse.. button.
        );
        return false;
      }

      if (fileList !== undefined && fileList !== null && fileList !== "") {
        let sendParam = {};

        for (let i = 0; i < fileList.length; i++) {
          if (fileList[i]) {

            if (fileList[i].model) {
              fileList[i].model = fileList[i].model.trim();
            } else {
              fileList[i].model = null;
            }

            if (fileList[i].serialNo) {
              fileList[i].serialNo = fileList[i].serialNo.trim();
            } else {
              fileList[i].serialNo = null;
            }

            if (fileList[i].invoiceNo) {
              fileList[i].invoiceNo = fileList[i].invoiceNo.trim();
            } else {
              fileList[i].invoiceNo = null;
            }

            if (fileList[i].qty) {
              fileList[i].qty = fileList[i].qty.trim();
            } else {
              fileList[i].qty = null;
            }

            if (fileList[i].dateSold) {
              fileList[i].dateSold = fileList[i].dateSold.trim();
            } else {
              fileList[i].dateSold = null;
            }

            if (fileList[i].dateSoldFrom) {
              fileList[i].dateSoldFrom = fileList[i].dateSoldFrom.trim();
            } else {
              fileList[i].dateSoldFrom = null;
            }

            if (fileList[i].dateSoldTo) {
              fileList[i].dateSoldTo = fileList[i].dateSoldTo.trim();
            } else {
              fileList[i].dateSoldTo = null;
            }

            if (!fileList[i].dealerName) {
              fileList[i].dealerName = null;
            }

          }

        }


        sendParam.claimId = reqClaimId;
        sendParam.billTo = reqBillToCd;
        sendParam.programId = reqProgramId;
        sendParam.listData = fileList;
        sendParam.xlsList = fileList;

        let resultData = null;
        if (reqClaimType == "IR") {
          resultData = await ApiManager.post(irConfInvSeriallUpload, sendParam);
        } else {
          resultData = await ApiManager.post(spiffConfInvSeriallUpload, sendParam);
        }


        if (resultData?.statusCode) {
          ComNoticfication.ShowErrorUI(
            ComUtils._altTitleError,
            ComUtils.getMessage(messageObject, "CLM_ERR_00011") // An error occurred during processing

          );
        } else {
          if (resultData.resultCode == -100) {
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, resultData.resultMsg);
            return;
          } else {
            for (let inx = 0; inx < resultData.rtnList.length; inx++) {
              resultData.rtnList[inx].result = resultData.rtnList[inx].result.replace(/<a>(.*?)<\/a>/g, (match, p1) => {
                if (p1.trim()) {
                  return `<span class="grid_btn_find" style="color: blue; cursor: pointer;">${p1}</span>`;
                }
                return match;
              });
            }
            document.getElementById('resultContainer').innerHTML = resultData.rtnList;

            setResultList(resultData?.rtnList);
            setViewType(false);
          }
        }
      }
    } catch (error) {
      console.log("Error uploading file:", error);
    }
  };

  const handleExcelUploadChange = async (event) => {
    if (event.target.files.length > 0) {
      setFileList([]);
      let chkFlow = true;
      let fileName = event.target.files[0].name;
      let fname = FileUtils.fileExtMatch(fileName);
      if (fname.length > 0) {
        if (fname !== "file-name excel") {
          chkFlow = false;
          ComNoticfication.ShowErrorUI(
            ComUtils._altTitleError,
            ComUtils.getMessage(messageObject, "CLM_ERR_00093") // Program attach only Excel file
          );
        }
      }

      if (chkFlow) {
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        try {
          const response = await ApiManager.post(
            "/Common/ExcelUtils/ExcelUpload",
            formData
          );
          if (response) {
            //
            for (let inx = 0; inx < response.length; inx++) {
              response[inx].fileName = fileName;
              response[inx].fileSizeList = event.target.files[0].size;
              response[inx].savedfilePath = "";
            }
            setFileList(response);
          }
        } catch (error) {
          console.log("Error uploading file:", error);
        } finally {
          entityUploadExcelFileNm?.current?.setValue(fileName);
        }
      }
    }
  };

  const openExcelupload = () => {
    setViewType(true);
    entityUploadExcelFileNm?.current?.setValue(null);
    excelUploadFileElement.current.value = "";
    excelUploadFileElement.current.click();
  };

  /******************************************************************* */
  let top = 0;

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true);
    positionTopValue.current = popComponent.current.hostElement.style.top;
  };

  const shown = (p) => {
    top = p.hostElement.style.top;
  };
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  };

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(
      Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 10)),
      window.scrollX
    );
    bnd.top = Math.max(
      Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height),
      window.scrollY
    );
  };

  const positionChanged = (s, e) => {
    top = s.hostElement.style.top;
  };



  const handleClick = (event) => {

    const clickedText = event.target.innerText;
    if (clickedText && (clickedText.startsWith("IR") || clickedText.startsWith("SP"))) {
      randomKey.current = ComUtils.setRefRandomKey();
      entityReqClaimId.current = clickedText;
      entityReqClaimType.current = reqClaimType;

      setDtlViewType(false);
      setOpenDtlPage(true);
    }

  }

  const closeIrSpiffConfDtl = (searchFlag) => {
    setOpenDtlPage(false);
    setDtlViewType(true);
  }

  return (
    <wjInput.Popup
      className="pop-resizable"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
      style={{
        width: openDtlPage ? "1811px" : "800px",
        height: openDtlPage ? "800px" : "600px",
        left: openDtlPage ? "72px" : "326.5px",
        overflowY: "auto" // Enable vertical scrolling
      }}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title">
          {openDtlPage ? (reqClaimType + " Confirm") : (reqClaimType + " Validations Excel Upload")}
        </h2>
        <Button color="secondary" className="btn-close" onClick={onCancleClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="content-modal" style={{ display: dtlViewType ? '' : 'none' }}>
        <Divider component="li" />
        <div className="field-wrap">
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">Claim Number</label>
            </div>
            <div className="bx-inp combo">{reqClaimId}</div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">Bill To Code</label>
            </div>
            <div className="bx-inp">{reqBillToCd}</div>
          </div>
          <div className="bx-item">
            <div className="div-label">
              <label className="txt-label">Bill To Name</label>
            </div>
            <div className="bx-inp">{reqBillToCdNm}</div>
          </div>
          <div className="bx-item" style={{ display: viewType ? "" : "none" }}>
            <div className="div-label">
              <label className="txt-label">Validations Excel File</label>
            </div>
            <div className="bx-inp combo">
              <ComTextBox
                ref={entityUploadExcelFileNm}
                elementId={"entityUploadExcelFileNm"}
                defaultDisabled={true}
              />
              <div className="btn-group-end" style={{ gap: "5px" }}>
                <Button
                  variant="contained"
                  className="btn-default btn-search-in-title"
                  onClick={openExcelupload}
                >
                  {"Find File"}
                </Button>
              </div>
              <input
                type="file"
                onChange={handleExcelUploadChange}
                ref={excelUploadFileElement}
                style={{ display: "none" }}
              />
            </div>
          </div>
        </div>
        <div className="action-layout mt-10">
          <div className="d-flex">
            <span style={{ display: viewType ? "" : "none" }}>
              <>
                The first line of excel file has to be formatted as followings.
                <br />
                Required fields are indicated with asterisk symbol(*)
              </>
            </span>
          </div>
          <div className="btn-group-end" style={{ display: viewType ? "" : "none" }}>
            <Button variant="outlined" onClick={onClickView}>
              {"View"}
            </Button>
            <Button variant="outlined" onClick={onSampleDown}>
              {"Sample"}
            </Button>
            <Button
              className="upload-btn"
              variant="contained"
              onClick={onExcelUpload}
            >
              {"Upload"}
            </Button>
          </div>
        </div>

        <div style={{ display: viewType ? "" : "none" }}>
          <div className="guide-table">
            <table>
              <thead>
                <tr>
                  <th scope="col" className="col-header">
                    MODEL
                  </th>
                  <th scope="col" className="col-remark" style={{ width: 300 }}>
                    SERIAL_NO
                  </th>
                  <th scope="col" className="col-remark" style={{ width: 200 }}>
                    INVOCIE_NO
                  </th>
                  <th scope="col" className="col-remark" style={{ width: 100 }}>
                    QTY
                  </th>
                  <th scope="col" className="col-remark" style={{ width: 200 }}>
                    DATE_SOLD
                  </th>
                  <th scope="col" className="col-remark" style={{ width: 200 }}>
                    DATE_SOLD_FROM
                  </th>
                  <th scope="col" className="col-remark" style={{ width: 200 }}>
                    DATE_SOLD_TO
                  </th>
                  <th scope="col" className="col-remark" style={{ width: 200 }}>
                    DEALER_NAME
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="header-group">
                      <div className="txt-left" style={{ justifyContent: "center" }}>55LE5400</div>
                    </div>
                  </td>
                  <td>
                    <div className="txt-left" style={{ justifyContent: "center" }}>710MXBP45365</div>
                  </td>
                  <td>
                    <div className="remark-group" style={{ justifyContent: "center" }}>1037</div>
                  </td>
                  <td>
                    <div className="remark-group" style={{ justifyContent: "center" }}>1</div>
                  </td>
                  <td>
                    <div className="remark-group" style={{ justifyContent: "center" }}>20130131 (YYYYMMDD)</div>
                  </td>
                  <td>
                    <div className="remark-group" style={{ justifyContent: "center" }}>20130131 (YYYYMMDD)</div>
                  </td>
                  <td>
                    <div className="remark-group" style={{ justifyContent: "center" }}>20130131 (YYYYMMDD)</div>
                  </td>
                  <td>
                    <div className="remark-group" style={{ justifyContent: "center" }}>HOME FURNISHINGS</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style={{ display: viewType ? "none" : "" }}>
          <div className="guide-table">
            <table>
              <thead>
                <tr>
                  <th scope="col" className="col-header">
                    * MODEL
                  </th>
                  <th scope="col" className="col-remark" style={{ width: 250 }}>
                    * SERIAL_NO
                  </th>
                  <th scope="col" className="col-remark" style={{ width: 200 }}>
                    * INVOCIE_NO
                  </th>
                  <th scope="col" className="col-remark" style={{ width: 100 }}>
                    * QTY
                  </th>
                  <th scope="col" className="col-remark" style={{ width: 200 }}>
                    * DATE_SOLD
                  </th>
                  <th scope="col" className="col-remark" style={{ width: 180 }}>
                    * DATE_SOLD_FROM
                  </th>
                  <th scope="col" className="col-remark" style={{ width: 180 }}>
                    * DATE_SOLD_TO
                  </th>
                  <th scope="col" className="col-remark" style={{ width: 180 }}>
                    DEALER_NAME
                  </th>
                  <th scope="col" className="col-remark" style={{ width: 200 }}>
                    Result
                  </th>
                </tr>
              </thead>
              <tbody>
                <>
                  {
                    resultList?.map((fn, index) => (
                      <tr key={index}>

                        <td className="txt-center">{fn.model}</td>
                        <td className="txt-center">{fn.serialNo}</td>
                        <td className="txt-center">{fn.invoiceNo}</td>
                        <td className="txt-right">{fn.qty}</td>
                        <td className="txt-center">{fn.dateSold}</td>
                        <td className="txt-center">{fn.dateSoldFrom}</td>
                        <td className="txt-center">{fn.dateSoldTo}</td>
                        <td className="txt-center">{fn.dealerName}</td>
                        <td className="txt-left" style={{ color: fn?.result?.substring(0, 1) != "S" ? 'red' : 'blue', whiteSpace: 'pre-wrap' }}
                          dangerouslySetInnerHTML={{ __html: fn.result?.replace(/(?:\r\n|\r|\n|\\n|\\r)/g, '<br />') }}
                          onClick={handleClick}

                        >
                        </td>
                      </tr>
                    ))
                  }
                </>
              </tbody>
            </table>
          </div>
        </div>
        <div id="resultContainer" style={{ display: 'none' }} />

        <FlexGrid
          columnGroups={gridColGroup}
          itemsSource={gridData}
          initialized={initGrid}
          style={{ minheight: "200px", height: "300px", display: "none" }}
        ></FlexGrid>
      </div>
      <div style={{ display: dtlViewType ? 'none' : '' }}>
        {openDtlPage ? <IrSpiffConfDtl pageInfo={pageInfo} closeFn={closeIrSpiffConfDtl} key={randomKey.current} reqClaimType={entityReqClaimType.current} reqClaimId={entityReqClaimId.current} reqActionTarget={""} reqUserLevel={pageInfo.userLevel} dtlRandomKey={randomKey.current} popupYn={"Y"} /> : null}
      </div>
    </wjInput.Popup>
  );
}

export default SerialInvoiceUploadPop;
