import { useState, useEffect, useContext, useRef, useCallback } from "react";
import "assets/styles/pages/PagesCommon.scss";
import * as wjInput from '@grapecity/wijmo.react.input';

import { Button, Divider } from "@mui/material";
import {
  CollectionView,
  toggleClass,
  createElement,
  format,
  Key
} from "@grapecity/wijmo";
import { ApiManager } from 'common/lib/ApiUtil';
import { ComUtils } from "common/lib/ComUtils";
import { GsrUtils } from "Pages/Gsr/Com/GsrUtils";
import { SampleUtils } from "Pages/Spl/Common/SampleUtils";
import { FileUtils } from 'common/lib/FileUtils';
import Breadcrumb from "common/components/ComBreadcrumb";
import * as wijmo from "@grapecity/wijmo";
import { FlexGrid } from "@grapecity/wijmo.react.grid";
import { MessageContext } from "common/lib/MessageProvider";
import _ from "lodash";
import FileSaver from 'file-saver';
import IcPdf from "assets/images/files/file_icon_pdf.png";

import ComTextArea from "common/components/ComTextArea";
import { GroupRow, DataMap } from "@grapecity/wijmo.grid";
import { ComNoticfication } from "common/components/ComNoticfication";
import ComRadioBox from "common/components/ComRadioBox";
import ComTextBox from "common/components/ComTextBox";
import ComMaskInput from "common/components/ComMaskInput";
import ComNumberInput from "common/components/ComNumberInput";
import ComCombo from "common/components/ComCombo";
import ComComboMultiColumn from "common/components/ComComboMultiColumn";
import ComTextBoxFindWithcode from "common/components/ComTextBoxFindWithcode";
import ComTextBoxFind from "common/components/ComTextBoxFind";
import ComDate from "common/components/ComDate";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import UnfoldLessRoundedIcon from "@mui/icons-material/UnfoldLessRounded";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import ClaimADV from "./classes/ClaimADV";
import ClaimSellIn from "./classes/ClaimSellIn";
import ClaimSellOut from "./classes/ClaimSellOut";
import ClaimMarkdown from "./classes/ClaimMarkdown";
import ClaimPriceProtection from "./classes/ClaimPriceProtection";
import ClaimPriceDifference from "./classes/ClaimPriceDifference";
import ClaimFreight from "./classes/ClaimFreight";
import ClaimMeetcomp from "./classes/ClaimMeetcomp";
import ClaimRecycling from "./classes/ClaimRecycling";
import ClaimDamageAllow from "./classes/ClaimDamageAllow";
import ClaimTax from "./classes/ClaimTax";
import ClaimVLPriceDiff from "./classes/ClaimVLPriceDiff";
import ClaimIrSpiff from "./classes/ClaimIrSpiff";

import BillToSchPop from "Pages/Pop/BillToSchPop";
import ShipToSchPop from "Pages/Pop/ShipToSchPop";
import ChargebackSchPop from "Pages/Pop/ChargebackSchPop";
import ModelSchPop from "Pages/Pop/ModelSchPop";
import LgProgramModelSchPop from "Pages/Pop/LgProgramModelSchPop";
import LgProgramSchPop from "Pages/Pop/LgProgramSchPop";
import LgOrgProgramSchPop from "Pages/Pop/LgOrgProgramSchPop";
import AnalystSchPop from "Pages/Pop/AnalystSchPop";
import ExcelUploadOtherClaim from "Pages/Pop/Clm/OtherClaimExcelUploadPop";
import SupplierPop from "Pages/Pop/SupplierPop";
import PriceInvoicePop from "Pages/Pop/PriceInvoicePop";
import InvoiceSchPop from "Pages/Pop/InvoiceSchPop";
import InvoiceReturnListPop from "Pages/Pop/InvoiceReturnListPop";
import InvoiceUsedListPop from "Pages/Pop/InvoiceUsedListPop";
import InvoiceTaxSchPop from "Pages/Pop/InvoiceTaxSchPop";
import DAInvoicePop from "Pages/Pop/DAInvoicePop"
import DiscountChart from "Pages/Clm/Oth/Other/components/DiscountChart";
import OtherClaimViewPop from "./OtherClaimViewPop";
import ESNExcelUploadPop from "Pages/Pop/Clm/ESNExcelUploadPop";
import { alignProperty } from "@mui/material/styles/cssUtils";


function OtherClaimRequestMod({ pageInfo, initData, closeFn, backFn }) {

  const { messageObject } = useContext(MessageContext);

  //info 메시지
  const msgOthInf001 = ComUtils.getMessage(messageObject, "OTH_INF_00001");           //You can't select Check as Payment Method if Reverse(Debit) is Yes
  const msgOthInf002 = ComUtils.getMessage(messageObject, "OTH_INF_00002");           //Unable to issue check due to outstaning amount on the account. Credit  will be issued.
  const msgOthInf008 = ComUtils.getMessage(messageObject, "OTH_INF_00008");           //There are no files to download
  const msgOthInf009 = ComUtils.getMessage(messageObject, "OTH_INF_00009");           //You can download it after you save it

  //warning 메시지
  const msgOthWar001 = ComUtils.getMessage(messageObject, "OTH_WAR_00001");           //To use this item, please enter [Bill To Code]
  const msgOthWar002 = ComUtils.getMessage(messageObject, "OTH_WAR_00002");           //Quotation No. doesn't exist for the bill to code.
  const msgOthWar003 = ComUtils.getMessage(messageObject, "OTH_WAR_00003");           //Invalid Quotation No.

  //error 메시지
  const msgOthErr001 = ComUtils.getMessage(messageObject, "OTH_ERR_00001");           //An error occurred during processing
  const msgOthErr007 = ComUtils.getMessage(messageObject, "OTH_ERR_00007");           //Blocked due to active no return allowance.
  const msgOthErr010 = ComUtils.getMessage(messageObject, "OTH_ERR_00010");           //Please input valid LG Promotion#. \n\nExample : \nUS-NTSO-20220314-0148\nUS-NTSI-20220314-0148.
  const msgOthErr012 = ComUtils.getMessage(messageObject, "OTH_ERR_00012");           //This claim is Final for this program?\nPlease select appropriate choice.
  const msgOthErr024 = ComUtils.getMessage(messageObject, "OTH_ERR_00024");           //Wrong file format. Please attach PDF or image file formats.

  const userType = sessionStorage.getItem("userType")?.toString();
  const userGroup = sessionStorage.getItem("userGroup")?.toString();
  const comCode = sessionStorage.getItem("comCode")?.toString();
  const userName = sessionStorage.getItem("userName")?.toString();
  const userEmail = sessionStorage.getItem("emailId")?.toString();
  const userId = sessionStorage.getItem("userId").toString();
  const empNo = sessionStorage.getItem("empNo").toString();
  let userLevel = pageInfo.userLevel;

  const [paymentMethods, setPaymentMethods] = useState([
    { Value: "CREDIT", Description: "Credit", Disable: false },
    { Value: "CHECK", Description: "Check", Disable: false },
  ]);

  // url
  const retrieveReasonCodeList = "/com/comCode/retrieveReasonCodeList";
  const retrieveBillToList = "/com/comPop/retrieveBillToList";
  const retrieveShipToList = "/com/comPop/retrieveShipToList";
  const retrieveLGChargebackList = "/com/comPop/retrieveLGChargebackList";
  const retrieveProgramModelList = "/com/comPop/retrieveProgramModelList";
  const retrieveLgProgramList = "/com/comPop/retrieveLgProgramList";
  const retrieveProgramList = "/com/comPop/retrieveProgramList";
  const retrieveModelList = "/com/comPop/retrieveModelList";
  const retrieveProgramBalanceGerp = "/com/comPop/retrieveProgramBalanceGerp";
  const retrieveOtherClaimRequestInfo = "/clm/oth/otherRequest/retrieveOtherClaimRequestInfo";
  const retrieveBillToReturn = "/clm/oth/otherRequest/retrieveBillToReturn";
  const retrieveClaimAnalystInfo = "/clm/oth/otherRequest/retrieveClaimAnalystInfo";
  const retrieveOutInvAmt = "/clm/oth/otherRequest/retrieveOutInvAmt";
  const updateOtherClaimUrl = "/clm/oth/otherRequest/updateOtherClaim";
  const updateOtherClaimConfirmUrl = "/clm/oth/otherRequest/updateOtherClaimConfirm";
  const retrieveInputCheckUrl = "/clm/oth/otherRequest/retrieveInputCheck";
  const retrieveOtherClaimConfirmInfo = "/clm/oth/otherConfirm/retrieveOtherClaimConfirmInfo";
  const retrieveOtherClaimInfo = "/clm/oth/otherConfirm/retrieveOtherClaimInfo";
  const retrieveDebitMemoInfo = "/gsr/grCrrClaim/retrieveDebitMemoInfo";
  const retrieveChargebackList = "/gsr/grCrrClaim/retrieveChargebackList"
  const retrieveSerialNoCheckUrl = "/gsr/grCrrClaim/retrieveSerialNoCheck";
  const retrieveReverseAmountChk = "/com/comOther/retrieveReverseAmountChk"
  const retrieveQuotationNoInfo = "/com/comOther/retrieveQuotationNoInfo";
  const retrieveModelQty = "/com/comOther/retrieveModelQty";
  const retrieveInvoiceNetQtyChk = "/com/comOther/retrieveInvoiceNetQtyChk";
  const retrieveNoReturnCustomer = "/com/comOther/retrieveNoReturnCustomer";
  const retrievePromotionNoInfo = "/com/comOther/retrievePromotionNoInfo";
  const retrieveInvoiceReturnList = "/com/comOther/retrieveInvoiceReturnList";
  const retrieveInvoiceUsedList = "/com/comOther/retrieveInvoiceUsedList";
  const retrieveGRPdfCreditMemoUrl = "/gsr/grRequest/retrieveGRPdfCreditMemo";
  const retrieveComInvoPdfUrl = "/gsr/grRequest/retrieveComInvoPdf";
  const retrieveAttachFileListByAttachNoUrl = "/gsr/grCrrClaim/retrieveAttachFileListByAttachNo";

  const allCombo = [{ cd: "", nm: "ALL" }];
  const allCombo2 = [{ cd: "ALL", nm: "" }];
  const selCombo = [{ cd: "", nm: "Select" }];
  const defaultCode = "ALL";

  const [isDisablePaymentMethod, setDisablePaymentMethod] = useState(false);
  const [isDisableVenderName, setDisableVenderName] = useState(initData?.claimMstInfo?.vendorName ? false : true);

  const entityCustEntry = useRef(null);
  const entityReverse = useRef(null);
  const entityBillTo = useRef(null);
  const entityParamBillTo = useRef(null);
  const entityParamBillToName = useRef(null);
  const entityShipTo = useRef(null);
  const entityDivision = useRef(null);
  const entityBillToAddress = useRef(null);
  const entityBillToCity = useRef(null);
  const entityBillToContactName = useRef(null);
  const entitySalesrepName = useRef(null);
  const entityBillToState = useRef(null);
  const entityBillToContactTel = useRef(null);
  const entitySalesrepTel = useRef(null);
  const entityBillToZipCode = useRef(null);
  const entityBillToContactEmail = useRef(null);
  const entitySalesrepEmail = useRef(null);
  const entityReasonCode = useRef(null);
  const entityClaimAnalyst = useRef(null);
  const entityDebitMemoNo = useRef(null);
  const entityDebitMemoAmt = useRef(null);
  const entityPaymentMethod = useRef(null);
  const entityLgChargebackNo = useRef(null);
  const entityVendorName = useRef(null);
  const entityVendorCode = useRef(null);
  const entityOutstandingInvoiceAmt = useRef(null);
  const entityPayToAddress = useRef(null);
  const entityPendingCode = useRef(null);
  const entityQuotationNo = useRef(null);
  const entityMultiQuote = useRef(null);
  const entityGroupCodeFlag = useRef(null);
  const entityTradeClaim = useRef(null);
  const entityMoreClaims = useRef(null);
  const entityFollowUpDate = useRef(null);
  const entityFollowFlag = useRef(null);
  const entityCustomerClaimDesc = useRef(null);
  const entityLgReviewComment = useRef(null);
  const entityClaimType = useRef(null);
  const entityPostAuditClaim = useRef(null);
  const entityActivityDateFrom = useRef(null);
  const entityActivityDateTo = useRef(null);
  const entityApproveComment = useRef(null);

  const input = useRef();
  const claimMstInfo = useRef();
  const toDay = useRef();
  const systemEntryNo = useRef("");
  const vendorSiteCode = useRef("");
  const claimAnalystEmpNo = useRef("");
  const claimStatus = useRef("A1"); // save, sumbit에 사용할 변경시킬 status값
  const confirmType = useRef("SAVE");
  const newApprovalLine = useRef("N");
  const systemEntryNoUpload = useRef("");
  const ppPeriod = useRef("");
  const gSingleMulti = useRef("N");
  const orgRequestHidden = useRef(true);
  const fileElement = useRef(null);
  const reasonCodeListRef = useRef([]);
  const pendingCodeListRef = useRef([]);
  const noClaimBillToListRef = useRef([]);
  const vConfirmSubmit = useRef(null);
  const updateUrl = useRef(updateOtherClaimUrl);
  const prevInpus = useRef({}); // input 포커스 잃을때 중복체크 방지 위한 이전값 저장
  const isEnterKeyEvent = useRef(false);

  const [title, setTitle] = useState(initData?.input?.prevJob === "confirm" ? "Other Claim Confirm Detail" : "Other Claim Update");
  const [fileList, setFileList] = useState([]);
  const [linkClasses, setLinkClasses] = useState(
    fileList?.map(() => 'file-link-blue')
  );
  const [postAuditCustomer, setPostAuditCustomer] = useState(initData?.claimMstInfo?.postAuditCustomer ?? "");
  const [postAuditClaim, setPostAuditClaim] = useState(initData?.claimMstInfo?.postAuditClaim ?? "");
  const [claimAnalystYn, setClaimAnalystYn] = useState(initData?.input?.claimAnalystYn ?? "");
  const [rvsClaimNo, setRvsClaimNo] = useState(initData?.claimMstInfo?.rvsClaimNo ?? "");
  const [orgDebitMemoAmount, setOrgDebitMemoAmount] = useState(initData?.claimMstInfo?.orgDebitMemoAmount);
  const [approvalLineView, setApprovalLineView] = useState(initData?.approvalList?.length > 0);
  const [isHiddenApporval, setIsHiddenApporval] = useState(initData?.approvalList?.length > 0);
  const [approveCommentView, setApproveCommentView] = useState(initData?.input?.selApprovalStatus === "TO-DO");
  const [systemEntryNoView, setSystemEntryNoView] = useState(initData?.claimMstInfo?.systemEntryNo ?? "");
  const [updateDateView, setUpdateDateView] = useState(initData?.claimMstInfo?.updateDate ?? "");
  const [claimStatusView, setClaimStatusView] = useState(initData?.claimMstInfo?.claimStatus ?? "");
  const [prevJobConfirmView, setPrevJobConfirmView] = useState(false);
  const [btnConfirmRejectView, setBtnConfirmRejectView] = useState(false);
  const [btnTransferView, setBtnTransferView] = useState(false);
  const [btnGerpErrorCountView, setBtnGerpErrorCountView] = useState(false);


  const [discountChart, setDiscountChart] = useState(false);
  const randomKey = useRef(null);

  // popup
  const [openModalBillTo, setOpenModalBillTo] = useState(false);
  const [openModalShipTo, setOpenModalShipTo] = useState(false);
  const [openModalCharge, setOpenModalCharge] = useState(false);
  const [openModalExcelUpload, setOpenModalExcelUpload] = useState(false);
  const [openModalModelCode, setOpenModalModelCode] = useState(false);
  const [openModalLgProgramModel, setOpenModalLgProgramModel] = useState(false);
  const [openModalLgProgram, setOpenModalLgProgram] = useState(false);
  const [openModalLgOrgProgram, setOpenModalLgOrgProgram] = useState(false);
  const [openModalAnalyst, setOpenModalAnalyst] = useState(false);
  const [openModalInvoiceNo, setOpenModalInvoiceNo] = useState(false);
  const [openModalPriceInvoiceNo, setOpenModalPriceInvoiceNo] = useState(false);
  const [openModalSupplier, setOpenModalSupplier] = useState(false);
  const [openModalInvoiceReturnList, setOpenModalInvoiceReturnList] = useState(false);
  const [openModalInvoiceUsedList, setOpenModalInvoiceUsedList] = useState(false);
  const [openModalInvoiceTax, setOpenModalInvoiceTax] = useState(false);
  const [openModalDAInvoice, setOpenModalDAInvoice] = useState(false);
  const [openGSRESNUploadPop, setOpenGSRESNUploadPop] = useState(false);
  const [openModalClaimRequestPop, setOpenModalClaimRequestPop] = useState(false);

  const modelResData = useRef(null);
  const chargeResData = useRef(null);
  const billToResData = useRef(null);
  const shipToResData = useRef(null);
  const lgProgramModelResData = useRef(null);
  const lgProgramResData = useRef(null);
  const lgOrgProgramResData = useRef(null);
  const analystResData = useRef(null);
  const invoiceNoResData = useRef(null);
  const invoiceTaxResData = useRef(null);
  const invoiceNoSearchParam = useRef('');
  const invoiceReturnListParam = useRef({ requstType: '', invoiceReturnList: [] });
  const invoiceUsedListParam = useRef({ requstType: '', invoiceUsedList: [] });
  const invoiceUsedInitParam = useRef(null);
  const priceInvoiceResData = useRef(null);

  const defaultBillToSearchParam = useRef({
    url: retrieveBillToList
    , comCode: comCode
    , userType: userType
    , userGroup: userGroup
    , userLevel: "1" /* ASIS에서 */
    , billToCode: ""      //changeValue
    , billToName: ""
    , pageSize: "1"
    , rowSize: pageInfo.pageRowSize
    , pageNo: "1"
    , userLevel: "1"
  });
  const defaultShipToParam = useRef({
    url: retrieveShipToList
    , comCode: comCode
    , userType: userType
    , userGroup: userGroup
    , sBillTo: ""
    , sShipToCode: ""
    , sShipToName: ""
    , pageSize: "1"
    , rowSize: pageInfo.pageRowSize
    , pageNo: "1"
    , userLevel: "1"
  });
  const defaultChargeSearchParam = useRef({
    url: retrieveLGChargebackList
    , billToCode: ""
    , chargebackNo: ""
    , cbInvoiceNo: ""
    , debitMemoNo: ""
    , referenceNo: ""
    , chargebackAmount: ""
    , reasonCode: ""
    , reasonDesc: ""
    , pageSize: "1"
    , rowSize: pageInfo.pageRowSize
    , pageNo: "1"
    , formYn: ""
  });
  const defaultModelSearchParam = useRef({
    url: retrieveModelList
    , comCode: comCode
    , modelCode: ""  //changeValue
    , modelDesc: ""
    , invoiceNo: ""
    , claimAnalystYn: "Y"
    , errorYn: ""
    , pageSize: "1"
    , rowSize: pageInfo.pageRowSize
    , pageNo: "1"
  });
  const defaultLgProgramModelSearchParam = useRef({
    url: retrieveProgramModelList
    , comCode: comCode
    , billToCode: ""
    , modelCode: "" //changeValue
    , divisionCode: ""
    , priceMoveDate: ""
    , pageSize: "1"
    , rowSize: pageInfo.pageRowSize
    , pageNo: "1"
  });
  const defaultLgProgramSearchParam = useRef({
    url: retrieveProgramList
    , comCode: comCode
    , paramBillTo: ""
    , modelCode: ""
    , claimType: ""
    , lgPgmNo: ""
    , lgPgmName: ""
    , reasonCode: []
    , startDate: '01/01/2000'
    , endDate: '12/31/2099'
    , requestAmt: ""
    , pageSize: "1"
    , rowSize: pageInfo.pageRowSize
    , pageNo: "1"
  });
  const defaultLgOrgProgramSearchParam = useRef({
    url: retrieveProgramList
    , comCode: comCode
    , paramBillTo: ""
    , modelCode: ""
    , claimType: ""
    , divisionCode: ""
    , lgPgmNo: ""
    , lgPgmName: ""
    , reasonCode: []
    , startDate: '01/01/2000'
    , endDate: '12/31/2099'
    , accuralTransfer: "Y"
    , requestAmt: ""
    , pageSize: "1"
    , rowSize: pageInfo.pageRowSize
    , pageNo: "1"
  });
  const defaultAnalystSearchParam = useRef({
    url: "/com/comPop/retrieveAnalyList"
    , userType: userType
    , claimAnalyst: ""  //changeValue
    , billToCode: ""
    , salesChannelName: ""
    , empNo: ""
    , claimType: ""
    , divisionCode: ""
    , reqType: ""
    , pageSize: "1"
    , rowSize: pageInfo.pageRowSize
    , pageNo: "1"
  });
  const defaultInvoiceNoSearchParam = useRef({
    url: "/com/comPop/retrieveInvoiceList"
    , comCode: comCode
    , invoiceNo: ""  //changeValue
    , paramBillTo: ""
    , claimType: ""
    , startDate: ''
    , endDate: ''
    , pageSize: "1"
    , rowSize: pageInfo.pageRowSize
    , pageNo: "1"
  });
  const defaultInvoiceTaxSearchParam = useRef({
    url: "/com/comPop/retrieveTaxInvoiceList"
    , comCode: comCode
    , invoiceNo: ""  //changeValue
    , paramBillTo: ""
    , claimStatus: ""
    , systemEntryNo: ""
    , startDate: ''
    , endDate: ''
    , pageSize: "1"
    , rowSize: pageInfo.pageRowSize
    , pageNo: "1"
  });
  const defaultInvoicePriceSearchParam = useRef({
    url: "/com/gsrComPop/retrievePriceInvoiceList"
    // , comCode: comCode
    , billToCode: ""  //changeValue
    , invoiceNo: ""
    , endDate: ""
    , pageSize: "1"
    , rowSize: pageInfo.pageRowSize
    , pageNo: "1"
  });

  // grid
  const ClaimClassPool = useRef({});
  const [claimType, setClaimType] = useState(initData?.claimMstInfo?.claimType ?? "");
  const claimRef = useRef(null);

  const gridObject = useRef();
  const gridObjApprv = useRef();
  const [gridColGroup, setGridColGroup] = useState(null);
  const [gridData, setGidData] = useState(null);
  const displayCheck = useRef(false);
  const rejectCheck = useRef(false);
  const [gridDataApprv, setGridDataApprv] = useState(new CollectionView(initData?.approvalList ?? [], { trackChanges: true, refreshOnEdit: false }));
  const [gridColGroupApprv, setGridColGroupApprv] = useState(null);

  // button click event
  const fncTempSaveConfirm = (type) => {
    confirmType.current = "SAVE";

    let _claimStatus = initData?.claimMstInfo?.claimStatusCode;
    let pendingCode = entityPendingCode?.current?.getValue() ?? "";

    if (pendingCode) {
      if ("REQUEST" === type) {
        confirmType.current = "REQUEST";
        claimStatus.current = "AR";
        _claimStatus = 'AR';
      } else {
        claimStatus.current = "A8";
        _claimStatus = 'A8';
      }
    } else {
      if ("REQUEST" === type) {
        confirmType.current = "REQUEST";
        claimStatus.current = "AR";
        _claimStatus = 'AR';
      } else {
        claimStatus.current = "A2";
        _claimStatus = "A2";
      }
    }

    if (_claimStatus == "AR") {
      if (!fncCheckValidation("A2")) {
        return;
      };
    } else {
      if (!fncCheckValidation("A1")) {
        return;
      };
    }

    newApprovalLine.current = "Y";
    updateUrl.current = updateOtherClaimConfirmUrl;

    fncSaveConfirm();
  }

  const fncClaimAnalystReject = () => {
    // Do you want to reject this claim?
    ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, ComUtils.getMessage(messageObject, "GSR_CNF_00017")
      , (isConfirmed) => {
        if (!isConfirmed)
          return;

        if (!checkEndEditing()) {
          return
        }

        let rows = gridObject.current?.rows ?? [];

        for (let i = 0; i < rows.length; i++) {
          let dataItem = rows[i].dataItem;
          if (!dataItem?.rejectCode) {
            //{0} row : {1} is required.
            ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00029", [(i + 1), "Rejection Reason"]));
            return;
          }
          if (!dataItem?.rejectDesc) {
            //{0} row : {1} is required.
            ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00029", [(i + 1), "Rejection Comment"]));
            return;
          }
        }

        //UpdateOtherClaimConfirmCmd
        confirmType.current = "CLAIMANALYST_REJECT";
        claimStatus.current = "A5"
        vConfirmSubmit.current = 'N';

        updateUrl.current = updateOtherClaimConfirmUrl;
        updateOrderClaim(true);
      });
  }

  const fncApprove = () => {
    if ("Y" === input.current?.endFlag) {
      //"This is final phase.\nDo you really want to approve ?
      ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, ComUtils.getMessage(messageObject, "COM_CNF_00006", ["approve"])
        , (isConfirmed) => {
          if (isConfirmed) {
            claimStatus.current = "A3";
            confirmType.current = "APPROVE";

            if (!fncCheckValidation("A2")) {
              return;
            };

            updateUrl.current = updateOtherClaimConfirmUrl;
            fncSaveConfirm();
          }
        });
    } else {
      //"Do you want to approve ?
      ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, ComUtils.getMessage(messageObject, "COM_CNF_00006", ["approve"])
        , (isConfirmed) => {
          if (isConfirmed) {
            claimStatus.current = "AA";
            confirmType.current = "APPROVE";

            if (!fncCheckValidation("A2")) {
              return;
            };

            updateUrl.current = updateOtherClaimConfirmUrl;
            fncSaveConfirm();
          }
        });
    }
  }
  const fncReject = () => {
    let approveComment = entityApproveComment?.current?.getValue() ?? "";
    if (!approveComment) {
      // Enter the Approval / Rejection Comment .');
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00100", ["Approval / Rejection Comment"]), () => {
        entityApproveComment?.current?.setFocus();
      });
      return;
    }

    // Do you want to reject ?
    ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, ComUtils.getMessage(messageObject, "COM_CNF_00006", ["reject"]), (isConfirmed) => {
      if (isConfirmed) {
        confirmType.current = "REJECT";
        claimStatus.current = "A5";

        updateUrl.current = updateOtherClaimConfirmUrl;
        fncSaveConfirm();
      }
    });

  }

  const fncTransfer = () => {
    //Are you sure you want to submit this Transfer Request?
    ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, ComUtils.getMessage(messageObject, "GSR_CNF_00016"), (isConfirmed) => {
      if (isConfirmed) {
        confirmType.current = "TRANSFER";
        claimStatus.current = "AF"
        if (!fncCheckValidation("A2")) {
          return;
        };

        updateUrl.current = updateOtherClaimConfirmUrl;
        fncSaveConfirm();
      }
    });
  }

  const fncConfirm = () => {
    confirmType.current = "CONFIRM";
    claimStatus.current = "A3"
    if (!fncCheckValidation('A2')) {
      return;
    };

    updateUrl.current = updateOtherClaimConfirmUrl;
    fncSaveConfirm();
  }

  const fncTempSave = () => {
    if (!fncCheckValidation("A1")) {
      return;
    };

    if (!checkEndEditing(true)) {
      return;
    }

    let rows = gridObject.current?.rows ?? [];
    if (rows.length < 1) {
      // Please enter one or more row
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00038"), () => { });
      return;
    }

    if (!fncFileCheck()) return;

    claimStatus.current = "A1";
    updateOrderClaim(true);
  }

  const fncESNUploadExcel = () => {
    setOpenGSRESNUploadPop(true);
  };

  const fncSave = async (type) => {
    let rows = gridObject.current.rows ?? [];
    let view = gridObject?.current?.collectionView;
    let pendingCode = entityPendingCode.current?.getValue() ?? "";
    let claimType = entityClaimType.current.getValue();


    if (pendingCode !== "") {
      if ("REQUEST" === type) { claimStatus.current = "AR"; }
      else { claimStatus.current = "A8"; }
    } else {
      if ("REQUEST" === type) { claimStatus.current = "AR"; }
      else { claimStatus.current = "A2"; }
    }

    if (!checkEndEditing()) {
      return false;
    }

    if (rows.length < 1) {
      // Please enter one or more row
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00038"), () => { });
      return false;
    }

    if (claimStatus.current === "A8") {
      //OTHER CLAIM - Original Amt zero error modification C20180810_63178
      let debitMemoAmt = entityDebitMemoAmt.current.getValue() || 0;
      if (!debitMemoAmt) {
        //Enter the Request Amount
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00100", ["Request Amount"]), () => entityDebitMemoAmt.current.setFocus());
        return false;
      }

      let requestAmtTotal = 0;

      for (let i = 0; i < view.items.length; i++) {
        if (view.items[i].requestAmt != undefined) {
          requestAmtTotal += Number(Number(view.items[i].requestAmt).toFixed(2));
        }
      }

      if (Number(debitMemoAmt.toFixed(2)) != Number(requestAmtTotal.toFixed(2))) {
        // Request Amt Total] value must be equal to [Request Amount].');
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00112", ["Request Amt Total", "Request Amount"]), () => { });
        return false;
      }
    } else {
      if (!fncCheckValidation('A2')) {
        return;
      };
    }

    if (!fncFileCheck())
      return;

    if (claimType === '5') {
      let resultData = await ApiManager.post(retrieveInvoiceNetQtyChk, { list: view.items });
      if (resultData?.statusCode) {
        ComNoticfication.ShowAlertUI(ComUtils._altTitleError, msgOthErr001);
        return;
      }

      if (resultData?.errMsg) {
        ComNoticfication.ShowAlertUI(ComUtils._altTitleError, resultData?.errMsg);
      } else {
        //  check duplicate Invoice
        if (claimStatus.current === "AR" || claimStatus.current === "A3") {
          fncCheckInvoiceUsed('Submit');
        } else {
          fncSubmit('');
        }
      }
    } else {
      if (claimType === "6" || claimType === "7" || claimType === "8") {
        //  check duplicate Invoice
        if (claimStatus.current === "AR" || claimStatus.current === "A3") {
          fncCheckInvoiceUsed('Submit');
        } else {
          fncSubmit(type);
        }
      } else if (claimType === "9") {//Damage Allowance
        fncCheckNoReturnCustomer('Submit');
      } else if (claimType === "1" || claimType === "2" || claimType === "13") {
        fncCheckPromotionNo('Submit');
      } else {
        if (initData?.claimMstInfo?.rvsClaimNo) {//Reverse Claim
          fncCheckReverseAmount('Submit');
        } else {
          fncSubmit(type);
        }
      }
    }
  };

  const fncSaveConfirm = async () => {
    if (!checkEndEditing()) {
      return false;
    }

    if (gridObject?.current?.rows?.length < 1) {
      // Please enter one or more row
      ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00038"));
      return false;
    }

    let claimType = entityClaimType.current?.getValue();

    if (claimType === "5") {
      let resultData = await ApiManager.post(retrieveInvoiceNetQtyChk, { list: gridObject?.current?.collectionView?.items });
      if (resultData?.statusCode) {
        ComNoticfication.ShowAlertUI(ComUtils._altTitleError, msgOthErr001);
        return;
      }

      if (resultData?.errMsg) {
        ComNoticfication.ShowAlertUI(ComUtils._altTitleError, resultData?.errMsg);
      } else {
        //  check duplicate Invoice
        if (claimStatus.current === "AR" || claimStatus.current === "A3") {
          fncCheckInvoiceUsed('Confirm');
        } else {
          fncConfirmProcess('Confirm');
        }
      }
    } else {
      if (claimType === "6" || claimType === "7" || claimType === "8") {
        //  check duplicate Invoice
        if (claimStatus.current === "AR" || claimStatus.current === "A3") {
          fncCheckInvoiceUsed('Confirm');
        } else {
          fncConfirmProcess('Confirm');
        }
      } else if (claimType === "9") {//Damage Allowance
        fncCheckNoReturnCustomer('Confirm');
      } else if (claimType === "1" || claimType === "2" || claimType === "13") {
        fncCheckPromotionNo('Confirm');
      } else {
        if (initData?.claimMstInfo?.rvsClaimNo) {//Reverse Claim
          fncCheckReverseAmount('Confirm');
        } else {
          fncConfirmProcess('Confirm');
        }
      }
    }
  };

  const fncCheckReverseAmount = async (requstType) => {
    let view = gridObject?.current?.collectionView;

    let list = view.items?.map(item => ({
      modelCode: item.modelCode ?? "0",
      lgPgmNo: item.lgPgmNo ?? "0",
      promoLineId: item.promoLineId ?? "0",
      requestAmt: item.requestAmt ?? "0",
    }));

    let sendParam = {
      systemEntryNo: initData?.claimMstInfo?.systemEntryNo,
      rvsClaimNo: initData?.claimMstInfo?.rvsClaimNo,
      rvsClaimId: initData?.claimMstInfo?.rvsClaimId,
      rvsClaimType: initData?.claimMstInfo?.rvsClaimType,
      list,
    };

    let resultData = await ApiManager.post(retrieveReverseAmountChk, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleError, msgOthErr001);
      return;
    }

    if (resultData?.errMsg) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleError, resultData?.errMsg);
    } else {
      if (requstType === "Submit") {
        fncSubmit('');
      } else {
        fncConfirmProcess('Confirm');
      }
    }
  }

  const onClickAddRow = () => {
    if (!checkClaimType())
      return;

    let view = gridObject.current?.collectionView;
    if (view?.items?.length > 0) {
      if (checkBill()) {
        return;
      }
    }

    if (!checkEndEditing(true)) {
      return;
    }

    let claimType = entityClaimType?.current?.getValue() ?? "";
    let lgChargebackNo = entityLgChargebackNo?.current?.getValue() ?? "";
    let quotationNo = entityQuotationNo.current.getValue() ?? "";
    let multiQuote = entityMultiQuote.current.getValue();

    let newRow = claimRef.current.getData()[0];

    if (claimType === "9") {
      newRow.requestQty = 1;
    }

    if ((gSingleMulti.current ?? "") === "N" && lgChargebackNo !== "") {
      // newRow.lgChargebackNo = lgChargebackNo;
    }

    if (claimType == "3" && (multiQuote == "N" || multiQuote == "NQ")) {
      if (multiQuote == "NQ") {
        quotationNo = "No Quote";
      }

      newRow.quotationNo = quotationNo;
    }

    gridObject.current?.editableCollectionView.addNew(newRow, true);
    gridObject?.current?.collectionView?.refresh();
  };

  const onClickDeleteRow = () => {
    let hasDeleteRow = gridObject.current?.rows.find(r => r.dataItem.isChecked2);

    if (hasDeleteRow !== undefined && hasDeleteRow !== null) {
      let flexGrid = gridObject.current;
      flexGrid?.rows?.filter((el) => el.dataItem.isChecked2)?.forEach((el) => {
        flexGrid?.editableCollectionView?.remove(el.dataItem);
      });
      flexGrid?.editableCollectionView?.commitEdit();

      gridObject?.current?.collectionView?.refresh();
      gridObject.current.select(-1, -1);
    } else {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00005", ["items to delete"]));
    }
  };

  const onClickUploadExcel = () => {
    if (checkBill()) {
      return;
    }

    if (!checkClaimType()) {
      return;
    }

    if (gridObject.current?.collectionView?.items?.length > 0) {
      ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, ComUtils.getMessage(messageObject, "GSR_CNF_00006") // "The currnet row will be deleted.\nAre you sure you want to proceed?"
        , (isConfirmed) => {
          if (isConfirmed) {
            openExcelUploadPopup();
          }
        });
    } else {
      openExcelUploadPopup();
    }
  }

  const onClickInvoice = () => {
    if (gridObject.current.collectionView.items.length == 0) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, ComUtils.getMessage(messageObject, "OTH_ERR_00006", ["at least one row or more"]));
      return;
    }

    if (!checkEndEditing()) {
      return;
    }

    setOpenModalDAInvoice(true);
  }

  // 파일 업로드 관련
  const onClickUploadFile = () => {
    fileElement.current.click();
  };

  const uploadFileExcute = async (uploadFileInfo) => {
    let formData = new FormData();
    formData.append('UploadFile', uploadFileInfo);
    formData.append("customPath", "claim");
    formData.append("createDate", "T");
    try {
      let retrieveFileData = await ApiManager.post("/Common/File/FileUploadCustomPath", formData);
      let uploadedFileInfo = { "serverFileName": retrieveFileData[0].savedFileName, "filePath": retrieveFileData[0].savedFilePath, "fileSize": uploadFileInfo.size, "displayFileName": retrieveFileData[0].orgFileName, "userId": userId }
      setFileList(fileList => [...fileList, uploadedFileInfo]);
    }
    catch (ex) {
      console.log(ex);
    }
    fileElement.current.value = "";
  };

  const callFileDownload = async (fileInfo, event) => {

    if (fileInfo?.attachNo) {

      let fileinfo = await ApiManager.downloadFileById(retrieveAttachFileListByAttachNoUrl, { "systemEntryNo": systemEntryNo.current, "attachNo": fileInfo.attachNo })
      if (fileinfo != -1) {
        let filename = null;
        let disposition = fileinfo.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }

        if (filename) {
          let extPass = false;
          filename = filename.replace(/\.\./g, "");
          let extensionRegex = /\.([^.]*)$/;
          let match = filename.match(extensionRegex);

          if (FileUtils.allowFileExt?.indexOf(match[1].toLowerCase()) > -1) {
            extPass = true;
          } else {
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgOthErr024);
            return;
          }
          if (extPass) {
            try {
              FileSaver.saveAs(new Blob([fileinfo.data], { type: fileinfo.headers['content-type'] }), decodeURI(filename));
            } catch (ex) {
              console.log(ex);
              ComNoticfication.ShowAlertUI(ComUtils._altTitleInfo, msgOthInf008);

            }
          }
        }
      }
    }
    else {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleInfo, msgOthInf009);

    }
  }

  const removeFile = async (fileInfo, event) => {
    if (fileInfo?.isOld) {
      // 기존파일
      setFileList(fileList => fileList.map((file) => file.attachNo == fileInfo.attachNo ? { ...file, "isDeleted": true } : file));
    } else {
      // 신규파일
      const response = await ApiManager.post("/Common/File/FileDelete", { "filePath": fileInfo.filePath, "serverFileName": fileInfo.serverFileName, "customPath": "claim" });
      setFileList(fileList => fileList.filter(f => f.serverFileName !== fileInfo.serverFileName));
    }
  };

  const handleFileNoChange = (selectedFile, value) => {
    setFileList(prevFileList =>
      prevFileList.map((file) =>
        file === selectedFile ? { ...file, fileNo: value } : file
      )
    );
  }

  // changed event
  const onChangedReverse = (elementId, selectedValue) => {
    if (selectedValue === "Y") {
      setDisablePaymentMethod(true);

      if (entityPaymentMethod.current?.getValue() === "CHECK") {
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, msgOthInf001, () => {
          entityReverse.current.setValue("N");
        });
      }
    } else {
      setDisablePaymentMethod(false);
    }
  }

  const onChangedDivision = async (elementId, selectedValue) => {
    if (selectedValue === 'OEM') {
      setListClaimType(prevItems =>
        prevItems.map(item => item.Value === '10' ? { ...item, Disable: false } : item)
      );
    } else {
      setListClaimType(prevItems =>
        prevItems.map(item => item.Value === '10' ? { ...item, Disable: true } : item)
      );
      //onChangedClaimType(undefined, "1");
    }

    let claimAnalystInfo = await ApiManager.post(retrieveClaimAnalystInfo,
      { divisionCode: selectedValue, sBillTo: entityBillTo.current.getCode() ?? '' },
      { loadbarOpen: false });

    entityClaimAnalyst.current.setValue(claimAnalystInfo?.claimAnalyst);
    claimAnalystEmpNo.current = claimAnalystInfo?.empNo;

    let claimType = entityClaimType.current.getValue();
    if (claimType == "4") {
      resetInvoiceFromToAll(selectedValue);
    }
  }

  const changeClaimType = async (type, isInit) => {
    rejectCheck.current = false;

    if (userType === 'E') {
      if (type === "9") { // Damage Allow
        setTimeout(() => showDiscountChart(), 400);
      }
    }

    if (!isInit) {
      let resReason = await ApiManager.post(retrieveReasonCodeList, { class: "040", claimType: type }, { loadbarOpen: false });
      entityReasonCode.current?.setList({ data: selCombo.concat(resReason), code: "nm", name: "locDescription", initValue: initData?.claimMstInfo?.reasonCode });
    }

    entityLgChargebackNo.current?.setDisabled(type === "7");
    if (type === "7") {
      entityLgChargebackNo.current?.setValue('');
    } 

    claimRef.current = ClaimClassPool.current[type];
    setClaimType(type);
    initGridFormat(type);

    let gridList = initData?.claimDtlList?.map(obj => ({
      ...obj,
      isChecked2: false,
      modelCode: isInit ? obj.modelCode : '',
      invoiceNo: isInit ? obj.invoiceNo : '',
    }));

    setGidData(
      new CollectionView(gridList, {
        trackChanges: true,
        refreshOnEdit: false,
      })
    );
  }

  const onChangedClaimType = (elementId, selectedValue) => {
    changeClaimType(selectedValue);
  }

  const onChangedPaymentMethod = async (elementId, selectedValue) => {
    setDisableVenderName(selectedValue === "CREDIT");
    // entityVendorName.current?.setDisabled(selectedValue === "CREDIT");
    entityPayToAddress.current?.setDisabled(selectedValue === "CREDIT");

    if (selectedValue === "CREDIT") {
      entityVendorName.current.setValue('');
      entityVendorCode.current.setValue('');
      vendorSiteCode.current = '';
      entityPayToAddress.current?.setValue('');
    } else {
      let reverseFlag = entityReverse.current.getValue();
      if (reverseFlag === "Y") {
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, msgOthInf001, () => {
          entityPaymentMethod.current.setValue('CREDIT');
        });
        return;
      }

      if (checkBill()) {
        // entityPaymentMethod.current.setValue('CREDIT');
        return;
      }

      let resultData = await ApiManager.post(retrieveOutInvAmt, { billToCode: entityBillTo.current.getCode() ?? '' });
      if (resultData.length > 0) {
        let outInvAmt = wijmo.Globalize.format(resultData[0].outInvAmt, "n2");
        entityOutstandingInvoiceAmt.current.setValue(outInvAmt);

        if (Number(resultData[0].outInvAmt) > 0) {
          ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, msgOthInf002, () => {
            entityPaymentMethod.current.setValue('CREDIT');
          });
          return;
        }
      }
    }
  }

  const onChangedMultiQuote = (elementId, selectedValue) => {
    entityQuotationNo.current.setDisabled(selectedValue === "Y");
    let claimType = entityClaimType.current.getValue();

    if (selectedValue === "Y") {
    } else {
      if (claimType == "3") {
        let quotationNo = "";
        if (selectedValue == "NQ") {
          quotationNo = "No Quote";
        } else if (selectedValue == "N") {
          quotationNo = entityQuotationNo.current.getValue()?.trim() ?? "";
        }

        let view = gridObject?.current?.collectionView;
        if (view) {
          view.items.map((item) => item.quotationNo = quotationNo);
          view.refresh();
        }
      }
    }

    entityQuotationNo.current.setValue("");
  }

  const onChangedGroupCode = (elementId, selectedValue) => {
    // 그리드의 Transfer From Program No orgPgmNo값 초기화
    if (selectedValue !== "Y") {
      const flexGrid = gridObject?.current; // FlexGrid 인스턴스에 접근
      if (flexGrid) {
        flexGrid.collectionView.items.map(item => item.orgPgmNo = '');
        flexGrid.collectionView?.refresh();
      }
    }

    if (initData?.claimMstInfo?.claimStatusCode === "A3"
      && initData?.input?.claimAnalystYn === "Y"
      && "12313".indexOf(claimType ?? "") > -1
      && parseInt(initData?.claimMstInfo?.gerpErrorCount ?? "0") > 0) {
      setBtnTransferView(selectedValue === "Y");
    }

    claimRef.current.update({ isDisabledorgPgmNo: selectedValue !== "Y" });
    gridObject?.current.collectionView?.refresh();
  }

  const checkBill = () => {
    if (!entityBillTo.current.getCode() || !entityBillTo.current.getText()) {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleWarn, msgOthWar001, () => entityBillTo.current.setFocusCode());
      return true;
    } else {
      return false;
    }
  }

  const searchDebitMemoInnser = async () => {
    let billToCode = entityBillTo.current.getCode();
    let referenceNo = entityDebitMemoNo.current.getValue()?.trim() ?? "";

    // chargebackNo, referenceNo, debitMemoNo, reasonCode
    let resultData = await ApiManager.post(retrieveChargebackList, { billToCode, referenceNo });

    if (resultData?.list) {
      if (resultData?.list.length == 1) {
        if (gSingleMulti.current === 'N') {
          entityLgChargebackNo.current.setValue(resultData?.list[0].cbInvoiceNo);

          let items = gridObject.current?.collectionView?.currentItem;
          if (items) {
            if ("" === (items?.lgChargebackNo ?? "")) {
              items.lgChargebackNo = resultData?.list[0].cbInvoiceNo;

              let requestAmt = items.requestAmt;
              let chargebackAmount = parseFloat(resultData?.list[0].chargebackAmount.replaceAll(',', ''));
              let newChargebackAmount = 0.00;

              if (chargebackAmount >= requestAmt) {
                newChargebackAmount = requestAmt;
              } else {
                newChargebackAmount = chargebackAmount;
              }

              items.lgChargebackAmt = newChargebackAmount;
              gridObject.current?.collectionView?.refresh();
            }
          }
        }
      } else if (resultData?.list.length > 1) {
        defaultChargeSearchParam.current.billToCode = entityBillTo?.current?.getCode() ?? "";
        defaultChargeSearchParam.current.referenceNo = entityDebitMemoNo?.current?.getValue() ?? "";
        defaultChargeSearchParam.current.chargebackNo = entityLgChargebackNo?.current?.getValue() ?? "";
        let resultData = await ApiManager.post(retrieveLGChargebackList, defaultChargeSearchParam.current);

        searchChargeback("entityLgChargebackNo", entityLgChargebackNo?.current?.getValue(), resultData);
      }
    }
  }

  const searchClaimAnalystSearch = async (elementId, value) => {
    if (checkBill()) { return; }

    randomKey.current = ComUtils.setRefRandomKey();
    setOpenModalAnalyst(true);
  }

  const searchDebitMemo = async (elementId, value) => {
    if (checkBill()) {
      entityDebitMemoNo.current.setValue('');
      return;
    }

    let billToCode = entityBillTo.current.getCode();
    let debitMemoNo = entityDebitMemoNo.current.getValue()?.trim() ?? "";

    if (debitMemoNo === '') return;

    let sendParam = {
      billToCode: billToCode,
      debitMemoNo: debitMemoNo,
      systemEntryNo: systemEntryNo.current
    };

    let resultData = await ApiManager.post(retrieveDebitMemoInfo, sendParam);
    if (resultData?.list) {
      if (resultData?.list.length == 0) {
        searchDebitMemoInnser();
      } else {
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo
          , ComUtils.getMessage(messageObject, "OTH_INF_00003", [resultData?.list[0].systemEntryNo, resultData?.list[0].debitMemoNo, (resultData?.list[0].lgPgmNo ?? ''), resultData?.list[0].requestDate])
          , () => {
            entityDebitMemoNo.current.setFocus();
          }
        );

        prevInpus.current.debitMemoNo = '';
        entityDebitMemoNo.current.setValue('');
      }
    }

  }

  const searchChargeback = async (elementId, value, resultData) => {
    defaultChargeSearchParam.current.formYn = "Y";

    //조회결과 존재
    if (resultData?.list) {
      if (resultData.list.length == 1) {
        //단건이면 세팅 (팝업 오픈 X)
        if (defaultChargeSearchParam.current.formYn === "Y") {
          fncChargebackReturnMaster(resultData.list[0]);
        } else {
          fncChargebackReturnDetail(resultData.list[0]);
        }
      } else {
        //여러건이면 팝업으로 전달
        chargeResData.current = resultData;

        randomKey.current = ComUtils.setRefRandomKey();
        setOpenModalCharge(true);
      }
    } else {
      randomKey.current = ComUtils.setRefRandomKey();
      setOpenModalCharge(true);
    }
  }

  const searchQuotationNo = async (elementId, value) => {
    if (!value)
      return;

    if (checkBill()) {
      entityQuotationNo.current.setValue('');
      return;
    }

    let resultData = await ApiManager.post(retrieveQuotationNoInfo, { billToCode: entityBillTo.current.getCode(), quotationNo: value });
    if (resultData?.statusCode) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleError, msgOthErr001);
      return;
    }

    if (resultData.length == 0) {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleWarn, msgOthWar002, () => {
        prevInpus.current.quotationNo = '';
        entityQuotationNo.current.setValue('');
        entityQuotationNo.current.setFocus();
      });
    } else {
      let quotationNo = resultData[0]?.quotationNo ?? '';
      entityQuotationNo.current.setValue(quotationNo);
    }
  }

  const getGridQuotationNo = async (s, e) => {
    let dataItem = s.rows[e.row].dataItem;

    let resultData = await ApiManager.post(retrieveQuotationNoInfo, { billToCode: entityBillTo.current.getCode(), quotationNo: dataItem.quotationNo }, { loadbarOpen: false });
    if (resultData?.statusCode) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleError, msgOthErr001);
      s.refresh();
      return;
    }

    if (resultData.length == 0) {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleWarn, msgOthWar003, () => {
        dataItem.quotationNo = ""
        s.refresh();
      });
    } else {
      dataItem.quotationNo = resultData[0]?.quotationNo ?? '';
      s.refresh();

      fncModelBidQty();
    }
  }

  const checkEndEditing = (isSave) => {
    if (!gridObject.current) {
      return false;
    }

    let isValid = true;
    const grid = gridObject.current;

    for (let col of grid.columns) {
      if (col.isRequired) {
        for (let item of grid.itemsSource.items) {
          if (item[col.binding] == null || item[col.binding].toString().trim() === '') {
            // 수정일때는 activity date 체크 안함
            if (isSave) {
              if (col.binding === 'activitySalesClaimDate') {
                continue;
              }
            }

            // Please complete or delete the working Row.
            ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00115"), () => { });
            return false;
          }
        }
      }
    }

    let requestAmt = grid.collectionView?.currentItem?.requestAmt || 0;
    let lgChargebackAmt = grid.collectionView?.currentItem?.lgChargebackAmt || 0;
    if (parseFloat(lgChargebackAmt) > parseFloat(requestAmt)) {
      // "Chargeback amount should not be greater than Request Amount."
      ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00113"));
      return false;
    }

    return true;
  };

  const checkClaimType = () => {
    let claimType = entityClaimType?.current?.getValue();
    if (!claimType) {
      //choose the claim type.
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00117"));
      return false;
    } else {
      return true;
    }
  }

  const fncSerialNoChange = async (s, e) => {
    let claimType = entityClaimType.current.getValue();
    let sender = s;
    let dataItem = s?.rows[e.row]?.dataItem;

    let serialNo = dataItem?.serialNo ?? "";
    if (serialNo === "") {
      dataItem.serialChk = "N";
      sender.refresh();
      return;
    }
    serialNo = serialNo.toUpperCase();
    dataItem.serialNo = serialNo;

    let chk = true;
    for (let i = 0; i < s.rows.length; i++) {
      if (i != e.row) {
        if (serialNo == s.rows[i].dataItem.serialNo) {
          chk = false;
          dataItem.serialChk = "Y";
        }
      }
    }

    if (chk) {
      let resultData = await ApiManager.post(retrieveSerialNoCheckUrl, { claimType, serialNo, systemEntryNo: systemEntryNo.current }, { loadbarOpen: false });
      dataItem.serialChk = resultData?.serialNoCheck ?? "";
    }

    sender.refresh();
  };

  const fncOrgProgramChange = async (s, e) => {
    s.rows[e.row].dataItem.orgBillTo = "";
    s.refresh();
  }

  const fncOrgProgramListReturn = (list) => {
    let view = gridObject?.current?.collectionView;
    if (!view) {
      return;
    }

    let claimType = entityClaimType?.current?.getValue() ?? "";
    let billToCode = entityBillTo?.current?.getCode() ?? "";
    let currentItem = view.currentItem;
    let promotionNo = currentItem.promotionNo ?? "";
    let activitySalesClaimDate = "";

    if (claimType === '1' || claimType === '2' || claimType === '14') {
      activitySalesClaimDate = currentItem.activitySalesClaimDate;;
    }

    let lgPgmNo = currentItem.lgPgmNo;
    let lgPgmName = currentItem.lgPgmName;
    let pgmReasonCode = currentItem.pgmReasonCode;
    let customerDesc = currentItem.customerDesc;
    let modelCode = "";
    let requestQty = "";
    let requestPrice = "";
    let priceDropInvDate = "";
    let disputeFlag = currentItem.disputeFlag;
    let lgChargebackNo = entityLgChargebackNo.current.getValue() ?? "";

    if (claimType === '2' || claimType === '14' || claimType === '3' || claimType === '13') {
      modelCode = currentItem.modelCode;
      requestPrice = currentItem.requestPrice;
      requestQty = currentItem.requestQty;
    }
    if (claimType === '3' || claimType === '13') {
      priceDropInvDate = currentItem.priceDropInvDate;
    }

    for (let i = 0; i < list.length; i++) {
      if (i == 0) {
        currentItem.requestAmt = list[i].transferAmt;
        currentItem.orgPgmNo = list[i].offerCode;

        if (claimType === '2' || claimType === '14' || claimType === '3' || claimType === '13') {
          if (Number(requestQty) * Number(requestPrice) != Number(list[i].transferAmt)) {
            requestPrice = Number(list[i].transferAmt) / Number(requestQty);

            currentItem.requestPrice = Number(requestPrice.toFixed(2));
            currentItem.requestAmt = Number((Number(requestQty) * Number(requestPrice.toFixed(2))).toFixed(2));
          }
        }

        currentItem.orgBillTo = list[i].custCode;
      } else {
        if (claimType === '1') {
          let newRow = {
            isChecked2: false,
            promotionNo: promotionNo,
            orgRequestAmt: 0,
            requestAmt: Number(list[i].transferAmt),
            activitySalesClaimDate: activitySalesClaimDate,
            lgChargebackNo: lgChargebackNo,
            lgChargebackAmt: 0,
            lgPgmNo: lgPgmNo,
            lgPgmName: lgPgmName,
            pgmReasonCode: pgmReasonCode,
            orgPgmNo: list[i].offerCode,
            orgBillTo: list[i].custCode,
            customerDesc: customerDesc
          };

          gridObject.current?.editableCollectionView.addNew(newRow, true);
        } else if (claimType === '2' || claimType === '14') {
          let newRow = {
            isChecked2: false,
            promotionNo: promotionNo,
            modelCode: modelCode,
            orgRequestQty: 0,
            orgRequestPrice: 0,
            orgRequestAmt: 0,
            requestQty: 1,
            requestPrice: Number(list[i].transferAmt),
            requestAmt: Number(list[i].transferAmt),
            activitySalesClaimDate: activitySalesClaimDate,
            lgChargebackNo: lgChargebackNo,
            lgChargebackAmt: 0,
            lgPgmNo: lgPgmNo,
            lgPgmName: lgPgmName,
            pgmReasonCode: pgmReasonCode,
            orgPgmNo: list[i].offerCode,
            orgBillTo: list[i].custCode,
            customerDesc: customerDesc
          };

          gridObject.current?.editableCollectionView.addNew(newRow, true);
        } else if (claimType === '3' || claimType === '13') {
          let newRow = {
            isChecked2: false,
            promotionNo: promotionNo,
            modelCode: modelCode,
            orgRequestQty: 0,
            orgRequestPrice: 0,
            orgRequestAmt: 0,
            requestQty: 1,
            requestPrice: Number(list[i].transferAmt),
            requestAmt: Number(list[i].transferAmt),
            priceDropInvDate: priceDropInvDate,
            lgChargebackNo: lgChargebackNo,
            lgChargebackAmt: 0,
            lgPgmNo: lgPgmNo,
            lgPgmName: lgPgmName,
            pgmReasonCode: pgmReasonCode,
            orgPgmNo: list[i].offerCode,
            orgBillTo: list[i].custCode,
            customerDesc: customerDesc,
            disputeFlag: disputeFlag,
          };

          gridObject.current?.editableCollectionView.addNew(newRow, true);
        }
      }
    }

    gridObject.current.collectionView.refresh();
  }

  const fncFileCheck = () => {
    // AS-IS에서 빈값을 체크하는게 아닌듯함
    // if (fileList.length === 0) {
    //   // Please attach a file.
    //   ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00054"), () => { });
    //   return false;
    // }

    return true;
  };

  const fncCheckInvoiceUsed = async (requstType) => {
    let view = gridObject?.current?.collectionView;
    let invoiceNoList = view.items.map((item) => item.invoiceNo);

    let resultData = await ApiManager.post(retrieveInvoiceUsedList, { invoiceList: invoiceNoList, systemEntryNo: systemEntryNo.current });
    if (resultData?.statusCode) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleError, msgOthErr001);
      return;
    }

    if (resultData?.length > 0) {
      openInvoiceUsedListPopup(requstType, resultData);
    } else {
      if (requstType == 'Submit') {
        fncSubmit('');
      } else {
        fncConfirmProcess('Confirm');
      }
    }
  }

  const fncCheckNoReturnCustomer = async (type) => {
    let billToCode = entityBillTo.current?.getCode();
    let modelCodeList = gridObject?.current?.collectionView?.items.map((item) => item.modelCode);

    let resultData = await ApiManager.post(retrieveNoReturnCustomer, { billTo: billToCode, list: modelCodeList });
    if (resultData?.statusCode) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleError, msgOthErr001);
      return;
    }

    if (resultData?.errMessage) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleError, msgOthErr007);
      return true;
    } else {
      if (type == 'Submit') {
        fncSubmit(type);
      } else {
        fncConfirmProcess(type);
      }
    }
  }

  const fncCheckPromotionNo = async (type) => {
    //check promotion no if trade claim is N for claim type ADV. (MDF, Co-op, Display, VR), Sell-In(TC),Bundle, Markdown RITM0533540
    let claimType = entityClaimType.current?.getValue();
    let billToCode = entityBillTo.current?.getCode();
    let tradeClaim = entityTradeClaim.current?.getValue();
    let rows = gridObject?.current?.collectionView?.items;

    if ('1' === claimType || '2' === claimType || '13' === claimType) {
      if (tradeClaim === 'Y' || tradeClaim === 'N') {
        if (tradeClaim == 'N') {
          let promotionNoList = gridObject?.current?.collectionView?.items.map((item) => item.promotionNo);

          let resultData = await ApiManager.post(retrievePromotionNoInfo, { list: promotionNoList, billToCode: billToCode, claimType: claimType, userId: userId });
          if (resultData?.statusCode) {
            ComNoticfication.ShowAlertUI(ComUtils._altTitleError, msgOthErr001);
            return;
          }

          if (resultData?.validation === "no") {
          } else {
            if (resultData?.list?.length === 0) {
              ComNoticfication.ShowAlertUI(ComUtils._altTitleError, msgOthErr010);
              return false;
            } else {
              for (let i = 0; i < rows.length; i++) {
                let bExist = false;

                for (let j = 0; j < resultData?.list?.length; j++) {
                  if (rows[i].promotionNo == resultData?.list[j].promotionNo) {
                    bExist = true;

                    if (!rows[i].lgPgmNo) {
                      rows[i].lgPgmNo = resultData?.list[j].pgmNo;
                      rows[i].lgPgmName = resultData?.list[j].pgmName;
                    }
                    break;
                  }
                }
                if (bExist == false) {
                  ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00009", [rows[i].promotionNo]));
                  return false;
                }
              }
            }
          }

          if (type === 'Submit') {
            fncSubmit(type);
          } else {
            fncConfirmProcess(type);
          }
        } else {
          if (type === 'Submit') {
            fncSubmit(type);
          } else {
            fncConfirmProcess(type);
          }
        }
      } else {
        ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00005", ["Trade Claim"]));
        return false;
      }
    } else {
      return true;
    }

    return true;
  }

  const fncCheckInvoiceReturn = async (requstType) => {
    let invoiceNoList = gridObject?.current?.collectionView?.items.map((item) => item.invoiceNo);

    let resultData = await ApiManager.post(retrieveInvoiceReturnList, { list: invoiceNoList });
    if (resultData?.statusCode) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleError, msgOthErr001);
      return;
    }

    if (resultData?.length > 0) {
      openInvoiceReturnListPopup(resultData);
    } else {
      fncCheckDuplicateDebitMemo(requstType);
    }
  }

  const fncCheckDuplicateDebitMemo = async (type) => {
    let billToCode = entityBillTo.current?.getCode() ?? "";
    let debitMemoNo = entityDebitMemoNo.current?.getValue() ?? "";

    let resultData = await ApiManager.post(retrieveDebitMemoInfo, { billToCode, debitMemoNo, systemEntryNo: systemEntryNo.current });
    if (resultData?.list) {
      if (resultData?.list.length == 0) {
        if (type === 'Submit') {
          fncSubmit('AR');
        } else {
          fncConfirmProcess('AR');
        }
      } else {
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo
          , ComUtils.getMessage(messageObject, "OTH_INF_00003", [resultData?.list[0].systemEntryNo, resultData?.list[0].debitMemoNo, (resultData?.list[0].lgPgmNo ?? ''), resultData?.list[0].requestDate])
          , () => { }
        );
        return;
      }
    }
  }

  const fncConfirmProcess = (type) => {
    if (type === 'AR' || claimStatus.current !== "AR") {
      updateOrderClaim(true);
    } else {
      //invoice return check C20190919_88526
      let claimType = entityClaimType.current?.getValue();
      if (claimType === "6" || claimType === "7" || claimType === "8") {
        fncCheckInvoiceReturn(type);
      } else {
        fncCheckDuplicateDebitMemo('Confirm');
      }
    }
  }

  const fncSubmit = async (type) => {
    if (type === 'AR' || claimStatus.current !== "AR") {
      // No changes can be made after submit.
      ComNoticfication.ShowConfirmUI(ComUtils._altTitleInfo, ComUtils.getMessage(messageObject, "GSR_CNF_00002"), updateOrderClaim);
    } else {
      //invoice return check C20190919_88526
      let claimType = entityClaimType.current.getValue();
      if (claimType === "6" || claimType === "7" || claimType === "8") {
        fncCheckInvoiceReturn('Submit');
      } else {
        fncCheckDuplicateDebitMemo('Submit');
      }
    }
  }

  const updateOrderClaim = async (isConfirmed) => {
    if (!isConfirmed) {
      return;
    }

    vConfirmSubmit.current = "N";

    // null처리, Date 형식 변환
    let dtlList = gridObject.current.collectionView.items.map(item => {
      const rowData = {};

      for (const [key, value] of Object.entries(item)) {
        if (value != null) {
          rowData[key] = value instanceof Date ? wijmo.Globalize.format(value, 'MM/dd/yyyy') : value;
        } else {
          rowData[key] = '';
        }
      }
      return rowData;
    });

    let sendParam = {
      userType: userType
      , systemEntryNo: systemEntryNo.current ?? ""
      , claimStatus: claimStatus.current ?? ""
      , claimAnalystYn: claimAnalystYn ?? ""
      , prevJob: initData.input?.prevJob ?? ""
      , confirmType: confirmType.current ?? ""
      , approverYn: initData.input?.approverYn ?? ""
      , approvalLineSize: initData?.approvalList?.length || 0
      , newApprovalLine: newApprovalLine.current ?? ""
      , rvsClaimNo: initData?.claimMstInfo?.rvsClaimNo ?? ""
      , rvsClaimId: initData?.claimMstInfo?.rvsClaimId ?? ""
      , rvsClaimType: initData?.claimMstInfo?.rvsClaimType ?? ""
      , systemEntryNoUpload: systemEntryNoUpload?.current ?? ""
      , ppPeriod: ppPeriod?.current ?? ""
      , postAuditCustomer: postAuditCustomer?.current ?? ""

      , custEntry: entityCustEntry?.current?.getValue() ?? ""
      , postAuditClaim: entityPostAuditClaim?.current?.getValue() ?? ""
      , activityDateFrom: entityActivityDateFrom.current?.getOriginalValue() ? wijmo.Globalize.format(entityActivityDateFrom.current.getOriginalValue(), "MM/dd/yyyy") : ''
      , activityDateTo: entityActivityDateTo.current?.getOriginalValue() ? wijmo.Globalize.format(entityActivityDateTo.current.getOriginalValue(), "MM/dd/yyyy") : ''

      , reverseFlag: entityReverse.current?.getValue() ?? ""
      , billToCodeConfirm: entityBillTo.current.getCode() ?? ""
      , billToCode: entityBillTo.current.getCode() ?? ""
      , billToName: entityBillTo.current.getText() ?? ""
      , divisionCode: entityDivision.current?.getValue() ?? ""
      , shipToCode: entityShipTo?.current.getCode() ?? ""
      , shipToName: entityShipTo?.current.getText() ?? ""
      , billToAddress: entityBillToAddress.current.getValue() ?? ""
      , billToCity: entityBillToCity?.current.getValue() ?? ""
      , billToContactName: entityBillToContactName?.current?.getValue() ?? ""
      , billToState: entityBillToState?.current.getValue() ?? ""
      , billToContactTel: entityBillToContactTel?.current.getValue() ?? ""
      , billToZipCode: entityBillToZipCode?.current.getValue() ?? ""
      , billToContactEmail: entityBillToContactEmail?.current?.getValue() ?? ""
      , salesrepName: entitySalesrepName.current?.getValueItem()?.nm ?? ""    // ck
      , salesrepId: entitySalesrepName.current?.getValueItem()?.cd ?? ""    // ck
      , salesrepTel: entitySalesrepTel?.current.getValue() ?? ""
      , salesrepEmail: entitySalesrepEmail?.current?.getValue() ?? ""
      , claimType: entityClaimType?.current.getValue() ?? ""
      , reasonCode: entityReasonCode?.current?.getValue() === selCombo[0]?.nm ? "" : (entityReasonCode?.current?.getValue() || "")
      , claimAnalystEmpNo: claimAnalystEmpNo?.current ?? ""
      , claimAnalyst: entityClaimAnalyst.current?.getValue() ?? ""
      , debitMemoNo: entityDebitMemoNo?.current?.getValue() ?? ""
      , debitMemoAmt: entityDebitMemoAmt?.current?.getValue() ?? ""
      , paymentMethod: entityPaymentMethod.current?.getValue() ?? ""
      , lgChargebackNo: entityLgChargebackNo?.current?.getValue() ?? ""
      , vendorName: entityVendorName?.current?.getValue() ?? ""
      , vendorCode: entityVendorCode.current?.getValue() ?? ""
      , vendorSiteCode: vendorSiteCode.current ?? ""
      , outstandingInvoiceAmt: entityOutstandingInvoiceAmt?.current?.getValue() ? entityOutstandingInvoiceAmt?.current?.getValue().replaceAll(',', '') : 0
      , payToAddress: entityPayToAddress?.current?.getValue() ?? ""
      , pendingCode: entityPendingCode.current?.getValue() ?? ""
      , quotationNo: entityQuotationNo.current?.getValue() ?? ""
      , multiQuote: entityMultiQuote.current?.getValue() ?? ""
      , groupCodeFlag: entityGroupCodeFlag.current?.getValue() ?? ""
      , tradeClaim: entityTradeClaim.current?.getValue() ?? ""
      , moreClaims: entityMoreClaims.current?.getValue() ?? ""
      , followUpDate: entityFollowUpDate.current?.getOriginalValue() ? wijmo.Globalize.format(entityFollowUpDate.current.getOriginalValue(), "MM/dd/yyyy") : ''
      , followUpFlag: entityFollowFlag.current?.getValue() ?? ""
      , lgReviewComment: entityLgReviewComment.current?.getValue() ?? ""
      , customerClaimDesc: entityCustomerClaimDesc.current?.getValue() ?? ""
      , approveComment: entityApproveComment?.current?.getValue() ?? ""
      , docType: initData?.claimMstInfo?.docType

      , list: dtlList
      , fileList: fileList
      , fileNoList: fileList?.map(item => item?.fileNo ?? "") ?? []
    };

    let resultData = await ApiManager.post(updateUrl.current, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00028"));
      return;
    }

    if (updateUrl.current === updateOtherClaimConfirmUrl) {
      executeOtherClaimConfirmAfter(resultData);
    } else { // updateUrl.current === updateOtherClaimUrl
      executeOtherClaimAfter(resultData);
    }

    // url 초기화
    updateUrl.current = updateOtherClaimUrl;
  }

  const executeOtherClaimConfirmAfter = (resultData) => {
    let msg = "Submit";
    if (claimStatus.current === "A1") {
      msg = "Claim has been saved";
    } else {
      if (confirmType.current === "APPROVE") {
        msg = "Approved";
      } else if (confirmType.current === "SAVE") {
        msg = "Saved";
      } else if (confirmType.current === "REJECT") {
        msg = "Rejected";
      } else if (confirmType.current === "CONFIRM") {
        msg = "Confirmed";
      }
    }

    ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, ComUtils.getMessage(messageObject, "OTH_INF_00005", [msg]), async () => {
      let pageData;
      let goConfirm = "SAVE" === confirmType.current ? "Y" : "N";

      if ("APPROVE" === confirmType.current || "REJECT" === confirmType.current || "REQUEST" === confirmType.current) {
        let sendParam = {
          userId, comCode, userType, empNo,
          systemEntryNo: resultData?.params?.systemEntryNo,
          prevJob: "confirm"
        }
        pageData = await ApiManager.post(retrieveOtherClaimInfo, sendParam);
      } else {
        let sendParam = {
          userId, comCode, userType, empNo,
          systemEntryNo: resultData?.params?.systemEntryNo,
          confirm: goConfirm,
          approverYn: resultData?.params?.approverYn
        }

        pageData = await ApiManager.post(retrieveOtherClaimConfirmInfo, sendParam);
      }

      // UM, RM에따라 다시 열림
      closeFn?.(pageData);
    });
  }

  const executeOtherClaimAfter = (resultData) => {
    let msg = claimStatus.current === "A1" ? ComUtils.getMessage(messageObject, "OTH_INF_00004", ["Claim"]) :
      ComUtils.getMessage(messageObject, "OTH_INF_00005", ["Submit"]);

    ComNoticfication.ShowAlertUICallback(ComUtils._altTitleInfo, msg, async () => {
      if (claimStatus.current === "A1") {
        let sendParam = {
          userId, comCode, userType, empNo,
          systemEntryNo: resultData?.params?.systemEntryNo,
        }
        let pageData = await ApiManager.post(retrieveOtherClaimInfo, sendParam);
        closeFn?.(pageData);
      } else {
        // OtherClaimRequest에서 systemEntryNo로 조회함
        closeFn?.({ viewMode: 'CM', systemEntryNo: resultData?.params?.systemEntryNo });
      }
    });
  }

  // validation
  const fncCheckValidation = (flag) => {
    if (checkBill()) {
      return false;
    }

    let claimType = entityClaimType?.current?.getValue();
    let divisionCode = entityDivision?.current?.getValue();

    if (divisionCode === "") {
      // Please select Request From.
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00097", ["Request From"]), () => { });
      return false;
    }

    if (userType === 'E') {
      if (!divisionCode) {
        ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00005", ['Division']));
        return false;
      }
    }

    let sEmail = entityBillToContactEmail.current.getValue()?.trim();
    if (!GsrUtils.checkEmailAddress(sEmail)) {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00006", ["the [E-Mail] of the [Dealer Contact Information] correctly"]), () => entityBillToContactEmail.current?.setFocus());
      return false;
    }

    sEmail = entitySalesrepEmail.current.getValue()?.trim();
    if (!GsrUtils.checkEmailAddress(sEmail)) {
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00098"), () => entitySalesrepEmail.current?.setFocus());
      return false;
    }


    if ("A2" === flag) {
      if (checkBill()) { return false; }

      let billToCode = entityBillTo.current?.getCode();
      let noClaimBillToList = noClaimBillToListRef.current ?? [];
      let idx = noClaimBillToList.findIndex((item) => item.cd === billToCode);

      if (idx > -1) {
        //  Bill To Code({0}) is not authorized to submit a claim entry.	GSR_ERR_00095
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00095", [billToCode]), () => { });
        return false;
      }

      if ('Y' === postAuditCustomer) {
        let postAuditClaim = entityPostAuditClaim.current?.getValue();

        if (postAuditClaim == "Y" || postAuditClaim == "N") {
          if (postAuditClaim == "Y") {

            let activityDateFrom = entityActivityDateFrom?.current?.getDateValueInteger() ?? "";
            let activityDateTo = entityActivityDateTo?.current?.getDateValueInteger() ?? "";

            if (!activityDateFrom || !activityDateTo) {
              ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00008", ["Activity Date for Post Audit claim"]));
              return false;
            }

            if (activityDateFrom > activityDateTo) {
              ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00011", ["Activity From Date", "Activity To Date"]));
              return false;
            }

            if (activityDateFrom > toDay.current || activityDateTo > toDay.current) {
              ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00011", ["Activity Date", "today"]));
              return false;
            }
          }
        } else {
          ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00005", ["Post Audit claim"]));
          return false;
        }
      }

      if (!entityBillToContactName?.current?.getValue) {
        // Enter the [Name] of the [Dealer Contact Information]
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00096", ["Name", "Dealer Contact Information"])
          , () => entityBillToContactName.current.setFocus());
        return false;
      }

      if (!entityBillToContactTel?.current) {
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00096", ["Tel", "Dealer Contact Information"]), () => {
          setTimeout(() => {
            //entityBillToContactTel.current.setFocus();
            document.getElementById("entityBillToContactTel").focus();
          }, 100);
        });

        return false;
      }

      if (!entityBillToContactEmail?.current?.getValue()) {
        // Enter the [E-Mail] of the [Dealer Contact Information]
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00096", ["E-Mail", "Dealer Contact Information"]), () => entityBillToContactEmail.current.setFocus());
        return false;
      }

      //Ship To Code Information C20160415_38845
      if (!entityShipTo?.current?.getCode()) {
        // Select the Ship To Code.
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00097", ["Ship To Code"]), () => entityShipTo.current.setFocusCode());
        return false;
      }

      if (!entitySalesrepName?.current?.getValue()) {
        // 'Enter the [Name] of the [LG Sales Person Contact Information]'
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00096", ["Name", "LG Sales Person Contact Information"]), () => { });
        return false;
      }

      if (!entitySalesrepEmail?.current?.getValue()) {
        // 'Enter the [E-Mail] of the [LG Sales Person Contact Information]
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00096", ["Name", "LG Sales Person Contact Information"]), () => entitySalesrepEmail.current.setFocus());
        return false;
      }

      let salesrepEmail = entitySalesrepEmail?.current?.getValue() ?? "";
      if (salesrepEmail.indexOf("@lge.com") > 0 || salesrepEmail.indexOf("@lgepartner.com") > 0 || salesrepEmail.indexOf("@zenith.com") > 0) {
        if (!GsrUtils.checkEmailAddress(salesrepEmail)) {
          //'Please enter the LG Salesperson email address correctly.'); // GSR_ERR_00098
          ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00098"), () => entitySalesrepEmail.current.setFocus());
          return false;
        }
      } else {
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00098"), () => entitySalesrepEmail.current.setFocus());
        return false;
      }

      if (!entityDebitMemoNo?.current?.getValue()) {
        //Enter the Invoice/DN
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00100", ["Debit Memo"]), () => entityDebitMemoNo.current.setFocus());
        return false;
      }

      if (entityDebitMemoAmt?.current?.getValue() == null) {
        //Enter the Request Amount
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00100", ["Request Amount"]), () => entityDebitMemoAmt.current.setFocus());
        return false;
      }

      if (entityDebitMemoAmt?.current?.getValue() <= 0) {
        // Request Amount must be greater than zero.'); // GSR_ERR_00099
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00099"), () => entityDebitMemoAmt.current.setFocus());
        return false;
      }

      if ('1' === claimType || '2' === claimType || '14' === claimType || '3' === claimType || '13' === claimType || '4' === claimType) {
        let moreClaims = entityMoreClaims.current?.getValue();

        if (moreClaims == "Y" || moreClaims == "N") {
        } else {
          ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, msgOthErr012);
          return false;
        }
      }

      if (!entityCustomerClaimDesc?.current?.getValue()) {
        // Enter the Customer Claim Desc
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00100", ["Customer Claim Desc"]), () => entityCustomerClaimDesc.current.setFocus());
        return false;
      }

      if ((claimAnalystYn ?? "") === "Y") {
        if (!entityLgReviewComment?.current?.getValue()) {
          // Enter the LG Review Comment.
          ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00100", ["LG Review Comment"]), () => entityLgReviewComment.current?.setFocus());
          return false;
        }
      }

      let fileCount = fileList?.filter(f => f?.isDeleted !== true)?.length;
      if (fileCount === 0) {
        // Enter the attached file.
        ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00100", ["attached file"]));
        return false;
      }

      let rows = gridObject.current.rows ?? [];
      if (rows.length < 1) {
        // Please enter one or more row
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00038"), () => { });
        return false;
      }

      // check grid
      let requestAmtTotal = 0;
      let priceProtectionDate = rows[0].dataItem.priceProtectionDate;
      let lgPgmNo = rows[0].dataItem.lgPgmNo;
      let noPgmCnt = 0;
      let fromPgmCnt = 0;
      let groupCodeFlag = "N";

      for (let i = 0; i < rows.length; i++) {
        let dataItem = rows[i].dataItem;

        if (dataItem.requestAmt) {
          requestAmtTotal += Number(dataItem.requestAmt.toFixed(2));
        }

        if (userType == 'E') {
          //check if request qty is greater than bid allowed qty
          if ('3' == claimType && divisionCode == "B2B") {
            if (!dataItem.quotationNo) {
              ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00013", [(i + 1), 'Quotation No'])
                , () => ComUtils.setGridFocus(gridObject, i, "quotationNo"));
              return false;
            }
          }

          //check if request qty is greater than net allowed qty
          if (('2' == claimType && divisionCode == "MC") || ('3' == claimType && divisionCode == "B2B")) {
            if (dataItem.requestQty > 0 && dataItem.netAllowedQty < dataItem.requestQty) {
              // if (!confirm((i + 1) + 'row : Net Claim Allowed Quantity is not enough.\nAre you sure you want to proceed?')) {
              //   return false;
              // }
            }
          }
        }

        if (claimAnalystYn === "Y") {
          if (initData.input?.rejectYn === "Y" && Number(rows[i].requestAmt) == 0) {
            if (!rows[i].rejectCode) {
              ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00013", [(i + 1), 'Rejection Reason']));
              return false;
            }
            if (!rows[i].rejectDesc) {
              ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00013", [(i + 1), 'Rejection Comment']));
              return false;
            }
          }

          if (dataItem.lgChargebackNo && !dataItem.lgChargebackAmt) {
            // 'row : The LG Chargeback Amt is required for the CB No.')
            ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00106", [(i + 1)])
              , () => ComUtils.setGridFocus(gridObject, i, "lgChargebackAmt"));
            return false;
          }

          if ('1' === claimType || '2' === claimType || '14' === claimType || '3' === claimType || '13' === claimType || '4' === claimType) {
            if ('1' === claimType || '2' === claimType || '14' === claimType || '3' === claimType || '13' === claimType) {
              groupCodeFlag = entityGroupCodeFlag.current?.getValue();
            }

            if ((claimStatus.current === "AR" && groupCodeFlag !== "Y") || claimStatus.current === "A3") {//Approval Request
              if (dataItem.disputeFlag == 'S' || dataItem.disputeFlag == 'P') {
                noPgmCnt = noPgmCnt + 1;
              } else {
                if (dataItem.requestAmt > 0) {
                  if (!dataItem.lgPgmNo) {
                    ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00013", [(i + 1), 'LG Program#'])
                      , () => ComUtils.setGridFocus(gridObject, i, "lgPgmNo"));
                    return false;
                  }

                  if (!dataItem.lgPgmName) {
                    ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00013", [(i + 1), 'LG Program Name'])
                      , () => ComUtils.setGridFocus(gridObject, i, "lgPgmName"));
                    return false;
                  }
                  if (!dataItem.pgmReasonCode) {
                    ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00013", [(i + 1), 'PGM Reason Code'])
                      , () => ComUtils.setGridFocus(gridObject, i, "lgPgmName"));
                    return false;
                  }
                }
              }
            }

            if (dataItem.pgmReasonCode) {
              if (i == 0) {
                entityReasonCode.current?.setValue(dataItem.pgmReasonCode);
              } else {//if claim has multi pgm then set header's reason code with 'PGM'
                if (dataItem.pgmReasonCode != entityReasonCode?.current?.getValue()) {
                  entityReasonCode.current?.setValue("PGM");
                }
              }
            }
          }

          //Accrual Transfer 2/5/2020
          if ('1' === claimType || '2' === claimType || '14' === claimType || '3' === claimType || '13' === claimType) {
            if ((claimStatus.current === "AR" && groupCodeFlag === "Y") || claimStatus.current === "AF") {
              if ('2' == claimType || '14' === claimType || '3' === claimType || '13' === claimType) {
                if (!dataItem.requestQty || dataItem.requestQty < 0) {
                  ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00013", [(i + 1), 'Request Qty'])
                    , () => ComUtils.setGridFocus(gridObject, i, "requestQty"));
                  return false;
                }
              }

              if (dataItem.orgPgmNo && !dataItem.orgBillTo) {
                ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00013", [(i + 1), 'Transfer From Bill To'])
                  , () => ComUtils.setGridFocus(gridObject, i, "orgBillTo"));
                return false;
              }

              if (!dataItem.orgPgmNo && !dataItem.orgBillTo) {
                ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00013", [(i + 1), 'Transfer From Program No'])
                  , () => ComUtils.setGridFocus(gridObject, i, "orgPgmNo"));
                return false;
              }

              if (dataItem.orgPgmNo && dataItem.orgBillTo) {
                fromPgmCnt = fromPgmCnt + 1;
              }
            }
          }
        }

        let activitySalesClaimDate = dataItem.activitySalesClaimDate;//C20200124_66704
        if (('1' === claimType || '2' === claimType || '14' === claimType) && !activitySalesClaimDate) {
          ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00013", [(i + 1), 'Activity Date']), () => { });
          return false;
        }

        if ('4' === claimType) {// Price Protection C20190221_30027
          if (priceProtectionDate != dataItem.priceProtectionDate) {
            ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00014", [(i + 1), 'Price Protection Date']), () => { });
            return false;
          }
          if (lgPgmNo != dataItem.lgPgmNo) {
            ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00014", [(i + 1), 'LG Program No.']), () => { });
            return false;
          }

          if (dataItem?.invoiceDate && dataItem?.invoiceDateTo) {
            let invoiceDate = ComUtils.DateToInteger(dataItem.invoiceDate); // from
            let invoiceDateTo = ComUtils.DateToInteger(dataItem.invoiceDateTo);

            if (invoiceDate > invoiceDateTo) {
              ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00015", [(i + 1)]), () => ComUtils.setGridFocus(gridObject, i, "invoiceDate"));
              return false;
            }
          }
        }

        if ('7' === claimType) {// Tax C20160317_12461
          if (dataItem.requestAmt != dataItem.invoiceTax) {
            ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00016", [(i + 1)]), () => { });
            return false;
          }
        }

        if ('9' === claimType) {
          if ('N' !== dataItem.serialChk) {
            ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00017", [(i + 1), 'Serial number']), () => { });
            return false;
          }

          if (!dataItem.damageType) {
            ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00018", [(i + 1), 'Approved(%,Amt.)']), () => { });
            return false;
          }

          if (!dataItem.damageValue) {
            ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00019", [(i + 1), 'Approved(%,Amt.)']), () => { });
            return false;
          }

          if (!dataItem.approvedBy) {
            ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00019", [(i + 1), 'Approver']), () => { });
            return false;
          }
        }

        if ("11" === claimType) {
          if ('N' !== dataItem.serialChk) {
            //'row : Reference number is already processed.'
            ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00017", [(i + 1), 'Reference number']), () => { });
            return false;
          }
        }
      }


      if (claimStatus.current === "AR" || claimStatus.current === "A3") {//Approval Request
        if (!entityReasonCode?.current?.getValue() || entityReasonCode?.current?.getValue() === selCombo[0]?.nm) {
          // Please select the reason Code.
          ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00097", ["reason Code"]), () => { });
          return false;
        }

        if (!entityClaimAnalyst.current.getValue()) {
          // Please select the reason Code.
          ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00097", ["Claim Analyst"]), () => entityClaimAnalyst.current.setFocus());
          return false;
        }

        if (noPgmCnt === rows.length) {
          ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00020", ["'Dispute Flag'. \nThere's no available fund"]), () => { });
          return false;
        }

        //check if validation result is uploaded
        if ('3' === claimType && divisionCode === "B2B" && !systemEntryNoUpload.current) {
          // if (!confirm("There is no validation result uploaded.\nAre you sure you want to proceed?")) {
          //   return false;
          // }
        }
      }

      if ((claimStatus.current === "AR" && groupCodeFlag === "Y") || claimStatus.current === "AF") {
        if (fromPgmCnt == 0) {
          ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00006", ["Transfer From Program No. \nThere's no program to be transferred"]), () => { });
          return false;
        }
      }

      let debitMemoAmt = entityDebitMemoAmt?.current?.getValue();
      if (Number(debitMemoAmt.toFixed(2)) != Number(requestAmtTotal.toFixed(2))) {
        // Request Amt Total] value must be equal to [Request Amount].');
        // GSR_ERR_00112
        ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00112", ["Request Amt Total", "Request Amount"]), () => { });
        return false;
      }
    }

    return true;
  };

  const fncModelBidQty = async () => {
    let currentItem = gridObject?.current?.collectionView?.currentItem;

    let claimType = entityClaimType.current.getValue();
    let billToCode = entityBillTo.current.getCode();
    let divisionCode = entityDivision.current?.getValue() ?? "";

    let quotationNo = currentItem.quotationNo;
    let modelCode = currentItem.modelCode;

    if (userType === "E" && quotationNo != "" && quotationNo != "No Quote" && modelCode != "") {
      let resultData = await ApiManager.post(retrieveModelQty, { claimType, divisionCode, billToCode, modelCode, systemEntryNo: systemEntryNo.current, quotationNo });

      if (resultData?.length ?? 0 > 0) {
        currentItem.goodsInsSeq = resultData[0].goodsInsSeq || 0;
        currentItem.bidAprvQty = resultData[0].bidAprvQty || 0;

        let netClaimQty = '';
        if (resultData[0].bidClaimAprvQty > 0) {
          netClaimQty = resultData[0].bidClaimAprvQty + ' APRV';
        }
        if (netClaimQty != '') {
          netClaimQty = netClaimQty + " ";
        }
        if (resultData[0].bidClaimHoldQty > 0) {
          netClaimQty = netClaimQty + resultData[0].bidClaimHoldQty + ' HOLD';
        }
        currentItem.bidClaimQty = netClaimQty;

        currentItem.bidClaimHoldQty = resultData[0].bidClaimHoldQty || 0;
        currentItem.bidClaimAprvQty = resultData[0].bidClaimAprvQty || 0;
        currentItem.bidAllowedQty = resultData[0].bidAllowedQty || 0;

        gridObject?.current?.collectionView.refresh();
      }
    }
  }

  const fncChargebackReturnMaster = (selectedItems) => {
    let cbInvoiceNo = selectedItems.cbInvoiceNo;
    let debitMemoNo = selectedItems.referenceNo;

    entityLgChargebackNo.current.setValue(cbInvoiceNo);
    // if (gridObject?.current) {
    //   let items = gridObject?.current?.collectionView?.currentItem;

    //   if (items) {
    //     if (!items?.lgChargebackNo) {
    //       items.lgChargebackNo = cbInvoiceNo;

    //       let requestAmt = items.requestAmt || 0;
    //       let chargebackAmount = parseFloat(selectedItems.chargebackAmount.replaceAll(',', ''));
    //       let newChargebackAmount = 0.00;

    //       if (chargebackAmount >= requestAmt) {
    //         newChargebackAmount = requestAmt;
    //       } else {
    //         newChargebackAmount = chargebackAmount;
    //       }

    //       items.lgChargebackAmt = newChargebackAmount;
    //       gridObject?.current?.collectionView.refresh();
    //     }
    //   }
    // }

    if (debitMemoNo !== "") {
      entityDebitMemoNo.current.setValue(debitMemoNo);
      entityLgChargebackNo.current.setFocus();
    }
  };

  const fncChargebackReturnDetail = (selectedItems) => {
    let cbInvoiceNo = selectedItems.cbInvoiceNo;

    if (gridObject?.current) {
      let items = gridObject?.current?.collectionView?.currentItem;
      if (items) {
        let requestAmt = items.requestAmt || 0;
        let chargebackAmount = parseFloat(selectedItems.chargebackAmount.replaceAll(',', ''));
        let newChargebackAmount = 0.00;

        if (chargebackAmount >= requestAmt) {
          newChargebackAmount = requestAmt;
        } else {
          newChargebackAmount = chargebackAmount;
        }
        items.lgChargebackNo = cbInvoiceNo;
        items.lgChargebackAmt = newChargebackAmount;
        gridObject?.current?.collectionView.refresh();
      }
    }
  };

  const fncChargebackChange = async (sender, e) => {
    if (checkBill()) {
      return;
    }
    let requestAmt = sender.rows[e.row].dataItem.requestAmt ?? "";
    if (requestAmt === "") {
      //"Please enter the Request Amt.");
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "COM_ERR_00002", ["Request Amt"]));
      return;
    }

    let chargebackNo = sender.rows[e.row].dataItem.lgChargebackNo ?? "";
    if (chargebackNo === "") {
      // fncClearLgChargebackNoName
      sender.rows[e.row].dataItem.lgChargebackNo = "";
      sender.rows[e.row].dataItem.lgChargebackAmt = null;

      sender.refresh();
      return;
    }

    let debitMemoNo = entityDebitMemoNo.current.getValue();
    let reasonCodes = entityReasonCode?.current?.getValue() === selCombo[0]?.nm ? "" : (entityReasonCode?.current?.getValue() || "");

    let param = {
      chargebackNo: chargebackNo
      , debitMemoNo: ""
      , reasonCodes: reasonCodes
      , referenceNo: debitMemoNo
      , jobFlag: "Validation"
    };
    let resultData = await ApiManager.post(retrieveChargebackList, param);

    if (resultData?.list?.length ?? 0 > 0) {
      let chargebackAmount = resultData.list[0].chargebackAmount;
      chargebackAmount = parseFloat(chargebackAmount.replaceAll(",", ""));
      let newChargebackAmount = 0.00;

      if (chargebackAmount >= requestAmt) {
        newChargebackAmount = requestAmt;
      } else {
        newChargebackAmount = chargebackAmount;
      }
      sender.rows[e.row].dataItem.lgChargebackAmt = newChargebackAmount;


    } else {
      // "Invalid Chargeback#.");
      ComNoticfication.ShowAlertUICallback(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00114", ["Chargeback#"]),
        () => {
        });
      sender.rows[e.row].dataItem.lgChargebackNo = "";
      sender.rows[e.row].dataItem.lgChargebackAmt = null;

    }
    sender.refresh();
  };

  const fncModelCodeChange = async (s, e) => {
    if (checkBill())
      return;

    let claimType = entityClaimType.current.getValue();
    let sender = s;

    let dataItem = s?.rows[e.row]?.dataItem;
    let billToCode = entityBillTo?.current?.getCode() ?? "";
    let invoiceNo = "";
    let modelCode = dataItem.modelCode;
    if (modelCode === "")
      return;

    if ("5" === claimType) {
      //all check
      invoiceNo = dataItem.invoiceNo ?? "";
      if (invoiceNo === "") {
        //'Please enter the LG Invoice# first.');
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "COM_ERR_00002", ["LG Invoice# first"]));
        s.rows[e.row].dataItem.modelCode = "";
        sender.refresh();
        return;
      }
    }

    if ("5" === claimType || "6" === claimType || "8" === claimType) {
      invoiceNo = dataItem.invoiceNo ?? "";
    }

    let resultData = await ApiManager.post(retrieveInputCheckUrl, {
      claimType: claimType,
      invoiceNo: invoiceNo,
      billToCode: billToCode,
      modelCode: modelCode ?? "",
      claimAnalystYn: claimAnalystYn ?? "",
      claimStatus: initData?.claimStatusCode?.claimStatusCode,
      systemEntryNo: systemEntryNo.current,
      errorYn: (initData?.input?.gerpErrorCount && initData?.input?.gerpErrorCount > 0) ? "Y" : ""
    });

    if (resultData?.list?.length ?? 0 > 0) {
      if (claimAnalystYn === "Y") {
        if (resultData?.list?.length === 1) {
          fncModelReturn(e.row, resultData?.list[0]);
        } else {
          openModelCodePopup({ rowIndex: e.row, dataItem }, null);
        }
      } else {
        if (claimType === "5") {
          fncPriceInvoiceReturn(e.row, resultData?.list[0]);
          fncModelReturn(e.row, resultData?.list[0]);
        } else {
          fncModelReturn(e.row, resultData?.list[0]);
        }
      }
    } else {
      openModelCodePopup({ rowIndex: e.row, dataItem }, null);
      s.rows[e.row].dataItem.modelCode = "";
      sender.refresh();
    }
  };

  const fncModelReturn = async (idx, obj) => {
    let view = gridObject?.current?.collectionView;
    if (!view) {
      return;
    }
    view.items[idx].modelCode = obj.modelCode;

    let claimType = entityClaimType.current.getValue();
    let billToCode = entityBillTo.current.getCode();

    if (userType === "E") {
      let divisionCode = entityDivision.current?.getValue() ?? "";

      if ((claimType == '2' && divisionCode == 'MC') || (claimType == '3' && divisionCode == 'B2B')) {
        let resultData = await ApiManager.post(retrieveModelQty, {
          claimType: claimType,
          divisionCode: divisionCode,
          billToCode: billToCode,
          modelCode: obj.modelCode,
          systemEntryNo: systemEntryNo.current
        });

        if (resultData?.length ?? 0 > 0) {
          view.items[idx].netSellInQty = resultData[0].netSellInQty;

          let netClaimQty = '';
          if (resultData[0].netIrAprvQty > 0) {
            netClaimQty = resultData[0].netIrAprvQty + ' APRV';
          }
          if (netClaimQty != '') {
            netClaimQty = netClaimQty + " ";
          }
          if (resultData[0].netIrHoldQty > 0) {
            netClaimQty = resultData[0].netIrHoldQty + ' HOLD';
          }

          view.items[idx].netIrClaimQty = netClaimQty;
          view.items[idx].netIrHoldQty = resultData[0].netIrHoldQty || 0;
          view.items[idx].netIrAprvQty = resultData[0].netIrAprvQty || 0;

          netClaimQty = '';
          if (resultData[0].netOtherAprvQty > 0) {
            netClaimQty = resultData[0].netOtherAprvQty + ' APRV';
          }
          if (netClaimQty != '') {
            netClaimQty = netClaimQty + " ";
          }
          if (resultData[0].netOtherHoldQty > 0) {
            netClaimQty = resultData[0].netOtherHoldQty + ' HOLD';
          }

          view.items[idx].netOtherClaimQty = netClaimQty;
          view.items[idx].netOtherHoldQty = resultData[0].netOtherHoldQty || 0;
          view.items[idx].netOtherAprvQty = resultData[0].netOtherAprvQty || 0;
          view.items[idx].netAllowedQty = resultData[0].netAllowedQty || 0;

          view.refresh();
        }

        //for Markdown/Meetcomp for B2B division
        if (claimType == '3' && divisionCode == 'B2B') {
          fncModelBidQty();
        }
      }
    }

    view.refresh();
  }

  const fncProgramModelCodeChange = async (s, e) => {
    if (initData?.input?.gerpErrorCount && initData?.input?.gerpErrorCount > 0) {
      fncModelCodeChange(s, e);
      return;
    }

    if (checkBill()) return;

    let claimType = entityClaimType?.current?.getValue() ?? "";
    let billToCode = entityBillTo?.current?.getCode() ?? "";
    let dataItem = s?.rows[e.row]?.dataItem;

    let priceProtectionDate = wijmo.Globalize.format(dataItem.priceProtectionDate, "MM/dd/yyyy");
    let modelCode = dataItem.modelCode;
    if (modelCode == "") return;

    if (!priceProtectionDate) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, ComUtils.getMessage(messageObject, "OTH_ERR_00006", ["the LG Price Protection Date first"]));
      s.rows[e.row].dataItem.modelCode = '';
      s.refresh();
      return;
    }

    let resultData = await ApiManager.post(retrieveInputCheckUrl, { claimType, priceProtectionDate, billToCode, modelCode });

    if (resultData?.list?.length ?? 0 > 0) {
      fncProgramModelReturn(resultData?.list);
    } else {
      openLgProgramModelPopup({ rowIndex: e.row, dataItem }, null);
    }
  }

  const fncProgramModelReturn = (list) => {
    let view = gridObject?.current?.collectionView;
    if (!view) {
      return;
    }

    let divisionCode = entityDivision.current?.getValue() ?? "";
    let oldInvoicePrice = 0;
    let newInvoicePrice = 0;
    let oldInvoiceFlag = '';
    let newInvoiceFlag = '';

    list?.forEach((row, index) => {
      oldInvoicePrice = row.oldPrice;
      newInvoicePrice = row.newPrice;
      oldInvoiceFlag = (oldInvoicePrice == 0) ? '' : 'popUp';
      newInvoiceFlag = (newInvoicePrice == 0) ? '' : 'popUp';

      let newRow = { ...claimRef.current.getData()[0] };

      newRow.priceProtectionDate = row.priceMoveDate;
      newRow.modelCode = row.modelCode;
      newRow.requestQty = 0;
      newRow.requestPrice = row.differencePerUnit;
      newRow.requestAmt = 0;
      newRow.lgChargebackNo = entityLgChargebackNo.current.getValue() || "";
      newRow.lgChargebackAmt = 0;
      newRow.priceDropInvDate = row.priceMoveDate;
      newRow.oldInvoicePrice = oldInvoicePrice;
      newRow.newInvoicePrice = newInvoicePrice;
      newRow.oldInvoiceFlag = oldInvoiceFlag;
      newRow.newInvoiceFlag = newInvoiceFlag;
      newRow.lgPgmNo = '';
      newRow.lgPgmName = '';
      newRow.invoiceDate = getInvoiceFromDate(divisionCode, row.priceMoveDate);
      newRow.invoiceDateTo = fromDateAddDay(row.priceMoveDate, -1);
      newRow.orgRequestQty = 0;
      newRow.orgRequestPrice = 0;
      newRow.orgRequestAmt = 0;
      newRow.attribute25 = index === 0 ? row.attribute25 : '';


      if (index == 0) {
        // 선택된 row는 list의 0으로 채워주고 list의 나머지는 새로 추가
        for (let key in newRow) {
          if (newRow.hasOwnProperty(key)) {
            gridObject.current.collectionView.currentItem[key] = newRow[key];
          }
        }
        view.refresh();
      } else {
        gridObject.current?.editableCollectionView.addNew(newRow, true);
      }
    });
  }


  const fncInvoiceNoChange = async (s, e) => {
    if (checkBill()) { return; }

    let claimType = entityClaimType.current.getValue();
    let billToCode = entityBillTo.current.getCode();
    let invoiceNo = s.rows[e.row].dataItem.invoiceNo ?? "";
    let dataItem = s.rows[e.row].dataItem;
    if (invoiceNo == "")
      return;

    let resultData = await ApiManager.post(retrieveInputCheckUrl, { claimType, invoiceNo, billToCode });
    if (resultData?.list?.length ?? 0 > 0) {
      fncInvoiceReturn(e.row, resultData?.list[0]);
    } else {
      openInvoiceNoPopup({ rowIndex: e.row, dataItem }, null);
    }
  }

  const fncInvoiceReturn = (idx, obj) => {
    let view = gridObject?.current?.collectionView;
    if (!view) {
      return;
    }

    view.items[idx].invoiceNo = obj.invoiceNo
    view.items[idx].modelCode = obj.modelName;
    view.items[idx].invoiceDate = obj.invoiceDate;
    view.items[idx].invoiceFreight = obj.invoiceAmount;

    view.refresh();
  }

  const fncPriceInvoiceNoChange = async (s, e) => {
    if (checkBill()) {
      return;
    }

    let invoiceNo = s.rows[e.row].dataItem.invoiceNo ?? "";
    let claimType = entityClaimType.current.getValue();
    let billToCode = entityBillTo.current.getCode();
    let dataItem = s.rows[e.row].dataItem;

    if (invoiceNo === "") {
      return;
    }

    let resultData = await ApiManager.post(retrieveInputCheckUrl, { claimType, invoiceNo, billToCode });
    if (resultData?.list?.length ?? 0 > 0) {
      fncPriceInvoiceReturn(e.row, resultData.list[0]);
    } else {
      openPriceInvoiceNoPopup({ rowIndex: e.row, dataItem }, null);
    }
  };

  const fncPriceInvoiceReturn = (idx, obj) => {
    let view = gridObject?.current?.collectionView;
    if (!view) {
      return;
    }

    view.items[idx].invoiceNo = obj.invoiceNo;
    view.items[idx].modelCode = obj.modelName;
    view.items[idx].invoiceDate = obj.invoiceDate;
    view.items[idx].invoiceAmt = obj.invoiceAmount;

    view.refresh();
  }

  const fncTaxInvoiceNoChange = async (s, e) => {
    if (checkBill()) { return; }

    let invoiceNo = s.rows[e.row].dataItem.invoiceNo ?? "";
    let claimType = entityClaimType.current.getValue();
    let billToCode = entityBillTo.current.getCode();
    let claimStatus = initData?.claimMstInfo?.claimStatusCode ?? "";
    let dataItem = s.rows[e.row].dataItem;

    if (invoiceNo == "")
      return;

    let resultData = await ApiManager.post(retrieveInputCheckUrl, { claimType, invoiceNo, billToCode, claimStatus, systemEntryNo: systemEntryNo.current });
    if (resultData?.list?.length ?? 0 > 0) {
      fncTaxInvoiceReturn(e.row, resultData.list[0]);
    } else {
      openTaxInvoiceNoPopup({ rowIndex: e.row, dataItem }, null);
    }
  }

  const fncTaxInvoiceReturn = (idx, obj) => {
    let view = gridObject?.current?.collectionView;
    if (!view) {
      return;
    }

    view.items[idx].invoiceNo = obj.invoiceNo;
    view.items[idx].invoiceDate = obj.invoiceDate;
    view.items[idx].invoiceTax = obj.totalTaxAmount;

    view.refresh();
  }

  const handleBillToInfo = async (selectedItems) => {
    entityBillTo.current.setValue({ code: selectedItems.billTo, text: selectedItems.billToName });
    entityBillToAddress.current.setValue(selectedItems.addr1 + ' ' + (selectedItems.addr2 ?? '') + ' ' + (selectedItems.addr3 ?? ''));
    entityBillToCity.current.setValue(selectedItems.city);
    entityBillToState.current.setValue(selectedItems.state);
    entityBillToZipCode.current.setValue(selectedItems.zip);

    let sendParam = {
      sBillTo: selectedItems.billTo,
      sShipToCode: '',
      sShipToName: '',
      divisionCode: entityDivision.current?.getValue() ?? "",
    }
    let resultData = await ApiManager.post(retrieveBillToReturn, sendParam, { loadbarOpen: false });

    setPostAuditCustomer(resultData?.claimAnalystInfo?.postAuditCustomer);
    entitySalesrepName.current.setList({ data: resultData?.salepersonList });
    entityClaimAnalyst.current.setValue(resultData?.claimAnalystInfo?.claimAnalyst);
    entityShipTo.current.setValue({ code: resultData?.shipToList[0].shipToCode, text: resultData?.shipToList[0].shipToName });
    claimAnalystEmpNo.current = resultData?.claimAnalystInfo?.empNo;
    ppPeriod.current = resultData?.claimAnalystInfo?.ppPeriod ?? "";

    entityDebitMemoNo.current.setValue("");
    entityLgChargebackNo.current.setValue("");
    entityPayToAddress.current?.setValue("");
    entityVendorName.current.setValue("");
    entityVendorCode.current.setValue("");
    vendorSiteCode.current = "";
    entityPaymentMethod.current.setValue("CREDIT");
    entityOutstandingInvoiceAmt.current.setValue("");

    defaultBillToSearchParam.current.billToCode = selectedItems.billTo;
  }

  function fncClearLgPgmNoName() {
    let currentItem = gridObject.current.collectionView?.currentItem;

    if (currentItem) {
      currentItem.lgPgmNo = "";
      currentItem.lgPgmName = "";
      currentItem.pgmBalance = "";
      currentItem.pgmReasonCode = "";

      gridObject.current.collectionView?.refresh();
    }
  }

  const fncLgProgramChange = async (s, e) => {
    if (checkBill()) { return; }

    let claimType = entityClaimType.current.getValue();
    let billTo = entityBillTo.current.getCode();
    let lgPgmNo = s.rows[e.row].dataItem.lgPgmNo ?? "";
    let modelCode = s.rows[e.row].dataItem.modelCode ?? "";
    let requestAmt = s.rows[e.row].dataItem.requestAmt || 0;

    if (lgPgmNo == "") {
      fncClearLgPgmNoName();
      return;
    }

    if (("4" == claimType) && ("" == modelCode)) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, ComUtils.getMessage(messageObject, "OTH_ERR_00006", ["the Model Code"]));
      return;
    }

    if (!requestAmt) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "COM_ERR_00002", ["Request Amt"]));
      fncClearLgPgmNoName();
      return;
    }

    let resultData = await ApiManager.post(retrieveProgramBalanceGerp, { claimType, billTo, lgPgmNo });
    if (resultData?.length ?? 0 > 0) {
      let vReturn = resultData[0].balanceInfo;

      let res = vReturn.split("|");
      let errCode = res[0];
      let errMsg = res[1];

      let promotionNo = res[2];
      let offerName = res[3];
      let accrualAmt = parseFloat(res[4]) || 0;
      let adjustAmt = parseFloat(res[5]) || 0;
      let paidAmt = parseFloat(res[6]) || 0;
      let processingAmt = parseFloat(res[10]) || 0;
      let balanceAmt = parseFloat(res[7]) || 0;
      let pgmReasonCode = res[9];

      if (errCode == "E") {
        ComNoticfication.ShowAlertUI(ComUtils._altTitleError, errMsg);
      } else if (errCode == "S") {
        if (requestAmt > balanceAmt) {
          ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, ComUtils.getMessage(messageObject, "OTH_WAR_00004", [accrualAmt, paidAmt, processingAmt, balanceAmt]));
        }
      } else {
        ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00020", ["the LG Program#"]));
        fncClearLgPgmNoName();
      }

      if (offerName != '') {
        s.rows[e.row].dataItem.pgmReasonCode = pgmReasonCode;
        s.rows[e.row].dataItem.lgPgmNo = lgPgmNo;
        s.rows[e.row].dataItem.lgPgmName = offerName;
        s.rows[e.row].dataItem.pgmBalance = balanceAmt;

        if ("1" == claimType || "2" == claimType || "14" == claimType || "3" == claimType || "13" == claimType) {
          if (promotionNo != '') {
            s.rows[e.row].dataItem.promotionNo = promotionNo;
          }
        }

        s.refresh();
      } else {
        fncClearLgPgmNoName();
      }
    } else {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00020", ["the LG Program#"]));
      fncClearLgPgmNoName();
    }
  }

  const fromDateAddYear = (date, years) => {
    let result = wijmo.Globalize.parseDate(date, "MM/dd/yyyy");
    result.setFullYear(result.getFullYear() + 1);
    return wijmo.Globalize.format(result, "MM/dd/yyyy");
  };

  const fromDateAddDay = (date, days) => {
    let result = wijmo.Globalize.parseDate(date, "MM/dd/yyyy");
    result.setDate(result.getDate() + days);
    return wijmo.Globalize.format(result, "MM/dd/yyyy");
  };

  const getInvoiceFromDate = (divisionCode, priceProtectionDate) => {
    let invoiceDateFrom = "";
    if (divisionCode == "HA") {
      invoiceDateFrom = fromDateAddDay(priceProtectionDate, -90);
    } else if (divisionCode == "HE") {
      let _ppPeriod = ppPeriod.current;

      if (_ppPeriod == "") {
        invoiceDateFrom = fromDateAddYear(priceProtectionDate, -1);
      } else {
        _ppPeriod = -1 * Number(_ppPeriod);
        invoiceDateFrom = fromDateAddDay(priceProtectionDate, _ppPeriod);
      }
    } else {
      invoiceDateFrom = fromDateAddYear(priceProtectionDate, -1);
    }

    return invoiceDateFrom;
  }

  const resetInvoiceFromTo = () => {
    let divisionCode = "";
    let currentItem = gridObject?.current?.collectionView.currentItem;

    if (currentItem?.priceProtectionDate) {
      if (userType == "E") {
        divisionCode = entityDivision.current?.getValue() ?? "";
      }

      let priceProtectionDate = wijmo.Globalize.format(currentItem?.priceProtectionDate, "MM/dd/yyyy");
      currentItem.invoiceDateTo = fromDateAddDay(priceProtectionDate, -1);
      currentItem.invoiceDate = getInvoiceFromDate(divisionCode, priceProtectionDate);
      gridObject?.current?.collectionView.refresh();
    }
  }

  const resetInvoiceFromToAll = (divisionCode) => {
    let rows = gridObject?.current?.collectionView.items;

    for (let i = 0; i < rows?.length; i++) {
      if (rows[i].priceProtectionDate) {
        let priceProtectionDate = wijmo.Globalize.format(rows[i].priceProtectionDate, "MM/dd/yyyy");
        rows[i].invoiceDate = getInvoiceFromDate(divisionCode, priceProtectionDate);
        rows[i].invoiceDateTo = fromDateAddDay(priceProtectionDate, -1);
      }
    }

    gridObject?.current?.collectionView.refresh();
  }

  //billTo팝업
  const openBillToPopup = (elementId, userCode, resultData) => {
    //조회결과 존재
    if (resultData?.list) {
      if (resultData.list.length == 1) {
        //단건이면 세팅 (팝업 오픈 X)
        handleBillToInfo(resultData.list[0]);
      } else {
        //여러건이면 팝업으로 전달
        billToResData.current = resultData;

        randomKey.current = ComUtils.setRefRandomKey();
        setOpenModalBillTo(true);
      }
    } else {
      randomKey.current = ComUtils.setRefRandomKey();
      setOpenModalBillTo(true);
    }
  };

  const callBillToPopup = (elementId, value) => {
    defaultBillToSearchParam.current.billToCode = value.toUpperCase();
    entityBillTo.current.setValue({ code: value.toUpperCase() });
    entityShipTo.current.setValue({ code: "" });
  };

  const closeBillToPopup = async (selectedItems) => {
    if (selectedItems !== undefined && selectedItems != null) {
      handleBillToInfo(selectedItems);
    }
    billToResData.current = null;
    setOpenModalBillTo(false);
  };

  //ShipTo 팝업
  const openShipToPopup = (elementId, userCode, resultData) => {
    //조회결과 존재
    if (resultData?.list) {
      if (resultData.list.length == 1) {
        //단건이면 세팅 (팝업 오픈 X)
        entityShipTo.current.setValue({ code: resultData.list[0].shipToCode, text: resultData.list[0].shipToName });
      } else {
        //여러건이면 팝업으로 전달
        shipToResData.current = resultData;

        randomKey.current = ComUtils.setRefRandomKey();
        setOpenModalShipTo(true);
      }
    } else {

      randomKey.current = ComUtils.setRefRandomKey();
      setOpenModalShipTo(true);
    }
  };

  const callShipToPopup = (elementId, value) => {
    defaultShipToParam.current.sBillTo = entityBillTo.current.getCode();
    defaultShipToParam.current.sShipToCode = value.toUpperCase();
    entityShipTo.current.setValue({ code: value.toUpperCase() });
  };
  const closeShipToPopup = (selectedItems) => {
    if (selectedItems !== undefined && selectedItems != null) {
      entityShipTo.current.setValue({ code: selectedItems.shipToCode, text: selectedItems.shipToName });
      defaultShipToParam.current.sShipToCode = selectedItems.sShipToCode;
    }
    shipToResData.current = null;
    setOpenModalShipTo(false);
  };
  const handleShipToValid = () => {
    if (!entityBillTo.current?.getCode() || !entityBillTo.current?.getText()) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00008", ["Bill to Code first"]));
      return false;
    }

    return true;
  }

  /* grid popup */
  //charge 팝업
  const openChargePopup = async (row, e) => {
    if (checkBill()) {
      return;
    }

    let requestAmt = row.dataItem.requestAmt ?? "";
    if (requestAmt === "") {
      //"Please enter the Request Amt.");
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "COM_ERR_00002", ["Request Amt"]));
      return;
    }

    defaultChargeSearchParam.current.chargebackNo = row.dataItem.lgChargebackNo ?? '';
    defaultChargeSearchParam.current.billToCode = entityBillTo.current.getCode() ?? '';
    defaultChargeSearchParam.current.referenceNo = entityDebitMemoNo?.current?.getValue() ?? "";;
    defaultChargeSearchParam.current.formYn = "N";

    let resultData;
    if (row.dataItem.lgChargebackNo) {
      resultData = await ApiManager.post(retrieveLGChargebackList, defaultChargeSearchParam.current);
    }

    // 기존값 초기화
    row.dataItem.lgChargebackNo = "";
    row.dataItem.lgChargebackAmt = null;
    gridObject.current?.collectionView?.refresh();

    //조회결과 존재
    if (resultData?.list) {
      if (resultData.list.length == 1) {
        if (defaultChargeSearchParam.current.formYn === "Y") {
          fncChargebackReturnMaster(resultData.list[0]);
        } else {
          fncChargebackReturnDetail(resultData.list[0]);
        }
        chargeResData.current = null;
      } else {
        //여러건이면 팝업으로 전달
        chargeResData.current = resultData;

        randomKey.current = ComUtils.setRefRandomKey();
        setOpenModalCharge(true);
      }
    } else {
      randomKey.current = ComUtils.setRefRandomKey();
      setOpenModalCharge(true);
    }
  };

  const callChargePopup = (elementId, value) => {
    entityLgChargebackNo?.current?.setValue(value.toUpperCase());

    defaultChargeSearchParam.current.billToCode = entityBillTo?.current?.getCode() ?? "";
    defaultChargeSearchParam.current.referenceNo = entityDebitMemoNo?.current?.getValue() ?? "";
    defaultChargeSearchParam.current.chargebackNo = entityLgChargebackNo?.current?.getValue() ?? "";
  }

  const closeModalCharge = (selectedItems) => {
    if (selectedItems !== undefined && selectedItems != null) {
      if (defaultChargeSearchParam.current.formYn === "Y") {
        fncChargebackReturnMaster(selectedItems);
      } else {
        fncChargebackReturnDetail(selectedItems);
      }

      defaultChargeSearchParam.current.chargebackNo = "";
    }
    chargeResData.current = null;
    setOpenModalCharge(false);
  };

  //model 팝업
  const openModelCodePopup = async (row, e) => {
    if (checkBill()) { return; }

    let invoiceNo = "";
    let claimType = entityClaimType.current.getValue();

    if ("5" === claimType) {
      //all check
      invoiceNo = row.dataItem.invoiceNo ?? "";
      if (invoiceNo === "") {
        //'Please enter the LG Invoice# first.');
        ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "COM_ERR_00002", ["LG Invoice# first"]));
        return;
      }
    }

    if ("5" === claimType || "6" === claimType || "8" === claimType) {
      invoiceNo = row.dataItem.invoiceNo ?? "";
    }

    defaultModelSearchParam.current.modelCode = row.dataItem.modelCode ?? "";
    defaultModelSearchParam.current.invoiceNo = invoiceNo;
    if (initData?.input?.gerpErrorCount && initData?.input?.gerpErrorCount > 0) {
      defaultModelSearchParam.current.errorYn = "Y";
    }

    let resultData;
    if (row.dataItem.modelCode) {
      resultData = await ApiManager.post(retrieveModelList, defaultModelSearchParam.current);
    }

    // 기존값 초기화
    row.dataItem.modelCode = "";
    gridObject.current?.collectionView?.refresh();

    //조회결과 존재
    if (resultData?.list) {
      if (resultData.list.length == 1) {
        //단건이면 세팅 (팝업 오픈 X)
        let rowIdx = gridObject.current?.selection?.row;
        fncModelReturn(rowIdx, resultData?.list[0]);
      } else {
        //여러건이면 팝업으로 전달
        modelResData.current = resultData;

        randomKey.current = ComUtils.setRefRandomKey();
        setOpenModalModelCode(true);
      }
    } else {
      randomKey.current = ComUtils.setRefRandomKey();
      setOpenModalModelCode(true);
    }
  };

  const closeModalModelCode = (selectedItems) => {
    if (selectedItems !== undefined && selectedItems != null) {
      let rowIdx = gridObject?.current?.selection.row;
      fncModelReturn(rowIdx, selectedItems);
    }

    defaultModelSearchParam.current.modelCode = "";
    defaultModelSearchParam.current.invoiceNo = "";

    modelResData.current = null;
    setOpenModalModelCode(false);
  };

  //Lg Program Model 팝업
  const openLgProgramModelPopup = async (row, e) => {
    if (initData?.input?.gerpErrorCount && initData?.input?.gerpErrorCount > 0) {
      openModelCodePopup(row, e);
      return;
    }

    if (checkBill()) return;

    let priceProtectionDate = wijmo.Globalize.format(row.dataItem?.priceProtectionDate, "MM/dd/yyyy");

    if (!priceProtectionDate) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, ComUtils.getMessage(messageObject, "OTH_ERR_00006", ["the LG Price Protection Date first"]));
      return;
    }

    defaultLgProgramModelSearchParam.current.modelCode = row.dataItem?.modelCode || "";
    defaultLgProgramModelSearchParam.current.billToCode = entityBillTo.current.getCode();
    defaultLgProgramModelSearchParam.current.priceMoveDate = priceProtectionDate;

    let resultData = await ApiManager.post(retrieveProgramModelList, defaultLgProgramModelSearchParam.current);

    // 기존값 초기화
    row.dataItem.modelCode = '';
    gridObject.current?.collectionView?.refresh();

    //조회결과 존재
    if (resultData?.list) {
      if (resultData.list.length == 1) {
        fncProgramModelReturn(resultData.list);
      } else {
        lgProgramModelResData.current = resultData;
        randomKey.current = ComUtils.setRefRandomKey();
        setOpenModalLgProgramModel(true);
      }
    } else {
      randomKey.current = ComUtils.setRefRandomKey();
      setOpenModalLgProgramModel(true);
    }
  };

  const closeModalLgProgramModel = (selectedItems) => {
    if (selectedItems !== undefined && selectedItems != null) {
      // selectedItems에서 dataItem은 제거후 전달
      let items = selectedItems.map(item => item.dataItem);
      fncProgramModelReturn(items);

      defaultLgProgramModelSearchParam.current.modelCode = selectedItems.modelCode;
    }
    lgProgramModelResData.current = null;
    setOpenModalLgProgramModel(false);
  };

  //Lg Program 팝업
  const openLgProgramPopup = async (row, e) => {
    if (checkBill()) { return; }

    let claimType = entityClaimType.current.getValue();
    let modelCode = row.dataItem?.modelCode || "";
    let requestAmt = row.dataItem?.requestAmt;

    if (claimType > 1 && modelCode.length < 1) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, ComUtils.getMessage(messageObject, "OTH_ERR_00005", ["a model"]))
      return;
    }

    if (!requestAmt) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, ComUtils.getMessage(messageObject, "OTH_ERR_00006", ["the Request Amt"]))
      return;
    }

    defaultLgProgramSearchParam.current.lgPgmNo = row.dataItem?.lgPgmNo || "";
    defaultLgProgramSearchParam.current.paramBillTo = entityBillTo.current.getCode();
    defaultLgProgramSearchParam.current.claimType = claimType;
    defaultLgProgramSearchParam.current.modelCode = row.dataItem?.modelCode;
    defaultLgProgramSearchParam.current.requestAmt = row.dataItem?.requestAmt;

    let resultData;
    if (row.dataItem?.lgPgmNo) {
      resultData = await ApiManager.post(retrieveProgramList, defaultLgProgramSearchParam.current);
    }

    if (resultData?.list) {
      //여러건이면 팝업으로 전달
      lgProgramResData.current = resultData;

      randomKey.current = ComUtils.setRefRandomKey();
      setOpenModalLgProgram(true);
    } else {
      lgProgramResData.current = null;
      randomKey.current = ComUtils.setRefRandomKey();
      setOpenModalLgProgram(true);
    }
  };

  const closeModalLgProgram = (selectedItems) => {
    if (selectedItems !== undefined && selectedItems != null) {
      let claimType = entityClaimType.current.getValue();
      let currentItem = gridObject.current?.collectionView?.currentItem;

      currentItem.lgPgmNo = selectedItems.offerCode;
      currentItem.lgPgmName = selectedItems.offerName;
      currentItem.pgmBalance = selectedItems.pgmBalance;
      currentItem.pgmReasonCode = selectedItems.salesProgramReasonCode;

      if ("1" === claimType || "2" === claimType || "14" === claimType || "3" === claimType || "13" === claimType) {
        if (selectedItems.promotionNo != '') {
          currentItem.promotionNo = selectedItems.promotionNo;
        }
      }
      gridObject.current?.collectionView?.refresh();

      defaultLgProgramSearchParam.current.modelCode = selectedItems.modelCode;
    }
    lgProgramResData.current = null;
    setOpenModalLgProgram(false);
  };

  //Org Program 팝업
  const openOrgProgramPopup = async (row, e) => {
    if (checkBill()) { return; }

    let claimType = entityClaimType.current.getValue();
    let modelCode = row.dataItem?.modelCode || "";
    let divisionCode = entityDivision.current?.getValue() || "";
    let requestAmt = row.dataItem?.requestAmt;

    if (claimType > 1 && !modelCode) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, ComUtils.getMessage(messageObject, "OTH_ERR_00005", ["a model"]));
      return;
    }

    if (!requestAmt || parseFloat(requestAmt) <= 0) {
      ComNoticfication.ShowAlertUI(ComUtils._altTitleWarn, ComUtils.getMessage(messageObject, "OTH_ERR_00006", ["the Request Amt"]));
      return;
    }

    row.dataItem.orgBillTo = "";
    gridObject.current?.collectionView?.refresh();

    defaultLgOrgProgramSearchParam.current.lgPgmNo = row.dataItem?.orgPgmNo || "";
    defaultLgOrgProgramSearchParam.current.paramBillTo = entityBillTo.current.getCode();
    defaultLgOrgProgramSearchParam.current.claimType = claimType;
    defaultLgOrgProgramSearchParam.current.divisionCode = divisionCode;
    defaultLgOrgProgramSearchParam.current.modelCode = modelCode;
    defaultLgOrgProgramSearchParam.current.requestAmt = requestAmt;

    setOpenModalLgOrgProgram(true);
  };

  const closeModalLgOrgProgram = (selectedItems) => {
    if (selectedItems !== undefined && selectedItems != null) {
      // selectedItems에서 dataItem은 제거후 전달
      let items = selectedItems.map(item => item.dataItem);
      fncOrgProgramListReturn(items);

      defaultLgOrgProgramSearchParam.current.modelCode = selectedItems.modelCode;
    }
    lgOrgProgramResData.current = null;
    setOpenModalLgOrgProgram(false);
  };

  //analyList 팝업
  const openAnalystPopup = async (elementId, value/*, resultData*/) => {
    if (checkBill()) { return; }

    defaultAnalystSearchParam.current.claimAnalyst = value ?? "";
    defaultAnalystSearchParam.current.billToCode = entityBillTo.current.getCode() ?? "";
    defaultAnalystSearchParam.current.divisionCode = entityDivision.current?.getValue() ?? "";

    let resultData;
    if (value) {
      resultData = await ApiManager.post(defaultAnalystSearchParam.current.url, defaultAnalystSearchParam.current);
    }

    //조회결과 존재
    if (resultData?.list) {
      if (resultData.list.length == 1) {
        //단건이면 세팅 (팝업 오픈 X)
        entityClaimAnalyst.current.setValue(resultData.list[0].claimAnalyst);
      } else {
        //여러건이면 팝업으로 전달
        analystResData.current = resultData;

        randomKey.current = ComUtils.setRefRandomKey();
        setOpenModalAnalyst(true);
      }
    } else {
      randomKey.current = ComUtils.setRefRandomKey();
      console.log("===" + randomKey);
      setOpenModalAnalyst(true);
    }
  };

  const closeModalAnalyst = (selectedItems) => {
    if (selectedItems !== undefined && selectedItems != null) {
      entityClaimAnalyst?.current?.setValue(selectedItems.claimAnalyst);
      claimAnalystEmpNo.current = selectedItems?.empNo ?? "";
      defaultAnalystSearchParam.current.claimAnalyst = selectedItems.claimAnalyst;
    }

    analystResData.current = null;
    setOpenModalAnalyst(false);
  };

  // Supplier 팝업
  const openSupplierPopup = (elementId, values) => {
    randomKey.current = ComUtils.setRefRandomKey();
    setOpenModalSupplier(true);
  }

  const closeModalSupplier = (selectedData) => {
    if (selectedData !== null) {
      entityVendorName?.current?.setValue(selectedData?.vendorName);
      entityVendorCode?.current?.setValue(selectedData?.vendorCode);
      vendorSiteCode.current = selectedData?.vendorCode;
      entityPayToAddress.current?.setValue(selectedData?.payToAddress);
    }
    setOpenModalSupplier(false);
  }

  //Invoice No 팝업
  const openInvoiceNoPopup = async (row, e) => {
    if (checkBill()) { return; }

    defaultInvoiceNoSearchParam.current.paramBillTo = entityBillTo.current.getCode();
    defaultInvoiceNoSearchParam.current.claimType = entityClaimType.current.getValue() ?? "";
    defaultInvoiceNoSearchParam.current.invoiceNo = row.dataItem?.invoiceNo ?? "";
    defaultInvoiceNoSearchParam.current.endDate = toDay.current;

    let resultData;
    if (row.dataItem?.invoiceNo) {
      resultData = await ApiManager.post(defaultInvoiceNoSearchParam.current.url, defaultInvoiceNoSearchParam.current);
    }

    // 기존값 초기화
    row.dataItem.invoiceNo = "";
    gridObject.current?.collectionView?.refresh();

    //조회결과 존재
    if (resultData?.list) {
      if (resultData.list.length == 1) {
        //단건이면 세팅 (팝업 오픈 X)
        fncInvoiceReturn(gridObject?.current?.selection.row, resultData.list[0]);
      } else {
        //여러건이면 팝업으로 전달
        invoiceNoResData.current = resultData;

        randomKey.current = ComUtils.setRefRandomKey();
        setOpenModalInvoiceNo(true);
      }
    } else {
      randomKey.current = ComUtils.setRefRandomKey();
      setOpenModalInvoiceNo(true);
    }
  };

  const closeModalInvoiceNo = (selectedItems) => {
    if (selectedItems !== undefined && selectedItems != null) {
      fncInvoiceReturn(gridObject?.current?.selection.row, selectedItems);
      defaultInvoiceNoSearchParam.current.invoiceNo = selectedItems.invoiceNo;
    }

    invoiceNoResData.current = null;
    setOpenModalInvoiceNo(false);
  };

  //Invoice Price No 팝업
  const openPriceInvoiceNoPopup = async (row, e) => {
    if (checkBill()) { return; }

    row.dataItem.modelCode = "";
    row.dataItem.invoiceDate = "";
    row.dataItem.invoiceAmt = "";
    gridObject.current?.collectionView.refresh();

    defaultInvoicePriceSearchParam.current.billToCode = entityBillTo?.current?.getCode() ?? "";
    defaultInvoicePriceSearchParam.current.invoiceNo = row.dataItem.invoiceNo ?? "";
    defaultInvoicePriceSearchParam.current.endDate = toDay.current;

    randomKey.current = ComUtils.setRefRandomKey();
    if (row.dataItem.invoiceNo === "") {
      setOpenModalPriceInvoiceNo(true);
    } else {
      // 기존값 초기화
      row.dataItem.invoiceNo = "";
      gridObject.current?.collectionView.refresh();

      // invoice팝업띄우기
      let resultData = await ApiManager.post(defaultInvoicePriceSearchParam.current.url, defaultInvoicePriceSearchParam.current);
      //조회결과 존재
      if (resultData?.list) {
        if (resultData.list.length == 1) {
          //단건이면 세팅 (팝업 오픈 X)
          let view = gridObject.current?.collectionView;
          if (view) {
            let rowIdx = gridObject?.current?.selection.row;
            fncPriceInvoiceReturn(rowIdx, resultData?.list[0]);
          }
        } else {
          //여러건이면 팝업으로 전달
          priceInvoiceResData.current = resultData;
          setOpenModalPriceInvoiceNo(true);
        }
      } else {
        setOpenModalPriceInvoiceNo(true);
      }
    }

    invoiceNoSearchParam.current = row.dataItem.invoiceNo ?? "";
  };

  const closeModalPriceInvoiceNo = (selectedItems) => {
    if (selectedItems) {
      fncPriceInvoiceReturn(gridObject?.current?.selection.row, selectedItems);
    }

    priceInvoiceResData.current = null;
    setOpenModalPriceInvoiceNo(false);
  };

  //Tax No 팝업
  const openTaxInvoiceNoPopup = async (row, e) => {
    if (checkBill()) { return; }

    defaultInvoiceTaxSearchParam.current.paramBillTo = entityBillTo.current?.getCode() ?? "";
    defaultInvoiceTaxSearchParam.current.invoiceNo = row.dataItem.invoiceNo ?? "";

    let resultData;
    if (row.dataItem.invoiceNo) {
      resultData = await ApiManager.post(defaultInvoiceTaxSearchParam.current.url, defaultInvoiceTaxSearchParam.current);
    }

    // 기존값 초기화
    row.dataItem.invoiceNo = "";
    gridObject.current?.collectionView?.refresh();

    //조회결과 존재
    if (resultData?.list) {
      if (resultData.list.length == 1) {
        //단건이면 세팅 (팝업 오픈 X)
        fncTaxInvoiceReturn(gridObject?.current?.selection.row, resultData.list[0]);
      } else {
        //여러건이면 팝업으로 전달
        invoiceTaxResData.current = resultData;

        randomKey.current = ComUtils.setRefRandomKey();
        setOpenModalInvoiceTax(true);
      }
    } else {
      randomKey.current = ComUtils.setRefRandomKey();
      setOpenModalInvoiceTax(true);
    }

  };

  const closeModalTaxInvoice = (selectedItems) => {
    if (selectedItems) {
      fncTaxInvoiceReturn(gridObject?.current?.selection.row, selectedItems);
    }

    invoiceTaxResData.current = null;
    setOpenModalInvoiceTax(false);
  };

  // Invoice Return List 팝업
  const openInvoiceReturnListPopup = async (requstType, invoiceReturnList) => {
    invoiceReturnListParam.current.requstType = requstType;
    invoiceReturnListParam.current.invoiceReturnList = invoiceReturnList;
    setOpenModalInvoiceReturnList(true);
  };

  const closeModalInvoiceReturnList = (requstType) => {
    if (requstType !== undefined && requstType != null) {
      fncCheckDuplicateDebitMemo(requstType);
    }
    setOpenModalInvoiceReturnList(false);
  };

  // Invoice Used List 팝업
  const openInvoiceUsedListPopup = async (requstType, invoiceUsedList) => {
    invoiceUsedListParam.current.requstType = requstType;
    invoiceUsedListParam.current.invoiceUsedList = invoiceUsedList;
    setOpenModalInvoiceUsedList(true);
  };

  const closeModalInvoiceUsedList = (requstType) => {
    if (requstType !== undefined && requstType != null) {
      if (requstType === "Submit") {
        fncSubmit('');
      } else {
        fncConfirmProcess();
      }
    }
    setOpenModalInvoiceUsedList(false);
  };

  const closeESNExcelUploadPopup = (_systemEntryNoUpload) => {
    if (_systemEntryNoUpload) {
      systemEntryNoUpload.current = _systemEntryNoUpload;
    }
    setOpenGSRESNUploadPop(false);
  };

  const handleClaimView = async (selectedSystemEntryNo) => {
    if (selectedSystemEntryNo) {
      let resultData = await ApiManager.post(retrieveOtherClaimInfo, { systemEntryNo: selectedSystemEntryNo, popup: "Y" });
      if (resultData?.statusCode) {
        ComNoticfication.ShowAlertUI(ComUtils._altTitleError, msgOthErr001);
        return;
      }

      invoiceUsedInitParam.current = resultData;
      setOpenModalClaimRequestPop(true);
    }
  };

  // excel upload popup
  const closeExcelUploadPopup = (items, entryNoUpload) => {
    if (entryNoUpload) {
      systemEntryNoUpload.current = entryNoUpload;
    }

    if (claimType == "4")
      resetInvoiceFromToAll(entityDivision.current.getValue());

    if (items?.length > 0) {
      let gridList = items?.map(obj => ({
        ...obj,
        isChecked2: false,
      }));

      setGidData(
        new CollectionView(gridList, {
          trackChanges: true,
          refreshOnEdit: false,
        })
      );
    }

    setOpenModalExcelUpload(false);
  };

  const openExcelUploadPopup = (e) => {
    randomKey.current = ComUtils.setRefRandomKey();
    setOpenModalExcelUpload(true);
  };

  //DiscountChart[S]
  const closeDiscountChart = (item) => {
    setDiscountChart(() => false);
  };

  const showDiscountChart = (e) => {
    randomKey.current = ComUtils.setRefRandomKey();
    setDiscountChart(true);
  };
  //DiscountChart[E]

  const downloadPdf = async (row, e) => {
    let invoiceNo = row?.dataItem?.invoiceNo;

    if (invoiceNo && invoiceNo.length == 10) {
      let url = invoiceNo.substring(0, 2) == "CN" ? retrieveGRPdfCreditMemoUrl : retrieveComInvoPdfUrl;

      let pdfParam = { lgCreditMemoNo: invoiceNo };
      let resultData = await ApiManager.callExcelDowonload(url, pdfParam);
      if (!resultData?.data)
        return;

      let disposition = resultData.headers['content-disposition'];
      let filename = 'sample-pdf.pdf';
      if (disposition && disposition.indexOf('attachment') !== -1) {
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
      if (filename) {
        try {
          FileSaver.saveAs(new Blob([resultData.data], { type: resultData.headers['content-type'] }), decodeURI(filename));
        } catch (ex) {
          console.log(ex);
        }
      }

    }
  }

  const convertUpperCase = (el, value) => {
    if (value && el.current) {
      el.current.setValue(value.toUpperCase());
    }
  };

  const getPageInfo = async () => {
    // 초기화
    setDisableVenderName(initData?.claimMstInfo?.vendorName ? false : true);
    setPostAuditClaim(initData?.claimMstInfo?.postAuditClaim ?? "");
    setClaimAnalystYn(initData?.input?.claimAnalystYn ?? "");
    setRvsClaimNo(initData?.claimMstInfo?.rvsClaimNo ?? "");
    setOrgDebitMemoAmount(initData?.claimMstInfo?.orgDebitMemoAmount);
    setApprovalLineView(initData?.approvalList?.length > 0);
    setApproveCommentView(initData?.input?.selApprovalStatus === "TO-DO");
    setSystemEntryNoView(initData?.claimMstInfo?.systemEntryNo ?? "");
    setUpdateDateView(initData?.claimMstInfo?.updateDate ?? "");
    setClaimStatusView(initData?.claimMstInfo?.claimStatus ?? "");


    initData.input.rejectYn = "N";
    if ("confirm" === initData.input?.prevJob) {
      if ("TO-DO" !== initData.input?.selApprovalStatus
        && (initData?.claimMstInfo?.claimStatusCode === "A2" || initData?.claimMstInfo.claimStatusCode === "A8" || initData?.claimMstInfo.claimStatusCode === "A9")) {
        if ("Y" === initData?.input?.claimAnalystYn) {
          initData.input.rejectYn = "Y";
        }
      }
      if (Number(initData?.input?.gerpErrorCount > 0)) {
        initData.input.rejectYn = "Y";
      }
    }

    toDay.current = initData?.toDay?.serverDate;
    input.current = initData?.input;
    claimMstInfo.current = initData?.claimMstInfo;
    systemEntryNo.current = initData?.claimMstInfo?.systemEntryNo;
    ppPeriod.current = initData?.claimMstInfo?.ppPeriod ?? "";
    orgRequestHidden.current = initData.input?.prevJob === "confirm" ? false : true;
    reasonCodeListRef.current = initData?.reasonCodeList ?? [];
    noClaimBillToListRef.current = initData?.noClaimBillToList ?? [];
    pendingCodeListRef.current = initData?.pendingCodeList?.map(item => {
      return { cd: item.nm, nm: item.locDescription };
    });

    let props = {
      userType,
      pageType: "Mod",
      claimAnalystYn: initData?.input?.claimAnalystYn,
      rejectYn: initData?.input.rejectYn,
      orgRequestHidden: initData.input?.prevJob === "confirm" ? false : true,
      rejectionReasonList: selCombo.concat(initData?.rejectionReasonList),
      // popup
      openChargePopup,
      openModelCodePopup,
      openLgProgramModelPopup,
      openLgProgramPopup,
      openOrgProgramPopup,
      openInvoiceNoPopup,
      openPriceInvoiceNoPopup,
      openTaxInvoiceNoPopup,
      // pdf
      downloadPdf,
      // state
      isDisabledorgPgmNo: initData?.claimMstInfo?.groupCodeFlag !== "Y",
    }

    ClaimClassPool.current = {
      1: new ClaimADV(props),
      2: new ClaimSellIn(props),
      14: new ClaimSellOut(props),
      13: new ClaimMarkdown(props),
      4: new ClaimPriceProtection(props),
      5: new ClaimPriceDifference(props),
      6: new ClaimFreight(props),
      3: new ClaimMeetcomp(props),
      8: new ClaimRecycling(props),
      9: new ClaimDamageAllow(props),
      7: new ClaimTax(props),
      11: new ClaimVLPriceDiff(props),
      12: new ClaimIrSpiff(props),
    };

    if (initData?.claimMstInfo?.rvsClaimType !== "IR" && initData?.claimMstInfo?.rvsClaimType !== "SPIFF") {
      setListClaimType(prevItems =>
        prevItems.filter(item => item.Value !== '12')
      );
    }
    if (userType === "B") {
      setListClaimType(prevItems =>
        prevItems.map(item => item.Value === '11' ? { ...item, Disable: true } : item)
      );
    }

    entitySalesrepName.current.setColumnConfig([
      { binding: "cd", header: "Code", width: 120, textAlign: "center" },
      { binding: "nm", header: "Name", width: 200 },
      { binding: "emailId", header: "E-mail", width: 200 },
    ]);

    entityReasonCode.current?.setColumnConfig([
      { binding: "nm", header: "Reason Code", width: 140, textAlign: "center" },
      { binding: "locDescription", header: "Reason Code Description", width: "*" },
    ]);

    entityPostAuditClaim.current?.setValue(initData?.claimMstInfo?.postAuditClaim);
    entityCustEntry.current?.setValue(initData?.claimMstInfo?.custEntry === "Y" ? "Y" : "N");
    entityReverse.current?.setValue(initData?.claimMstInfo?.reverseFlag);
    entityDivision.current?.setValue(initData?.claimMstInfo?.divisionCode);
    entityBillTo.current?.setValue({ code: initData?.claimMstInfo?.billToCode, text: initData?.claimMstInfo?.billToName });
    entityParamBillTo?.current?.setValue(initData?.claimMstInfo?.billToCode);
    entityParamBillToName?.current?.setValue(initData?.claimMstInfo?.billToName);
    entityBillToAddress.current?.setValue(initData?.claimMstInfo?.billToAddress);
    entityBillToCity.current?.setValue(initData?.claimMstInfo?.billToCity);
    entityBillToState.current?.setValue(initData?.claimMstInfo?.billToState);
    entityBillToZipCode.current?.setValue(initData?.claimMstInfo?.billToZipCode);
    entityBillToContactName.current?.setValue(initData?.claimMstInfo?.billToContactName);
    entityBillToContactTel.current?.setValue(initData?.claimMstInfo?.billToContactTel?.replace(/\D/g, '')?.slice(-10));
    entityBillToContactEmail.current?.setValue(initData?.claimMstInfo?.billToContactEmail);
    entityShipTo.current?.setValue({ code: initData?.claimMstInfo?.shipToCode, text: initData?.claimMstInfo?.shipToName });
    entityActivityDateFrom.current?.setValue(initData?.claimMstInfo?.activityDateFrom);
    entityActivityDateTo.current?.setValue(initData?.claimMstInfo?.activityDateTo);
    entitySalesrepName.current.setList({ data: initData?.salepersonList, initValue: initData?.claimMstInfo?.salesrepId });
    entityReasonCode.current?.setList({ data: selCombo.concat(initData?.reasonCodeList), code: "nm", name: "locDescription", initValue: initData?.claimMstInfo?.reasonCode });
    if (!initData?.claimMstInfo?.reasonCode) {
      entityReasonCode.current?.setValue("");
    }
    entityPendingCode.current?.setList({ data: selCombo.concat(pendingCodeListRef.current), initValue: initData?.claimMstInfo?.pendingType ?? "" });
    if (!initData?.claimMstInfo?.pendingType) {
      entityPendingCode.current?.setValue("");
    }
    entityClaimAnalyst.current?.setValue(initData?.claimMstInfo?.claimAnalyst);
    claimAnalystEmpNo.current = initData?.claimMstInfo?.claimAnalystEmpNo;
    entityDebitMemoNo.current?.setValue(initData?.claimMstInfo?.debitMemoNo);
    entityDebitMemoAmt.current?.setValue(initData?.claimMstInfo?.debitMemoAmount ? Number(initData?.claimMstInfo?.debitMemoAmount?.replaceAll(',', '')) : 0);
    entityPaymentMethod.current?.setValue(initData?.claimMstInfo?.paymentMethod);
    entityOutstandingInvoiceAmt.current?.setValue(initData?.claimMstInfo?.outstandingInvoiceAmt);
    entityLgChargebackNo.current?.setValue(initData?.claimMstInfo?.lgChargebackNo);
    entityQuotationNo.current?.setValue(initData?.claimMstInfo?.quotationNo);
    entityMultiQuote.current?.setValue(initData?.claimMstInfo?.multiQuote);
    entityGroupCodeFlag.current?.setValue(initData?.claimMstInfo?.groupCodeFlag === 'Y' ? 'Y' : 'N');
    entityVendorName.current?.setValue(initData?.claimMstInfo?.vendorName);
    entityVendorCode.current?.setValue(initData?.claimMstInfo?.vendorCode);
    entityPayToAddress.current?.setValue(initData?.claimMstInfo?.payToAddress);
    entityTradeClaim.current?.setValue(initData?.claimMstInfo?.tradeClaim == 'Y' ? 'Y' : 'N');
    entityCustomerClaimDesc.current?.setValue(initData?.claimMstInfo?.customerClaimDesc);
    entityLgReviewComment.current?.setValue(initData?.claimMstInfo?.lgReviewComment);
    entityMoreClaims.current?.setValue(initData?.claimMstInfo?.moreClaims);
    entityFollowFlag.current?.setValue(initData?.claimMstInfo?.followUpFlag);
    systemEntryNoUpload.current = initData?.claimMstInfo?.systemEntryNoUpload;

    // entitySalesrepName 이벤트로 리셋 방지
    setTimeout(() => {
      entitySalesrepEmail.current?.setValue(initData?.claimMstInfo?.salesrepEmail);
      entitySalesrepTel.current?.setValue(initData?.claimMstInfo?.salesrepTel?.replaceAll('-', ''));
    }, 200);

    if (initData?.input?.claimAnalystYn === "Y") {
      entityFollowUpDate.current?.setValue(initData?.claimMstInfo?.followUpDate);
    }

    // set claim Type, grid data
    changeClaimType(initData?.claimMstInfo?.claimType, true);
    entityClaimType.current.setValue(initData?.claimMstInfo?.claimType);

    // set disable
    if (userGroup === 'BUILDER') {
      setListClaimType(prevItems =>
        prevItems.map(item => item.Value !== '9' ? { ...item, Disable: true } : item)
      );
    }
    entityPayToAddress?.current?.setDisabled(initData?.claimMstInfo?.vendorName ? false : true);
    entityQuotationNo.current?.setDisabled(initData?.claimMstInfo?.multiQuote === "Y");

    // set buttons
    let selApprovalStatus = initData?.input?.selApprovalStatus ?? "";
    let claimStatusCode = initData?.claimMstInfo?.claimStatusCode ?? "";
    let gerpErrorCount = initData?.input?.gerpErrorCount ?? 0;
    setPrevJobConfirmView(initData.input?.prevJob === "confirm");
    setBtnConfirmRejectView(selApprovalStatus !== "TO-DO" && (claimStatusCode === "A2" || claimStatusCode === "A8" || claimStatusCode === "A9"));
    setBtnTransferView(claimStatusCode === "A3" && initData?.input?.claimAnalystYn === "Y" && "12313".indexOf(initData?.claimMstInfo?.claimType ?? "") > -1 && parseInt(gerpErrorCount) > 0);
    setBtnGerpErrorCountView(parseInt(gerpErrorCount) > 0);

    // attach file
    let attachList = initData?.attachList ?? [];
    const newFileList = attachList.map(f => {
      return {
        fileNo: f?.fileNo ?? "",
        attachNo: f?.attachNo ?? "",
        isDeleted: false,
        isOld: true,
        serverFileName: f?.saveFileName ?? "",
        filePath: f?.filePath ?? "",
        fileSize: "",
        displayFileName: f?.fileName ?? "",
        userId: f?.createId ?? ""
      };
    });
    setFileList(newFileList);

    // set approval line
    initGridFormatApprv();
  }

  const _isMounted = useRef(true);
  useEffect(() => {
    let abortController = new AbortController();

    getPageInfo();


    const handleBeforeUnload = (event) => {
      if (initData?.claimMstInfo?.claimStatusCode === "A1" && vConfirmSubmit.current === 'Y') {
        event.preventDefault();
        event.returnValue = "Entry has not been submitted.\n\nPlease click 'Submit' button to submit this claim.";
        return event.returnValue;
      }
    };

    // window.addEventListener('beforeunload', handleBeforeUnload);

    // ComponentWillUnmount in Class Component
    return () => {
      //window.removeEventListener('beforeunload', handleBeforeUnload);
      if (initData?.claimMstInfo?.claimStatusCode === "A1" && vConfirmSubmit.current === 'Y') {
        alert("Entry has not been submitted.\n\nPlease click 'Submit' button to submit this claim.");
      }

      _isMounted.current = false;
      abortController.abort();
    };
  }, [initData]);

  const [listClaimType, setListClaimType] = useState([
    { Value: "1", Description: "ADV. (MDF, Co-op, Display, VR)", },
    { Value: "2", Description: "Sell-In(TC)" },
    { Value: "14", Description: "Sell-Out(Bundle)" },
    { Value: "13", Description: "Markdown" },
    { Value: "4", Description: "Price Protection", },
    { Value: "5", Description: "Price Difference", },
    { Value: "6", Description: "Freight" },
    { Value: "3", Description: "Meetcomp" },
    { Value: "8", Description: "Recycling" },
    { Value: "9", Description: "Damage Allow." },
    { Value: "7", Description: "Tax" },
    { Value: "11", Description: "VL. Price Diff." },
    { Value: "12", Description: "IR/SPIFF" },
  ]);

  const showLayoutByClaimType = () => {
    switch (claimType) {
      case "2":
        return (
          <>
            <div className="bx-item column-2column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit memo copy & Proof of Perfomance
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
            <div className="bx-item">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Trade Claim
                </label>
              </div>
              <div className="bx-inp combo disabled-color-change">
                <ComRadioBox
                  ref={entityTradeClaim}
                  radioList={[
                    { Value: "Y", Description: "Yes" },
                    { Value: "N", Description: "No" },
                  ]}
                  direction={1}
                  radioGroupName={"TradeClaim"}
                  defaultValue="N"
                />
              </div>
            </div>
          </>
        );
      case "14":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit memo copy & Proof of Perfomance
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "13":
        return (
          <>
            <div className="bx-item column-2column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit memo copy & Customer On-hand inventory report
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
            <div className="bx-item">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Trade Claim
                </label>
              </div>
              <div className="bx-inp combo disabled-color-change">
                <ComRadioBox
                  ref={entityTradeClaim}
                  radioList={[
                    { Value: "Y", Description: "Yes" },
                    { Value: "N", Description: "No" },
                  ]}
                  direction={1}
                  radioGroupName={"TradeClaim"}
                  defaultValue="N"
                />
              </div>
            </div>
          </>
        );
      case "4":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit memo copy & Proof of Customer On-hand inventory report
                  as of price drop date
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "5":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit memo or LG Invoice copy & Customer purchase order copy
                  as of price drop date
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "6":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit memo or LG Invoice copy & Customer purchase order copy
                  as of price drop date
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "3":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit copy & Customer On-hand inventory report
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "8":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit Memo or LG invoice copy & Exemption certificate required
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "9":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit Memo with LG approved document - LG Claim from
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "7":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit Memo with LG approved document - LG Claim from
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "11":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit Memo with LG approved document - LG Claim from
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case "12":
        return (
          <>
            <div className="bx-item column-3column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit Memo with LG approved document - LG Claim from
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="bx-item column-2column">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Required Attachment
                </label>
              </div>
              <div className="bx-inp combo">
                <div>
                  Debit memo copy & Proof of Perfomance
                  <span className="field-required">
                    (Claim reject if no evidence)
                  </span>
                  <p>
                    {
                      "1) MDF/COOP __ Advertisement (Newspaper, Flyers, Online Etc,..) 2) VR __ Sales data for period"
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="bx-item">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Trade Claim
                </label>
              </div>
              <div className="bx-inp combo disabled-color-change">
                <ComRadioBox
                  ref={entityTradeClaim}
                  radioList={[
                    { Value: "Y", Description: "Yes" },
                    { Value: "N", Description: "No" },
                  ]}
                  direction={1}
                  radioGroupName={"TradeClaim"}
                  defaultValue="N"
                />
              </div>
            </div>
          </>
        );
    }
  };

  const handleEnterAction = (callEnterAction) => {
    // EditEnded이 엔터 keydown 보다 먼저 호출되어 setTimeout으로 keydown먼저 처리되도록
    setTimeout(() => {
      if (isEnterKeyEvent.current) {
        isEnterKeyEvent.current = false;
        return;
      }
      callEnterAction();
    }, 0);
  }

  const initGrid = (sender) => {
    gridObject.current = sender;
    ComUtils.gridInit(sender, {
      selectionMode: 6,
      hasShowNumbering: true,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: false,
      canAreaInfo: false,
      keyActionEnter: "None"
    });
    sender.allowSorting = false;


    sender.addEventListener(sender.hostElement, "keydown", (e) => {
      let colIdx = gridObject?.current?.selection?.col ?? "";
      let columnId = sender?.cells?.columns[colIdx]?._binding?._key;
      let row = gridObject?.current?.selection?.row ?? -1;
      let rowData = sender.selectedItems[0];

      if (e.keyCode == Key.Enter) {
        let dataItem = { row: row, dataItem: rowData, ctx: null };
        let claimType = entityClaimType.current.getValue();

        switch (columnId) {
          case "lgChargebackNo":
            openChargePopup(dataItem, e);
            isEnterKeyEvent.current = true;
            break;
          case "modelCode":
            if (claimType === "4") openLgProgramModelPopup(dataItem, e);
            else openModelCodePopup(dataItem, e);

            isEnterKeyEvent.current = true;
            break;
          case "invoiceNo":
            if (claimType === "6" || claimType === "8") openInvoiceNoPopup(dataItem, e);
            else if (claimType === "5") openPriceInvoiceNoPopup(dataItem, e);
            else if (claimType === "7") openTaxInvoiceNoPopup(dataItem, e);
            isEnterKeyEvent.current = true;
            break;
        }
      }
    });


    sender.cellEditEnded.addHandler((s, e) => {
      let binding = e.getColumn().binding;
      let claimType = entityClaimType.current.getValue();
      let dataItem = s.rows[e.row].dataItem;

      if (binding === "lgChargebackNo") {
        handleEnterAction(() => fncChargebackChange(s, e));
      } else if (binding === "modelCode") {
        if (claimType === "4") {
          handleEnterAction(() => fncProgramModelCodeChange(s, e));
        } else {
          handleEnterAction(() => fncModelCodeChange(s, e));
        }
      } else if (binding === "invoiceNo") {
        if (claimType === "6" || claimType === "8") {
          handleEnterAction(() => fncInvoiceNoChange(s, e));
        } else if (claimType === "5") {
          handleEnterAction(() => fncPriceInvoiceNoChange(s, e));
        } else if (claimType === "7") {
          handleEnterAction(() => fncTaxInvoiceNoChange(s, e));
        }
      } else if (binding === "lgPgmNo") {
        fncLgProgramChange(s, e);
      } else if (binding === "serialNo") {
        fncSerialNoChange(s, e);
      } else if (binding == "orgPgmNo") {
        fncOrgProgramChange(s, e);
      } else if (binding === "requestQty" || binding === "requestPrice") {
        let requestQty = dataItem.requestQty || 0;
        let requestPrice = dataItem.requestPrice || 0;
        let requestAmt = requestPrice * requestQty;
        dataItem.requestAmt = requestAmt;
        sender.refresh();
      } else if (binding === "quotationNo") {
        if (!dataItem.quotationNo || "No Quote" == dataItem.quotationNo)
          return;

        dataItem.quotationNo = dataItem.quotationNo.toUpperCase();
        getGridQuotationNo(s, e);
      } else if (binding === "priceProtectionDate") {
        dataItem.requestQty = null;
        dataItem.requestPrice = null;
        dataItem.requestAmt = null;
        dataItem.oldInvoicePrice = null;
        dataItem.newInvoicePrice = null;

        resetInvoiceFromTo(); // 여기서 refresh함
      } else if (binding === "damageType") {
        if (dataItem.damageType === "%") {
          dataItem.damageValue = null;
        } else {
          dataItem.damageValue = dataItem.requestAmt;
        }
        s.refresh();
      } else if (binding === "rejectCode") {
        // initData?.rejectionReasonList 에서 code를 찾아 nm으로 채워준다.
        let rejectReason = initData?.rejectionReasonList?.find(item => item.cd === dataItem.rejectCode);
        dataItem.rejectDesc = rejectReason?.nm;
        s.refresh();
      }

      // Amount 체크
      if (binding === "requestQty" || binding === "requestPrice" || binding === "requestAmt") {
        if (claimType === "5" || claimType === "6" || claimType === "7" || claimType === "8") { // Price Difference, Freight, Tax, Recycling
          let requestQty = s.rows[e.row].dataItem.requestQty || 0;
          let requestPrice = s.rows[e.row].dataItem.requestPrice || 0;
          let requestAmt = claimType === "5" ? (requestPrice * requestQty) : s.rows[e.row].dataItem.requestAmt;
          let reverseFlag = entityReverse.current.getValue();
          let invoiceNo = s.rows[e.row].dataItem.invoiceNo ?? "";

          if (!requestAmt)
            return;
          if (reverseFlag === "Y")
            return;
          if (invoiceNo === "") {
            // Please search for LG Invoice#
            ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00119", ["LG Invoice#"]));
            return;
          }

          if (claimType === "5") { // Price Difference
            let invoiceAmt = s.rows[e.row].dataItem.invoiceAmt || 0;

            if (requestAmt > Number(invoiceAmt)) {
              // Request Amount will not exceed the Invoice Amount');
              ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "GSR_ERR_00120"));

              s.rows[e.row].dataItem[binding] = 0;
              s.rows[e.row].dataItem.requestAmt = 0;
              s.refresh();

              return;
            }
          } else if (claimType === "6") {
            let invoiceFreight = s.rows[e.row].dataItem.invoiceFreight || 0;

            if (requestAmt > Number(invoiceFreight)) {
              ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00021", ["Invoice Freight"]));
              s.rows[e.row].dataItem.requestAmt = 0;
              s.refresh();
              return;
            }
          } else if (claimType === "7") {

          } else if (claimType === "8") {
            let invoiceFreight = s.rows[e.row].dataItem.invoiceFreight || 0;

            if (requestAmt > Number(invoiceFreight)) {
              ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "OTH_ERR_00021", ["Invoice Recycling"]));
              s.rows[e.row].dataItem.requestAmt = 0;
              s.refresh();
              return;
            }
          }
        }
      }

      // Checkbox
      if (binding === "isChecked2") {
        let checkNoneList = sender.collectionView.items.filter(e => e.isChecked2 === false);
        if (checkNoneList.length === sender.collectionView.items.length) {
          displayCheck.current = false;
        }

        let checkAllList = sender.collectionView.items.filter(e => e.isChecked2 === true);
        if (checkAllList.length === sender.collectionView.items.length) {
          displayCheck.current = true;
        } else {
          displayCheck.current = false;
        }
        sender.refresh();
      }
    });

    sender.beginningEdit.addHandler((s, e) => {
      const row = s.rows[e.row];
      const item = row.dataItem;

      // orgPgmNo의 isReadOnly 속성을 동적으로 변경
      if (s.columns[e.col].binding === 'orgPgmNo') {
        if (entityGroupCodeFlag.current?.getValue() !== 'Y') {
          e.cancel = true; // 수정 불가
        }
      }
    });

    sender.formatItem.addHandler((s, e) => {
      if (e.panel === s.columnHeaders && e.getColumn(e.col).binding === "isChecked2") {
        e.cell?.firstChild?.remove();
        let element = null;
        if (displayCheck.current) {
          element = createElement(format(
            `<div class="wj-align-center" tabindex="-1" aria-colindex="1" aria-selected="false" style="left: 0px; top: 0px; width: 43px; height: 15px; z-index: 0;"><label><input type="checkbox" class="wj-column-selector wj-column-selector-group wj-cell-check" tabindex="-1" checked=true></label></div>`, {}), e.cell);
        }
        else {
          element = createElement(format(
            `<div class="wj-align-center" tabindex="-1" aria-colindex="1" aria-selected="false" style="left: 0px; top: 0px; width: 43px; height: 15px; z-index: 0;"><label><input type="checkbox" class="wj-column-selector wj-column-selector-group wj-cell-check" tabindex="-1"></lable></div>`, {}), e.cell);
        }
        element.onchange = function (e) {
          e.preventDefault();
          onAllChecked.apply(e, [e]);
        }
      } else if (e.panel === s.columnHeaders && e.getColumn(e.col).binding === "rejectCode") {
        if (input.current?.rejectYn === "Y") {
          e.cell?.firstChild?.remove();
          const col = s.columns[e.col];

          let element = null;
          if (rejectCheck.current) {
            element = createElement(format(
              `<span class="txt-title" style="display: inline-block;"><label>Rejection</label><br><input type="checkbox" class="wj-column-selector wj-column-selector-group wj-cell-check" tabindex="-1" checked=true>Reason</label></span>`, {}), e.cell);
          }
          else {
            element = createElement(format(
              `<span class="txt-title" style="display: inline-block;"><label>Rejection</label><br><input type="checkbox" class="wj-column-selector wj-column-selector-group wj-cell-check" tabindex="-1">Reason</lable></spen>`, {}), e.cell);
          }

          element.onchange = function (e) {
            e.preventDefault();
            fncCopyRejectReason.apply(e, [e]);
          }
        }
      }
    });

    // add total footer
    sender.columnFooters.rows.push(new GroupRow());
  };

  const fncCopyRejectReason = (status) => {
    let tmpChecked = status.target.checked;
    let view = gridObject.current?.collectionView;
    let rows = gridObject.current.rows ?? [];
    if (rows.length > 0) {
      if (tmpChecked) {
        if (rows.length > 1) {
          let rejectCode = view.items[0].rejectCode;
          let rejectDesc = view.items[0].rejectDesc;

          for (let i = 1; i < rows.length; i++) {
            view.items[i].rejectCode = rejectCode;
            view.items[i].rejectDesc = rejectDesc;
          }
        }

      } else {
        gridObject.current.collectionView.items.forEach((el) => {
          el.rejectCode = "";
          el.rejectDesc = "";
        });
      }
      view.commitEdit();
      view.refresh();
    }

    rejectCheck.current = tmpChecked;
  };

  const onAllChecked = (status) => {
    let tmpChecked = status.target.checked;
    if ((gridObject?.current?.collectionView?.items?.length ?? 0) > 0) {

      let checkCountList = gridObject.current.collectionView.items.filter(e => e.isChecked2 === true);
      if (checkCountList.length === gridObject.current.collectionView.items.length) {
        tmpChecked = false;
      } else {
        tmpChecked = true;
      }

      gridObject.current.collectionView.items.forEach((el) => {
        if (tmpChecked) {
          el.isChecked2 = true;
        } else {
          el.isChecked2 = false;
        }
      });

      gridObject.current.collectionView.commitEdit();
      gridObject.current.collectionView.refresh();
    } else {
      status.target.checked = false;
      tmpChecked = false;
    }

    displayCheck.current = tmpChecked;
  };

  const initGridFormat = useCallback(
    (
      type,
      popups
    ) => {
      let gridFormat = claimRef.current?.getGirdFormat();

      setGridColGroup([...gridFormat]);
    }, []
  );

  const initGridBindData = useCallback((type) => {
    let resultData = claimRef.current?.getData();

    setGidData(
      new CollectionView(resultData, {
        trackChanges: true,
        refreshOnEdit: false,
      })
    );
  });

  const gridFormatItemClaimDetail = (s, e) => {
    claimRef?.current?.setFormatItem(s, e);
  };

  // Approval Line Grid
  const initGridApprv = (sender) => {
    gridObjApprv.current = sender;
    ComUtils.gridInit(sender, {
      // hasShowNumbering: true,
      canAreaInfo: false,
      selectionMode: 6,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: false,
    });
    sender.allowSorting = false;
  }

  const initGridFormatApprv = useCallback(() => {
    let gridFormat = [
      { binding: "flowSeq", header: "Seq.", width: 80, allowDragging: true, isReadOnly: true, align: "center", },
      { binding: "personName", header: "Approver", width: 150, allowDragging: true, isReadOnly: true, align: "center", },
      { binding: "email", header: "E-Mail", width: 200, allowDragging: true, isReadOnly: true, align: "center", },
      { binding: "status", header: "Status", width: 120, allowDragging: true, isReadOnly: true, align: "center", },
      { binding: "apprDate", header: "Approval Date", width: 150, allowDragging: true, isReadOnly: true, align: "center", },
      { binding: "comments", header: "Comments", width: "*", allowDragging: true, isReadOnly: true, align: "left", },
    ];
    setGridColGroupApprv([...gridFormat]);
  }, []);

  const showTable = () => {
    return <div className={claimRef.current?.isMergeNumbering() ? "numbering-merge" : undefined}>
      <FlexGrid key={claimType}
        columnGroups={gridColGroup} // 그리드 포멧
        itemsSource={gridData} // 바인딩할 데이터
        initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
        style={{ minheight: "200px" }}
        formatItem={gridFormatItemClaimDetail}>
      </FlexGrid>
    </div>;
  };

  return (
    <div className="page-wrap">
      <div className="breadcrumb-header">
        <Breadcrumb items={pageInfo} />
        <div className=" btn-group-end">
          {backFn && <Button variant="outlined" onClick={(e) => backFn()}>{"Back"}</Button>}
          {backFn && <Button variant="outlined" onClick={(e) => backFn(true)}>{"List"}</Button>}

          {prevJobConfirmView && btnConfirmRejectView && <Button variant="outlined" onClick={(e) => fncTempSaveConfirm('SAVE')}>{"Save"}</Button>}
          {prevJobConfirmView && btnConfirmRejectView && claimAnalystYn === "Y" && <Button variant="outlined" onClick={(e) => fncTempSaveConfirm('REQUEST')}>{"Confirm"}</Button>}
          {prevJobConfirmView && btnConfirmRejectView && claimAnalystYn === "Y" && <Button variant="outlined" onClick={(e) => fncClaimAnalystReject()}>{"Reject"}</Button>}

          {prevJobConfirmView && initData?.input?.selApprovalStatus === "TO-DO" && <Button variant="outlined" onClick={(e) => fncApprove()}>{"Approve"}</Button>}
          {prevJobConfirmView && initData?.input?.selApprovalStatus === "TO-DO" && <Button variant="outlined" onClick={(e) => fncReject()}>{"Reject"}</Button>}

          {prevJobConfirmView && btnTransferView && <Button variant="outlined" onClick={(e) => fncTransfer()}>{"Transfer"}</Button>}

          {(prevJobConfirmView && btnGerpErrorCountView) && <Button variant="outlined" onClick={(e) => fncConfirm()}>{"Confirm"}</Button>}
          {(prevJobConfirmView && btnGerpErrorCountView) && <Button variant="outlined" onClick={(e) => fncClaimAnalystReject()}>{"Reject"}</Button>}

          {!prevJobConfirmView && <Button variant="contained" onClick={(e) => { fncTempSave() }}>{"Save"}</Button>}
          {!prevJobConfirmView && <Button variant="outlined" onClick={(e) => { fncSave("SUBMIT") }}>{"Submit"}</Button>}
          {!prevJobConfirmView && claimAnalystYn === "Y" && <Button variant="outlined" onClick={(e) => { fncSave("REQUEST") }}>{"Confirm"}</Button>}
        </div>
      </div>
      <div className="page-in-wrap">
        <div className="bx-card-group">
          <div className="filter-title">
            <div className="txt-title">
              {title}
              <div className="ml-5">
              </div>
            </div>
          </div>

          <div className="search-wrapper">
            <div className="bx-search2 fixed-column3">
              <div className="bx-item">
                <span className="field-required fz-12 ml-5 fwb">
                  * is required field
                </span>
              </div>

              <div className="bx-item">
                {(postAuditCustomer === "Y" || postAuditClaim === "Y") ?
                  <>
                    <span className="fz-12 fwb">This is Post Audit claim</span>
                    <div className="ml-10 d-flex disabled-color-change">
                      <ComRadioBox
                        radioList={[
                          { Value: "Y", Description: "Yes" },
                          { Value: "N", Description: "No" },
                        ]}
                        ref={entityPostAuditClaim}
                        elementId={"entityPostAuditClaim"}
                        radioGroupName={"PostAuditClaim"}
                        direction={1}
                      />
                    </div>
                    <div className="ml-10 d-flex" style={{ width: '130px' }}>
                      <ComDate
                        ref={entityActivityDateFrom}
                        elementId={"entityActivityDateFrom"}
                        controlDateFormat={"MM/dd/yyyy"}
                        controlDateMask={"##/##/####"}
                        controlDateValidFormat={"__/__/____"}
                        isNullable={true}
                      />
                    </div>
                    <div className="ml-10 d-flex" style={{ width: '130px' }}>
                      <ComDate
                        ref={entityActivityDateTo}
                        elementId={"entityActivityDateTo"}
                        controlDateFormat={"MM/dd/yyyy"}
                        controlDateMask={"##/##/####"}
                        controlDateValidFormat={"__/__/____"}
                        isNullable={true}
                      />
                    </div>
                  </>
                  :
                  <>
                    <span className="fz-12 fwb">
                      Cust. Entry
                    </span>
                    <div className="ml-10 d-flex disabled-color-change">
                      <ComRadioBox
                        radioList={[
                          { Value: "Y", Description: "Yes" },
                          { Value: "N", Description: "No" },
                        ]}
                        ref={entityCustEntry}
                        elementId={"entityCustEntry"}
                        radioGroupName={"CustEntry"}
                        direction={1}
                        defaultDisabled={true}
                      />
                    </div>
                  </>
                }
              </div>

              <div className="bx-item  d-flex-end">
                <span className="fz-12 fwb">
                  Reverse(Debit):
                </span>
                <div className="mr-10 d-flex disabled-color-change">
                  <ComRadioBox
                    ref={entityReverse}
                    radioList={
                      [
                        { Value: "Y", Description: "Yes" },
                        { Value: "N", Description: "No" },
                      ]
                    }
                    direction={1}
                    radioGroupName={"ReverseDebitGroup"}
                    defaultValue="N"
                    callRelationFuntion={onChangedReverse}
                  />
                </div>
              </div>
            </div>

            <div className="bx-search2 fixed-column3">
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Portal Entry#</label>
                </div>
                <div className="bx-inp combo">{systemEntryNoView}</div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Last Update</label>
                </div>
                <div className="bx-inp combo">{updateDateView}</div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">SP Status</label>
                </div>
                <div className="bx-inp combo">
                  {claimStatusView}
                </div>
              </div>

              <div className="bx-item column-2column d-flex-column">
                <div className="ct-fl-sample-request w-100">
                  <div className="bx-item " style={{ width: "100%" }}>
                    <div className="div-label">
                      <label className="txt-label">
                        <span className="requiresimbol"> *</span> Bill To Code
                      </label>
                    </div>
                    <div
                      className="bx-inp combo inp-mix"
                      style={{ display: userType === "B" ? "none" : "" }}
                    >
                      <ComTextBoxFindWithcode
                        ref={entityBillTo}
                        elementId={"entityBillTo"}
                        placeholderCode={"Enter a Bill To Code"}
                        placeholderText={"Bill To Name"}
                        callOpenPopupFunction={openBillToPopup}
                        callRelationFuntion={callBillToPopup}
                        callRelationBlur={openBillToPopup}
                        defaultRaiseClick={false}
                        defaultSearch={defaultBillToSearchParam}
                        defaultMaxLength={20}
                      />
                    </div>
                    <div
                      className="bx-inp combo inp-mix"
                      style={{ display: userType === "B" ? "" : "none" }}
                    >
                      <ComTextBox
                        ref={entityParamBillTo}
                        elementId={"entityParamBillTo"}
                        defaultDisabled={true}
                      />
                      <ComTextBox
                        ref={entityParamBillToName}
                        elementId={"entityParamBillToName"}
                        defaultDisabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="ct-fl-sample-request w-100">
                  <div className="bx-item" style={{ width: "100%" }}>
                    <div className="div-label">
                      <label className="txt-label">
                        <span className="requiresimbol"> *</span> Ship To Code
                      </label>
                    </div>
                    <div className="bx-inp combo inp-mix">
                      <ComTextBoxFindWithcode
                        ref={entityShipTo}
                        elementId={"entityShipTo"}
                        placeholderCode={"Enter a Ship To Code"}
                        placeholderText={"Ship To Name"}
                        callOpenPopupFunction={openShipToPopup}
                        callRelationFuntion={callShipToPopup}
                        callRelationBlur={openShipToPopup}
                        callOpenValid={handleShipToValid}
                        defaultRaiseClick={false}
                        defaultReadOnly={false}
                        defaultSearch={defaultShipToParam}
                        defaultMaxLength={20}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  {userType !== "B" &&
                    <>
                      <label className="txt-label"> {claimAnalystYn === "Y" ? <span className="requiresimbol"> *</span> : undefined} Division</label>
                    </>
                  }
                </div>
                <div
                  className="bx-inp combo inp-mix disabled-color-change"
                  style={{
                    wordBreak: "unset",
                    flexWrap: "wrap",
                    columnGap: "20px",
                  }}
                >
                  {userType !== "B" &&
                    <ComRadioBox
                      ref={entityDivision}
                      radioList={[
                        { Value: "HA", Description: "HA" },
                        { Value: "AE", Description: "AE" },
                        { Value: "HE", Description: "HE" },
                        { Value: "B2B", Description: "B2B" },
                        // { Value: "MC", Description: "MC" },
                        { Value: "BUILDER", Description: "Builder" },
                        { Value: "SKS", Description: "SKS" },
                        { Value: "PROJECT", Description: "Project" },
                        { Value: "OEM", Description: "OEM" },
                      ]}
                      direction={1}
                      radioGroupName={"DivisionGroup"}
                      callRelationFuntion={onChangedDivision}
                    />
                  }
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Address</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultDisabled
                    ref={entityBillToAddress}
                  />
                </div>
              </div>

              <div className="bx-item item-only-bg txt-center d-flex-center">
                Dealer Contact Information
              </div>
              <div className="bx-item item-only-bg txt-center d-flex-center">
                LG Sales Person Contact Information
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> City</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultDisabled
                    ref={entityBillToCity}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Name
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityBillToContactName}
                    defaultMaxLength={50}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Name
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComComboMultiColumn
                    ref={entitySalesrepName}
                    callRelationFuntion={(id, value, index, item) => {
                      entitySalesrepEmail.current?.setValue(item?.emailId);
                      entitySalesrepTel.current?.setValue(item?.tel);
                    }}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> State</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    defaultDisabled
                    ref={entityBillToState}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Tel
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComMaskInput
                    ref={entityBillToContactTel}
                    mask="000-000-0000"
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Tel</label>
                </div>
                <div className="bx-inp combo">
                  <ComMaskInput
                    ref={entitySalesrepTel}
                    mask="000-000-0000"
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label"> Zip Code</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityBillToZipCode}
                    defaultDisabled
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>E-Mail
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityBillToContactEmail}
                    defaultMaxLength={50}
                    placeholder="Enter a email address"
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>E-Mail
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entitySalesrepEmail}
                    defaultMaxLength={50}
                    placeholder="Enter a email address"
                  />
                </div>
              </div>
              <div className="bx-item column-2column">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Claim Type <br />(
                    <span className="field-required">Select 1 Only</span>)
                  </label>
                </div>
                <div
                  className="bx-inp combo disabled-color-change"
                  style={{
                    wordBreak: "unset",
                    flexWrap: "wrap",
                    columnGap: "50px",
                  }}
                >
                  <ComRadioBox
                    ref={entityClaimType}
                    radioList={listClaimType}
                    callRelationFuntion={(id, value) => onChangedClaimType(id, value)}
                    direction={1}
                    radioGroupName={"ClaimTypeGroup"}
                    defaultValue={claimType}
                  />
                </div>
              </div>
              <div className="bx-item d-flex-column">
                <div className="ct-fl-sample-request w-100">
                  <div className="bx-item " style={{ width: "100%" }}>
                    <div className="div-label">
                      <label className="txt-label">
                        {userType !== "B" &&
                          <>
                            {claimAnalystYn === "Y" &&
                              <span className="requiresimbol"> *</span>
                            }
                            Reason Code
                          </>
                        }
                      </label>
                    </div>
                    <div className="bx-inp combo inp-mix">
                      {userType !== "B" &&
                        <ComComboMultiColumn
                          ref={entityReasonCode}
                          elementId="entityReasonCode"
                        />
                      }
                    </div>
                  </div>
                </div>
                <div className="ct-fl-sample-request w-100">
                  <div className="bx-item" style={{ width: "100%" }}>
                    <div className="div-label">
                      <label className="txt-label">Claim Analyst</label>
                    </div>
                    <div className="bx-inp combo inp-mix">
                      <ComTextBox
                        isSearch
                        ref={entityClaimAnalyst}
                        defaultRaiseClick={false}
                        defaultReadOnly={false}
                        callOpenPopupFunction={openAnalystPopup}
                        callKeyUpFuction={(id, e) => {
                          e.preventDefault();
                          if (e.key === "Enter") {
                            openAnalystPopup(id, e.target.value);
                          }
                        }
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Debit Memo
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityDebitMemoNo}
                    elementId={entityDebitMemoNo}
                    defaultMaxLength={50}
                    callRelationFuntion={(id, value) => {
                      convertUpperCase(id, value);
                    }}
                    callRelationBlur={(id, value) => {
                      if (prevInpus.current.debitMemoNo != value) {
                        searchDebitMemo(id, value);
                      }
                    }}
                    callKeyUpFuction={(id, e) => {
                      e.preventDefault();
                      if (e.key === "Enter") {
                        prevInpus.current.debitMemoNo = e.target.value;
                        searchDebitMemo(id, e.target.value);
                      }
                    }
                    }
                  />
                  <img
                    src={IcPdf}
                    style={{ cursor: "pointer" }}
                    onClick={e => {
                      downloadPdf({ dataItem: { invoiceNo: entityDebitMemoNo.current?.getValue() } });
                    }}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>Request Amount
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComNumberInput
                    ref={entityDebitMemoAmt}
                    formatValue={'n2'}
                    minValue={0}
                    defaultStyle={{ color: "red" }}
                  />
                  <span style={{ width: "60%" }}>
                    Org.: {orgDebitMemoAmount}
                  </span>
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    {!rvsClaimNo &&
                      <><span className="requiresimbol"> *</span>Payment Method</>
                    }
                  </label>
                </div>
                <div className="bx-inp combo disabled-color-change">
                  {!rvsClaimNo &&
                    <ComRadioBox
                      ref={entityPaymentMethod}
                      radioList={[
                        { Value: "CREDIT", Description: "Credit" },
                        { Value: "CHECK", Description: "Check" },
                      ]}
                      defaultDisabled={isDisablePaymentMethod}
                      direction={1}
                      radioGroupName={"PaymentMethod"}
                      defaultValue="CREDIT"
                      callRelationFuntion={(id, value) => onChangedPaymentMethod(id, value)}
                    />
                  }
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">LG Chargeback#</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBoxFind
                    ref={entityLgChargebackNo}
                    elementId={entityLgChargebackNo}
                    defaultRaiseClick={false}
                    defaultReadOnly={false}
                    defaultSearch={defaultChargeSearchParam}
                    callRelationFuntion={callChargePopup}
                    callOpenPopupFunction={searchChargeback}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Vendor Name</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityVendorName}
                    isSearch={!isDisableVenderName}
                    defaultDisabled={isDisableVenderName}
                    defaultMaxLength={70}
                    defaultRaiseClick={false}
                    defaultReadOnly={false}
                    callOpenPopupFunction={openSupplierPopup}
                    callKeyUpFuction={(id, e) => {
                      e.preventDefault();
                      if (e.key === "Enter") {
                        openSupplierPopup(id, e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Vendor No.</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityVendorCode}
                    defaultDisabled
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Outstanding Invoice Amt</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityOutstandingInvoiceAmt}
                    placeholder={"**********"}
                    defaultStyle={{ color: "red", textAlign: "right" }}
                    defaultClassName={"input-placeholder-red"}
                    defaultDisabled
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    Pay to address
                  </label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityPayToAddress}
                    defaultMaxLength={500}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    {claimAnalystYn === "Y" &&
                      `Pending Type`
                    }
                  </label>
                </div>
                <div className="bx-inp combo">
                  {claimAnalystYn === "Y" &&
                    <ComCombo
                      ref={entityPendingCode}
                      elementId={"entityPendingCode"}
                    />
                  }
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Quotation No.</label>
                </div>
                <div className="bx-inp combo">
                  <ComTextBox
                    ref={entityQuotationNo}
                    callRelationBlur={(id, value) => {
                      if (prevInpus.current.quotationNo != value) {
                        searchQuotationNo(id, value);
                      }
                    }}
                    callKeyUpFuction={(id, e) => {
                      e.preventDefault();
                      if (e.key === "Enter") {
                        prevInpus.current.quotationNo = e.target.value;
                        searchQuotationNo(id, e.target.value);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">Multiple Quote</label>
                </div>
                <div className="bx-inp combo disabled-color-change">
                  <ComRadioBox
                    ref={entityMultiQuote}
                    radioList={[
                      { Value: "Y", Description: "Yes" },
                      { Value: "N", Description: "No" },
                      { Value: "NQ", Description: "No Quote" },
                    ]}
                    direction={1}
                    radioGroupName={"MultipleQuote"}
                    callRelationFuntion={(id, value) => onChangedMultiQuote(id, value)}
                  />
                </div>
              </div>
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    {(claimAnalystYn === "Y" && "12313".indexOf(claimType ?? "") > -1) &&
                      `Group Code`
                    }
                  </label>
                </div>
                <div className="bx-inp combo disabled-color-change">
                  {(claimAnalystYn === "Y" && "12313".indexOf(claimType ?? "") > -1) &&
                    <ComRadioBox
                      ref={entityGroupCodeFlag}
                      radioList={[
                        { Value: "Y", Description: "Yes" },
                        { Value: "N", Description: "No" },
                      ]}
                      direction={1}
                      radioGroupName={"GroupCode"}
                      defaultValue="N"
                      callRelationFuntion={(id, value) => onChangedGroupCode(id, value)}
                    />
                  }
                </div>
              </div>
              {showLayoutByClaimType()}
            </div>
          </div>
        </div>
        <div className="table-area-wrapper">
          <div className="tb-content">
            <div className="bx-top">
              <div>
                <div className="txt-title">
                  <h3>
                    Claim Type:
                    <span className="field-required ml-5 fwb">
                      {claimType !== "" &&
                        listClaimType.find((x) => x.Value == claimType)?.Description || ""
                      }
                    </span>
                  </h3>
                </div>
              </div>
              <div className="bx-btn-group">
                {(userType === "E" && (claimType == "9" || claimType == "11")) &&
                  <Button variant="outlined" className="btn-default" onClick={e => onClickInvoice()}>
                    {"Invoice"}
                  </Button>
                }
                {rvsClaimNo === "" &&
                  <Button variant="outlined" className="btn-default" onClick={e => onClickAddRow()}>
                    {"Add Row"}
                  </Button>
                }
                <Button variant="outlined" className="btn-default" onClick={e => onClickDeleteRow()}>
                  {"Delete Row"}
                </Button>
                {rvsClaimNo === "" &&
                  <Button variant="outlined" className="btn-default" onClick={e => onClickUploadExcel()}>
                    {"Excel Upload"}
                  </Button>
                }
                {claimAnalystYn === "Y" &&
                  <Button variant="outlined" className="btn-default" onClick={e => fncESNUploadExcel()}>
                    {"Validation"}
                  </Button>
                }
              </div>
            </div>
            {claimType !== "" &&
              <div className="bx-table">
                {showTable()}
              </div>
            }
          </div>
        </div>
        <div className="search-wrapper mt-10">
          <div className="bx-search2 fixed-column2">
            <div className="bx-item">
              {
                ('1' == claimType || '2' == claimType || '14' == claimType || '3' == claimType || '13' == claimType || '4' == claimType) &&
                <>
                  * This claim is Final for this program.
                  <div className="ml-5 d-flex disabled-color-change">
                    <ComRadioBox
                      ref={entityMoreClaims}
                      radioList={[
                        { Value: "N", Description: "Yes" },
                        { Value: "Y", Description: "No" },
                      ]}
                      direction={1}
                      radioGroupName={"ThisClaim"}
                      defaultValue={claimAnalystYn === "Y" ? "N" : undefined}
                    />
                  </div>
                </>
              }
            </div>

            <div className="bx-item">
              {claimAnalystYn === "Y" &&
                <>
                  {"To be Follow Up (Pre Payment )"}
                  <div className="ml-5">
                    <ComDate
                      ref={entityFollowUpDate}
                      elementId={"entityReturnDateFrom"}
                      controlDateFormat={"MM/dd/yyyy"}
                      controlDateMask={"##/##/####"}
                      controlDateValidFormat={"__/__/____"}
                      isNullable={true}
                    />
                  </div>

                  <div className="ml-5 d-flex disabled-color-change">
                    <ComRadioBox
                      ref={entityFollowFlag}
                      radioList={[
                        { Value: "Y", Description: "Done" },
                        { Value: "N", Description: "Wait" },
                      ]}
                      direction={1}
                      radioGroupName={"ThisClaim"}
                    // defaultValue="Y"
                    />
                  </div>
                </>
              }
            </div>
          </div>
          <div className="bx-search2 fixed-column1">
            <div className="bx-item">
              <div className="div-label">
                <label className="txt-label">
                  <span className="requiresimbol"> *</span>Customer Claim Description
                </label>
              </div>
              <div
                className="bx-inp combo"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <ComTextArea
                  ref={entityCustomerClaimDesc}
                  defaultStyle={{
                    border: "unset",
                    resize: "vertical",
                    minHeight: "40px",
                    margin: 0,
                    padding: "4px 6px",
                  }}
                  defaultMaxLength={2000}
                ></ComTextArea>
              </div>
            </div>
            {(claimAnalystYn === 'Y' || initData?.claimMstInfo?.claimStatusCode !== 'A1' && initData?.claimMstInfo?.claimStatusCode !== 'AE') &&
              <div className="bx-item">
                <div className="div-label">
                  <label className="txt-label">
                    <span className="requiresimbol"> *</span>LG Review Comment
                  </label>
                </div>
                <div
                  className="bx-inp combo"
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  <ComTextArea
                    ref={entityLgReviewComment}
                    defaultStyle={{
                      border: "unset",
                      resize: "vertical",
                      minHeight: "40px",
                      margin: 0,
                      padding: "4px 6px",
                    }}
                  ></ComTextArea>
                </div>
              </div>
            }
          </div>
        </div>

        {/* Approval */
          approvalLineView &&
          <div className="bx-card-group mt-10">
            <div className="filter-title">
              <div
                className={
                  isHiddenApporval ? "bx-inp-control open" : "bx-inp-control"
                }
                onClick={() => setIsHiddenApporval(!isHiddenApporval)}
              >
                {isHiddenApporval ? (
                  <UnfoldMoreRoundedIcon />
                ) : (
                  <UnfoldLessRoundedIcon />
                )}
                <span className="txt-title">Approval Line</span>
              </div>
            </div>

            <div style={isHiddenApporval ? {} : { display: approvalLineView ? '' : 'none' }}>

              <div className="table-area-wrapper">
                <div className="tb-content">
                  <div className="bx-table">
                    <FlexGrid
                      columnGroups={gridColGroupApprv} // 그리드 포멧
                      itemsSource={gridDataApprv} // 바인딩할 데이터
                      initialized={initGridApprv} // 그리드 초기 이벤트 및 등록 처리
                      style={{ minheight: "200px" }}
                    >
                    </FlexGrid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        {approveCommentView &&
          <div className="bx-card-group mt-10">
            <div className="search-wrapper">
              <div className="bx-search2">

                <div className="bx-item column-4column">
                  <div className="div-label">
                    <label className="txt-label">
                      Approval / Save / Rejection Comment
                    </label>
                  </div>
                  <div
                    className="bx-inp combo"
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    <ComTextArea
                      defaultStyle={{
                        border: "unset",
                        resize: "vertical",
                        minHeight: "40px",
                      }}
                      elementId={"entityApproveComment"}
                      ref={entityApproveComment}
                    ></ComTextArea>
                  </div>
                </div>

              </div>
            </div>
          </div>
        }

        <div className="bx-card-group">
          <div className="search-wrapper">
            <div className="bx-search2">
              <div className="bx-item column-4column">
                <div className="div-label">
                  <label className="txt-label">
                    Backup Document Submission By
                  </label>
                </div>
                <div className="bx-inp combo inp-mix inp-column">
                  {claimAnalystYn === "Y" &&
                    <div style={{ fontSize: "11px" }}>
                      1. Debit Memo &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2. POP/Proof &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3. Analysis &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 4. Email/PGM
                    </div>
                  }
                  <div className="layout-file">
                    <div>
                      <Button variant="outlined" style={{ margin: "0px 10px" }} onClick={onClickUploadFile}>
                        {"Find File"}
                      </Button>
                      <input type="file" onChange={(e) => SampleUtils.checkFile(e, fileList, uploadFileExcute, null, null, null, 300, true)} ref={fileElement} style={{ display: 'none' }} multiple />

                    </div>
                    <div className="file-group">
                      {fileList &&
                        fileList?.filter((file) => (file?.isDeleted ?? false) == false)
                          ?.map((file, index) => (
                            <>
                              <div className="file-list" key={file.serverFileName}>
                                {claimAnalystYn === "Y" && <input className="file-stt" style={{ width: "40px" }} defaultValue={file?.fileNo ?? ''} onChange={(e) => handleFileNoChange(file, e.target.value)} />}
                                <div className="file-name">
                                  {!file?.isOld && <p className="file-name">{file.displayFileName}</p>}
                                  {file?.isOld && <p className="file-name" style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      file.clickChk = true;
                                      const newLinkClasses = fileList.map((file, i) =>
                                        file.clickChk ? 'file-link-purple' : 'file-link-blue'
                                      );
                                      setLinkClasses(newLinkClasses);

                                      callFileDownload(file, e)
                                    }} >

                                    <span
                                      key={index}
                                      className={linkClasses[index] || 'file-link-blue'}
                                    >
                                      {file.displayFileName}
                                    </span>
                                  </p>}
                                  <CloseOutlinedIcon onClick={(e) => removeFile(file, e)} />
                                </div>
                              </div>
                            </>
                          ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DiscountChart
        callbackFuntion={closeDiscountChart}
        isOpen={discountChart}
        randomKey={randomKey.current}
      />

      {openModalExcelUpload && <ExcelUploadOtherClaim closeFn={closeExcelUploadPopup} open={openModalExcelUpload} randomKey={randomKey.current} pageInfo={pageInfo} claimType={entityClaimType.current?.getValue()} claimDesc={listClaimType.find((x) => x.Value == claimType)?.Description || ""} billToCode={entityBillTo.current?.getCode()} systemEntryNo={systemEntryNoUpload.current ?? ""} divisionCode={userType === "E" ? entityDivision.current?.getValue() : null} reverseFlag={entityReverse.current?.getValue()} claimObj={claimRef.current} />}
      {openModalBillTo && <BillToSchPop open={openModalBillTo} closeFn={closeBillToPopup} randomKey={randomKey.current} reqBillToCode={entityBillTo?.current?.getCode()} reqBillToName={entityBillTo?.current?.getText()} userLevel={"1"} billToResData={billToResData.current} pageInfo={pageInfo} />}
      {openModalShipTo && <ShipToSchPop open={openModalShipTo} closeFn={closeShipToPopup} randomKey={randomKey.current} reqShipToCode={entityShipTo?.current.getCode()} reqShipToName={entityShipTo?.current.getText()} userLevel={"1"} shipToResData={shipToResData.current} pageInfo={pageInfo} />}
      {openModalCharge && <ChargebackSchPop open={openModalCharge} closeFn={closeModalCharge} randomKey={randomKey.current} reqChargebackNo={defaultChargeSearchParam.current.chargebackNo} reqReferenceNo={entityDebitMemoNo?.current?.getValue()} reqReasonCode='' reqBillToCode={entityBillTo.current?.getCode()} chargeResData={chargeResData.current} pageInfo={pageInfo} />}
      {openModalModelCode && <ModelSchPop open={openModalModelCode} closeFn={closeModalModelCode} randomKey={randomKey.current} reqModelCode={defaultModelSearchParam?.current?.modelCode} reqModelDesc='' reqInvoiceNo='' reqClaimAnalystYn='Y' modelResData={modelResData.current} pageInfo={pageInfo} />}
      {openModalLgProgramModel && <LgProgramModelSchPop open={openModalLgProgramModel} closeFn={closeModalLgProgramModel} randomKey={randomKey.current} reqModelCode={defaultLgProgramModelSearchParam.current?.modelCode} reqBillToCode={defaultLgProgramModelSearchParam.current?.billToCode} reqPriceProtectionDate={defaultLgProgramModelSearchParam.current?.priceMoveDate} resData={lgProgramModelResData.current} pageInfo={pageInfo} />}
      {openModalLgProgram && <LgProgramSchPop open={openModalLgProgram} closeFn={closeModalLgProgram} randomKey={randomKey.current} reqLgPgmNo={defaultLgProgramSearchParam.current?.lgPgmNo} reqBillTo={defaultLgProgramSearchParam.current?.paramBillTo} reqClaimType={defaultLgProgramSearchParam.current?.claimType} reqModelCode={defaultLgProgramSearchParam.current?.modelCode} reqRequestAmt={defaultLgProgramSearchParam.current?.requestAmt} resData={lgProgramResData.current} pageInfo={pageInfo} />}
      {openModalAnalyst && <AnalystSchPop open={openModalAnalyst} closeFn={closeModalAnalyst} randomKey={randomKey.current} reqClaimAnalyst={entityClaimAnalyst?.current?.getValue()} reqBillToCode={entityBillTo?.current?.getCode()} reqDivisionCode={entityDivision.current?.getValue()} reqSalesChannelName='' reqEmpNo='' analystResData={analystResData.current} />}
      {openModalSupplier && <SupplierPop open={openModalSupplier} closeFn={closeModalSupplier} billToData={{ billToCode: entityBillTo?.current?.getCode(), billToName: entityBillTo.current?.getText() }} supplierCode={entityVendorName.current?.getValue()} reqRandomKey={randomKey.current} pageInfo={pageInfo} />}
      {openModalPriceInvoiceNo && <PriceInvoicePop open={openModalPriceInvoiceNo} closeFn={closeModalPriceInvoiceNo} reqBillToCode={entityBillTo?.current?.getCode()} reqInvoiceNo={defaultInvoicePriceSearchParam.current?.invoiceNo} rsltData={priceInvoiceResData.current} randomKey={randomKey.current} pageInfo={pageInfo} />}
      {openModalInvoiceNo && <InvoiceSchPop open={openModalInvoiceNo} closeFn={closeModalInvoiceNo} reqBillTo={entityBillTo?.current?.getCode()} reqInvoiceNo={defaultInvoiceNoSearchParam.current?.invoiceNo} reqClaimType={entityClaimType.current?.getValue()} resData={invoiceNoResData.current} randomKey={randomKey.current} pageInfo={pageInfo} />}
      {openModalLgOrgProgram && <LgOrgProgramSchPop open={openModalLgOrgProgram} closeFn={closeModalLgOrgProgram} randomKey={randomKey.current} reqBillTo={defaultLgOrgProgramSearchParam.current?.paramBillTo} reqModelCode={defaultLgOrgProgramSearchParam.current?.modelCode} reqLgPgmNo={defaultLgOrgProgramSearchParam.current?.lgPgmNo} reqClaimType={defaultLgOrgProgramSearchParam.current?.claimType} reqDivisionCode={defaultLgOrgProgramSearchParam.current?.divisionCode} reqRequestAmt={defaultLgOrgProgramSearchParam.current?.requestAmt} resData={lgOrgProgramResData.current} pageInfo={pageInfo} />}
      {openModalInvoiceReturnList && <InvoiceReturnListPop open={openModalInvoiceReturnList} closeFn={closeModalInvoiceReturnList} requstType={invoiceReturnListParam.current?.requstType} invoiceReturnList={invoiceReturnListParam.current?.invoiceReturnList} randomKey={randomKey.current} pageInfo={pageInfo} />}
      {openModalInvoiceUsedList && <InvoiceUsedListPop open={openModalInvoiceUsedList} closeFn={closeModalInvoiceUsedList} requstType={invoiceUsedListParam.current?.requstType} invoiceReturnList={invoiceUsedListParam.current?.invoiceUsedList} handleUsedPopup={handleClaimView} randomKey={randomKey.current} pageInfo={pageInfo} />}
      {openModalInvoiceTax && <InvoiceTaxSchPop open={openModalInvoiceTax} closeFn={closeModalTaxInvoice} reqBillTo={entityBillTo?.current?.getCode()} reqInvoiceNo={defaultInvoiceTaxSearchParam.current?.invoiceNo} resData={invoiceTaxResData.current} reqClaimStatus={initData?.claimMstInfo?.claimStatus} reqSystemEntryNo={systemEntryNo.current} randomKey={randomKey.current} pageInfo={pageInfo} />}
      {openModalDAInvoice && <DAInvoicePop open={openModalDAInvoice} closeFn={() => setOpenModalDAInvoice(false)} billToCode={entityBillTo?.current?.getCode()} rows={gridObject.current?.collectionView?.items} randomKey={randomKey.current} pageInfo={pageInfo} />}
      {openGSRESNUploadPop && <ESNExcelUploadPop open={openGSRESNUploadPop} closeFn={closeESNExcelUploadPopup} claimType={entityClaimType?.current?.getValue()} billToCode={entityBillTo?.current?.getCode()} divisionCode={entityDivision.current?.getValue()} systemEntryNo={systemEntryNoView} openClaimView={handleClaimView} randomKey={randomKey.current} pageInfo={pageInfo} />}
      {openModalClaimRequestPop && <OtherClaimViewPop open={openModalClaimRequestPop} closeFn={() => setOpenModalClaimRequestPop(false)} initData={invoiceUsedInitParam.current} randomKey={randomKey.current} pageInfo={pageInfo} />}
    </div>
  );
}

export default OtherClaimRequestMod;
