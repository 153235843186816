import { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openMenu } from "reducer/OpenMenus";

import { ApiManager } from "common/lib/ApiUtil";
import { ComUtils } from "common/lib/ComUtils";
import { Button, Divider } from "@mui/material";
import { MessageContext } from "common/lib/MessageProvider";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BookIcon from "assets/icons/book.svg";

import _ from "lodash";

const menuData = [
  {
    menuId: "claim",
    menuName: "Claim",
    items: [
      {
        menuId: "rsaSpiff",
        menuName: "RSA SPIFF",
        items: [
          {
            menuId: "rsa-request",
            menuName: "Claim Entry",
            menuUrl: "Pub/Claim/RSASpiff/Request/RSASpiffRequest",
          },
          {
            menuId: "rsa-extension-request",
            menuName: "Promo Extension",
            menuUrl: "Pub/Claim/RSASpiff/Request/RSASpiffExtensionRequest",
          },
          {
            menuId: "rsa-tracking",
            menuName: "Claim History",
            menuUrl: "Pub/Claim/RSASpiff/Tracking/RSASpiffTracking",
          },
        ],
      },
    ],
  },
  {
    menuId: "popup-common",
    menuName: "Popups",
    menuUrl: "Pub/Popup/ListPopup",
  },
];
const MenuLayerMobile = (props) => {
  /** test code start  */
  const [dbMenuData, setDbMenuData] = useState(null);
  const [pubMenu, setPubMenu] = useState(true);
  const onSetPubMenu = () => {
    setPubMenu(!pubMenu);
    if (pubMenu) {
      setMenuTree(menuData);
    } else {
      setMenuTree(dbMenuData);
    }
  };
  /** test code end  */

  const { messageObject } = useContext(MessageContext);

  const currentMenu = useSelector((state) => state.openMenus);
  const dispatch = useDispatch();

  const [menuTree, setMenuTree] = useState();
  const [activeHome, setActiveHome] = useState(true);
  const [activeMenu, setActiveMenu] = useState("");

  const menuClick = (menuItem) => {
    if (menuItem?.menuUrl) {
      if (menuItem.menuUrl.indexOf("http") > -1) {
        window.open(menuItem.menuUrl);
      } else {
        document.getElementById("areaInfoObject").style.display = "none";
        let t = {};
        Object.assign(t, menuItem);
        t["origSysDocumentRef"] = "";
        t["copyFlag"] = false;
        t["editFlag"] = false;
        t["timeKey"] = menuItem.menuId;
        t["tabUniqueKey"] = menuItem.menuId;
        t["Active"] = true;
        dispatch(openMenu(t));
        setActiveMenu(menuItem.menuId);

        props.onCollapseClick();
      }
    }
  };

  useEffect(() => {
    if (currentMenu?.length > 0 && currentMenu[0].Active) {
      setActiveHome(true);
    } else {
      setActiveHome(false);
    }
  }, [currentMenu, dispatch]);

  useEffect(() => {
    const loadMenuData = async () => {
      let resultData = await ApiManager.post(
        "/menu/retrieveMenuWithUserNo",
        {}
      );
      let orginalMenu = _.cloneDeep(resultData);
      // 조회 데이터를 트리로 변경 처리
      let menuTreeData = ComUtils.makeMenuTree(resultData);

      // test code
      setDbMenuData(menuTreeData);

      // 데이터 바인딩
      setMenuTree(menuTreeData);
    };

    loadMenuData();
  }, [dispatch]);

  const [selected, setSelected] = useState(null);
  // const [allSelected, setAllSelected] = useState(false);
  const [subMenuOpened, setSubMenuOpened] = useState(null);
  const [expandedItems, setExpandedItems] = useState([]);

  const toggle = (i, el) => {
    if (el?.menuUrl) {
      if (el.menuUrl.indexOf("http") > -1) {
        window.open(el.menuUrl);
      } else {
        document.getElementById("areaInfoObject").style.display = "none";
        let t = {};
        Object.assign(t, el);
        t["origSysDocumentRef"] = "";
        t["copyFlag"] = false;
        t["editFlag"] = false;
        t["timeKey"] = el.menuId;
        t["tabUniqueKey"] = el.menuId;
        t["Active"] = true;
        dispatch(openMenu(t));
      }
    }
    const currentItem = expandedItems.find((item) => item === el?.menuId);
    if (currentItem) {
      setExpandedItems([
        ...expandedItems.filter((item) => item !== el?.menuId),
      ]);
    } else {
      if (selected === i) {
        return setSelected(null);
      }
      setSelected(i);
    }
    if (props.menuFixed === false) {
      props.onCollapseClick();
    }
  };

  const toggleSubMenu = (i, menuId) => {
    const currentItem = expandedItems.find((item) => item === menuId);
    if (currentItem) {
      setExpandedItems([...expandedItems.filter((item) => item !== menuId)]);
    } else {
      setSubMenuOpened(subMenuOpened === i ? null : i);
    }
  };

  useEffect(() => {
    if (props.menuFixed === false) {
      setExpandedItems([...expandedItems]);
      // setSubMenuOpened(null);
      // setExpandedItems([]);
    }
  }, [props.menuFixed]);

  useEffect(() => {
    //handle open all menu when user type is RSA SPIFF
    if (
      sessionStorage.getItem("userType") &&
      sessionStorage.getItem("userType") == "B"
    ) {
      if (!pubMenu) {
        const subItems = [];
        setExpandedItems(
          [
            ...menuData.map((item) => {
              if (item.items) {
                subItems.push([...item.items.map((i) => i.menuId)]);
              }
              return [item.menuId, ...subItems].flat();
            }),
          ].flat()
        );
      } else {
        const subItems = [];
        if (dbMenuData && dbMenuData.length > 0) {
          setExpandedItems(
            [
              ...dbMenuData.map((item) => {
                if (item.items) {
                  subItems.push([...item.items.map((i) => i.menuId)]);
                }
                return [item.menuId, ...subItems].flat();
              }),
            ].flat()
          );
        }
      }
    }
  }, [dbMenuData, pubMenu]);

  return (
    <>
      <div>
        <Divider textAlign="left" className={activeHome ? "isHome" : ""}>
          <span onClick={() => onSetPubMenu()}>
            {props.menuFixed ? "MENU" : ""}
          </span>
        </Divider>
        {/* <div className={activeHome ? "menu-ctrl on-title" : "menu-ctrl"}>
          <div className="btn-collapse">
            <Button
              variant="outlined"
              onClick={() => {
                const subItems = [];
                {
                  !pubMenu
                    ? setExpandedItems(
                        [
                          ...menuData.map((item) => {
                            if (item.items) {
                              subItems.push([
                                ...item.items.map((i) => i.menuId),
                              ]);
                            }
                            return [item.menuId, ...subItems].flat();
                          }),
                        ].flat()
                      )
                    : setExpandedItems(
                        [
                          ...dbMenuData.map((item) => {
                            if (item.items) {
                              subItems.push([
                                ...item.items.map((i) => i.menuId),
                              ]);
                            }
                            return [item.menuId, ...subItems].flat();
                          }),
                        ].flat()
                      );
                }
              }}
            >
              {"+Expand All"}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setExpandedItems([]);
                setSelected(null);
                setSubMenuOpened(null);
              }}
            >
              {"-Collapse All"}
            </Button>
          </div>
        </div> */}
      </div>
      <div className="main-ul-wrap">
        <ul className="main-ul">
          {menuTree !== undefined && menuTree !== null
            ? menuTree.map((el, i) => (
                <li key={el.menuId}>
                  <Button
                    className={selected === i ? "btn-nav active" : "btn-nav"}
                    onClick={() => {
                      toggle(i, el);
                    }}
                    endIcon={
                      el.items === undefined ? (
                        ""
                      ) : selected === i ||
                        expandedItems.includes(el.menuId) ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )
                    }
                  >
                    <span className={"bx-icon " + el.menuName}></span>
                    <span className="btn-text">{el.menuName}</span>
                  </Button>
                  {el?.items?.length > 0 ? (
                    <ul
                      className={
                        (selected === i && props.menuFixed) ||
                        expandedItems.includes(el.menuId)
                          ? "bx-drop-menu show"
                          : "bx-drop-menu"
                      }
                      key={el.menuId + "Ul"}
                    >
                      {el.items.map((sub2, i2) => (
                        <li
                          key={sub2.menuId}
                          onClick={() => {
                            sub2?.items?.length > 0
                              ? toggleSubMenu(i2, sub2.menuId)
                              : menuClick(sub2);
                          }}
                          className={
                            sub2?.items?.length > 0
                              ? "has-child"
                              : activeMenu === sub2.menuId
                              ? "active"
                              : ""
                          }
                        >
                          <span
                            className={`sub-text ${
                              subMenuOpened === i2 ? "on" : ""
                            }`}
                          >
                            {sub2.menuName}
                            {sub2.items === undefined ? (
                              ""
                            ) : subMenuOpened === i2 ||
                              expandedItems.includes(sub2.menuId) ? (
                              <KeyboardArrowDownIcon />
                            ) : (
                              <KeyboardArrowRightIcon />
                            )}
                          </span>
                          {sub2?.items?.length > 0 ? (
                            <ul
                              className={`lvl-3 ${
                                subMenuOpened === i2 ||
                                expandedItems.includes(sub2.menuId)
                                  ? "bx-drop-menu show"
                                  : "bx-drop-menu"
                              }`}
                              key={sub2.menuId + "Ul"}
                            >
                              {sub2.items.map((sub3) => (
                                <li
                                  key={sub3.menuId}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    menuClick(sub3);
                                  }}
                                  className={
                                    activeMenu === sub3.menuId ? "active" : ""
                                  }
                                >
                                  <span className="sub-text">
                                    {sub3.menuName}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            ""
                          )}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              ))
            : ""}
          <li></li>
        </ul>
      </div>
      <div className="btn-manual-wrapper">
        <Divider />
        <div className="d-flex" style={{ marginRight: "10px" }}>
          {/* {sessionStorage.getItem("userType") &&
          sessionStorage.getItem("userType") == "B" ? (
            <div className="w-50">
              <Button
                variant="outlined"
                className="btn-nav btn-manual"
                style={{
                  minWidth: "108px",
                }}
              >
                <span
                  className="btn-text"
                  style={{ flex: "inherit", fontSize: "12px" }}
                >
                  PC Version
                </span>
              </Button>
            </div>
          ) : null} */}

          {/* <Button
            className={activeHome ? "btn-nav btn-manual" : "btn-nav"}
            variant="outlined"
            style={{
              width:
                sessionStorage.getItem("userType") &&
                sessionStorage.getItem("userType") == "B"
                  ? "50%"
                  : "96%",
              gap: "4px",
              display: "flex",
              justifyContent: "center",
              paddingLeft: "10px",
            }}
          >
            <img
              src={BookIcon}
              alt="manual"
              style={{
                width: "20px",
                height: "20px",
              }}
            />
            <span
              className="btn-text"
              style={{ flex: "inherit", fontSize: "12px" }}
            >
              Manual
            </span>
          </Button> */}
        </div>
        <div className="d-flex-center fz-16" style={{ color: "#000000" }}>
          LG Sales Portal
        </div>
      </div>
    </>
  );
};
export default MenuLayerMobile;
