import { useContext, useEffect, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import "assets/styles/pages/ModalCommon.scss";

import Divider from "@mui/material/Divider";
import { ComUtils } from "common/lib/ComUtils";

import { CollectionView, createElement, format } from "@grapecity/wijmo";
import { FlexGrid } from "@grapecity/wijmo.react.grid";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import * as wjInput from "@grapecity/wijmo.react.input";
import { ComNoticfication } from 'common/components/ComNoticfication';
import { ApiManager } from 'common/lib/ApiUtil';
import ReactDOMServer from 'react-dom/server';
import IrSpiffDateOverlapReqDtl from "Pages/Clm/Isf/IrSpiffDateOverlapReqDtl";
import { MessageContext } from "common/lib/MessageProvider";

function IrDateSoldValidationPop({ open, closeFn, randomKey, reqClaimId, reqBillTo }) {

  //session정보
  const userType = sessionStorage.getItem("userType").toString();
  const userGroup = sessionStorage.getItem("userGroup").toString();
  const comCode = sessionStorage.getItem("comCode").toString();
  const userId = sessionStorage.getItem("userId").toString();

  //메시지 추가
  const { messageObject } = useContext(MessageContext);

  //error 메시지
  const msgClmErr185 = ComUtils.getMessage(messageObject, "CLM_ERR_00185");               //Can not get commercial invoice info.


  //url
  const retrieveDateSoldValidationList = "/clm/isf/retrieveDateSoldValidationList";
  const retrieveClaimsDtl = "/clm/isf/retrieveClaimsDtl";

  const positionTopValue = useRef();
  const popComponent = useRef();
  const [popuView, setPopuView] = useState(true);
  const [gridData, setGridData] = useState(null);
  const [gridColGroup, setGridColGroup] = useState(null);
  const filterSearch = useRef(null);
  const [gridDataCount, setGridDataCount] = useState('0');


  //pageing관련
  const totCntRef = useRef(0);
  const pageCntRef = useRef(0);
  const entityPageNo = useRef(0);
  const searchDataRef = useRef(null);

  const gridObject = useRef();
  const filterObject = useRef();


  const css = `
  <style>
  .txt-link-green {
            color: #006400;
            border-bottom: 1px solid #006400;
            font-weight: 400;
            cursor: pointer;
            font-size: 11px;
          }
  </style>`;

  const onDateOverlapCombo = async (pClaimId) => {

    let sendParam = {}
    sendParam.claimType = "IR";
    sendParam.claimId = pClaimId;
    sendParam.userLevel = '1';

    let resultData = await ApiManager.post(retrieveClaimsDtl, sendParam);
    if (resultData?.statusCode) {
      ComNoticfication.ShowErrorUI(ComUtils._altTitleError, msgClmErr185);
      return;
    } else {
      if (resultData?.list?.length > 0) {
        let cnt = 0;
        for (let inx = 0; inx < resultData?.list?.length; inx++) {
          if (Number(resultData?.list[inx]?.rejectCount ?? 0) > 0) {
            cnt++;
            break;
          }
        }

        let element = <IrSpiffDateOverlapReqDtl
          claimType={"IR"}
          claimId={pClaimId}
          customerInfo={resultData?.list[0]}
          claimList={resultData?.list}
          fileList={resultData?.fileList}
          userId={userId}
          totRejectCount={cnt}
          userLevel={"1"}
          userType={userType}
          serverUrl={resultData.serverURL}


        />
        const htmlString = ReactDOMServer.renderToString(element);

        const width = 1560;
        const height = 800;
        const left = (window.screen.width / 2) - (width / 2);
        const top = (window.screen.height / 2) - (height / 2);
        const printWindow = window.open('', '_blank', `width=${width},height=${height},top=${top},left=${left}`);

        let script = `
              <script>


                  function mergeCell(tbl, startRow, cNum, length2, add)
                  {
                    var isAdd = false;
                    if(tbl == null) return;
                    if(startRow == null || startRow.length == 0) startRow = 1;
                    if(cNum == null || cNum.length == 0) return ;
                    if(add  == null || add.length == 0) {
                      isAdd = false;
                    }else {
                      isAdd = true;
                      add   = parseInt(add);
                    }
                    cNum   = parseInt(cNum);
                    length2 = parseInt(length2);

                    rows   = tbl.rows;
                    rowNum = rows.length;

                    tempVal  = '';
                    cnt      = 0;
                    startRow = parseInt(startRow);

                    pos=0;
                    for( i = startRow; i < rowNum; i++ ) {
                      curVal = rows[i].cells[cNum].innerHTML;
                      if( curVal == tempVal ) {
                        if(cnt == 0) {
                          cnt++;
                          startRow = i - 1;
                        }
                        cnt++;
                      }else if(cnt > 0) {
                        merge(tbl, startRow, cnt, cNum, length2);
                        startRow = endRow = 0;
                        cnt = 0;
                      }else {
                      }
                      tempVal = curVal;
                    }

                    if(cnt > 0) {
                      merge(tbl, startRow, cnt, cNum, length2);
                    }
                  }

                  function merge(tbl, startRow, cnt, cellNum, length)
                  {
                    rows = tbl.rows;
                    row  = rows[startRow];

                    for( i = startRow + 1; i < startRow + cnt; i++ ) {
                      for( j = 0; j < length; j++) {
                        rows[i].deleteCell(cellNum);
                      }
                    }
                    for( j = 0; j < length; j++) {
                      row.cells[cellNum + j].rowSpan = cnt;
                    }
                  }
                </script>
                <script>
                  mergeCell(document.getElementById('tbl0'), '1', '0', '1', '0');
                </script>
                <script>
                  const fileDownTarget = document.querySelectorAll('.fileDown');

                  fileDownTarget.forEach(el => {
                    el.addEventListener('click', function() {
                      const fileId = el.getAttribute('data-id');
                      const fileSeq = el.getAttribute('data-seq');

                      const param = {
                        fileId: fileId,
                        fileSeq: fileSeq,
                        customPath: "ir"
                      }
                      
                      //이후 서버통신 하여 파일다운로드 진행
                      console.log(param);
                      onFileDown(fileId, fileSeq);

                    });
                  });

                  async function onFileDown(fileId, fileSeq) {
                    try {

                      const requestData = {
                        fileId: fileId,
                        fileSeq: fileSeq,
                        customPath: "ir",
                      };
                      var fullUrl = document.getElementById("serverUrl").value + "/lgsp/api/clm/file/retrieveDateOverLapClaimAttchFileByAttchId";
                      const response = await fetch(fullUrl, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(requestData),
                      });

                      if (response.ok) {
                        const sBlob = await response.blob();
                        
                        let filename = null;
                        let disposition = response.headers.get('content-disposition');
                        if (disposition && disposition.indexOf('attachment') !== -1) {
                          let filenameMatch = disposition.split(';').find(part => part.trim().startsWith('filename='));
                          if (filenameMatch) {
                            filename = filenameMatch.split('=')[1].trim().replace(/['"]/g, '');
                          }
                        }
                        
                        const url = window.URL.createObjectURL(sBlob);
                        const link = document.createElement('a');
                        
                        link.setAttribute('href', url);
                        link.setAttribute('download', filename);
                        
                        document.body.appendChild(link);
                        
                        link.click();
  
                        link.parentNode.removeChild(link);
                        window.URL.revokeObjectURL(url)
                      }

                    } catch (error) {
                      console.error('Error fetching accumDtl:', error);
                    } finally {
                    }
                  };
                </script>
                `;
        let printHtml = htmlString + script;
        console.log(printWindow)
        if (printWindow) {
          printWindow.document.writeln(printHtml);
          printWindow.document.close();
          printWindow.document.title = "LG Electronics :: AIC";

          printWindow.focus();
        }
      }
    }
  }

  const initGrid = (sender) => {
    gridObject.current = sender;
    ComUtils.gridInit(sender, {
      selectionMode: 6,
      hasCheckBox: false,
      showCheckAll: false,
      hasDisableCheck: false,
    });
  };

  const initFilter = (f) => {
    filterObject.current = f;
  };

  const initGridFormat = () => {
    let gridFormat = [
      {
        binding: "verificationResult", header: " ", width: 130, allowDragging: true, isReadOnly: true, align: "left"
        /*,cellTemplate: (ctx, cell) => {
          cell?.firstChild?.remove();
          let dataItem = ctx.row.dataItem;
          let value = ctx.value;
          if (value) {
            let element = null;
            if (dataItem?.verificationResult != 'Current Claim') {
                element = createElement(format(`<div style="background: #F6F6F6">${value}</div>`, {}), cell)
            } else {
              element = createElement(format(`<p>${value}</p>`, {}), cell);
            }
          }
        },*/
      }
      , {
        binding: "claimId", header: "Claim ID", width: 130, allowDragging: true, isReadOnly: true, align: "center",
        cellTemplate: (ctx, cell) => {
          cell?.firstChild?.remove();
          let dataItem = ctx.row.dataItem;
          let value = ctx.value;
          if (value) {
            if (dataItem.verificationResult !== "Current Claim") {
              let element = createElement(

                format(
                  `${css}<span class="txt-link-green" style="font-size: 11px">${value}</span>`,
                  {}
                ),
                cell
              );
              element.onclick = function (e) {
                e.preventDefault();
                onDateOverlapCombo(dataItem.claimId);
              };
            } else {
              let element = createElement(
                format(
                  `<span>${value}</span>`,
                  {}
                ),
                cell
              );
            }

          }
        },
      }
      , { binding: "createDate", header: "Claim Date", width: 100, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: "programId", header: "Program ID", width: "*", minWidth: 200, allowDragging: true, isReadOnly: true, align: "left" }
      , { binding: "periodDate", header: "Program Period", width: 180, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: "modelCode", header: "Model", width: 130, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: "dateSoldFrom", header: "Date Sold From", width: 90, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: "dateSoldTo", header: "Date Sold To", width: 90, allowDragging: true, isReadOnly: true, align: "center" }
      , { binding: "debitMemo", header: "Debit Memo", width: 100, allowDragging: true, isReadOnly: true, align: "left" }
    ];
    setGridColGroup([...gridFormat]);
  };


  //Memory
  const _isMounted = useRef(true);
  // 데이터 로딩 초기화
  useEffect(() => {
    let abortController = new AbortController();

    initGridFormat();
    const getPageInfo = async () => {
      if (reqClaimId) {
        const [resultData] = await Promise.all([
          ApiManager.post(retrieveDateSoldValidationList, { "claimId": reqClaimId, "billTo": reqBillTo }),

        ]);
        if (resultData?.statusCode) {
          // An error occurred during processing
          ComNoticfication.ShowErrorUI(ComUtils._altTitleError, ComUtils.getMessage(messageObject, "CLM_ERR_00011"));
        }
        else {
          setGridData(
            new CollectionView(resultData, {
              trackChanges: true,
              refreshOnEdit: false,
            })
          );
          setGridDataCount(resultData?.length);
        }
      }
    }
    getPageInfo();

    setPopuView(() => open);

    return () => {
      _isMounted.current = false;
      abortController.abort();
    };
  }, [open, randomKey]);

  const onCancelClick = () => {
    popComponent.current.hide();
    setPopuView(false);
    closeFn();
  };

  let top = 0;

  const initForm = (s) => {
    popComponent.current = s;
    popComponent.current.show(true);
    positionTopValue.current = popComponent?.current?.hostElement?.style?.top;
  };

  const shown = (p) => {
    top = p.hostElement.style.top;
  };
  const sizeChanged = (p) => {
    p.hostElement.style.top = top;
  };

  const popupPositionChanging = (s, e) => {
    let bnd = e.bounds;
    bnd.left = Math.max(
      Math.min(bnd.left, window.innerWidth + window.scrollX - (bnd.width + 17)),
      window.scrollX
    );
    bnd.top = Math.max(
      Math.min(bnd.top, window.innerHeight + window.scrollY - bnd.height),
      window.scrollY
    );
  };

  const positionChanged = (s, e) => {
    top = s.hostElement.style.top;
  };

  return (
    <wjInput.Popup
      className="pop-resizable_small"
      isDraggable={true}
      isResizable={true}
      initialized={initForm}
      hideTrigger="None"
      style={{ width: 1200, height: 700 }}
      positionChanging={popupPositionChanging}
      positionChanged={positionChanged}
      shown={shown}
      sizeChanged={sizeChanged}
    >
      <div className="wj-dialog-header">
        <h2 className="pop-title">{"IR Date Sold Validation Result"}</h2>
        <Button color="secondary" className="btn-close" onClick={onCancelClick}>
          <CloseIcon />
        </Button>
      </div>
      <div className="content-modal">
        <Divider component="li" />
        <div className="bx-top">
          {/* <div>
            <div className="txt-title">
              <h3>{"List"}</h3>
              <p className="txt-total">
                {"Total"}
                <strong className="txt-num">
                  {ComUtils.NumberWithComma(gridDataCount)}
                </strong>
              </p>
            </div>
          </div> */}
        </div>
        <div className="bx-table mt-10">
          <FlexGrid
            columnGroups={gridColGroup} // 그리드 포멧
            itemsSource={gridData} // 바인딩할 데이터
            initialized={initGrid} // 그리드 초기 이벤트 및 등록 처리
            style={{ minHeight: "200px", height: "600px" }}
          >
            <FlexGridFilter initialized={initFilter} />
          </FlexGrid>
        </div>
      </div>
    </wjInput.Popup>
  );
}

export default IrDateSoldValidationPop;
